import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb } from '../../../components/';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import { Container, Wrapper, PageHeader } from './../styles';
import {
  InspectionType,
  ProjectInspectionType,
  ProjectStatus,
  UploadTypes,
} from './../types';
import { FormContainer, InputGroup, TableContainer } from '../../styles';
import Radio from '../../../components/form-elements/radio';
import Textarea from '../../../components/form-elements/textarea';
import { Button } from '../../../components';
import { ButtonContainer, FilesContainer, Observation } from './style';
import projectsApi from '../../../services/projects';
import { useAuth } from '../../../contexts/auth';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';
import { InspectionFiles } from '../../../components/';
import { ProjectType } from '../../../types/project';

type ControlledInputTypes = {
  status: string;
};

type EngineeringInspectPageProps = {
  id: string;
};

const EngineeringInspectPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [inspection, setInspections] = useState<ProjectInspectionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Files[]>([]);
  const [project, setProject] = useState<ProjectType>();
  const { id } = useParams<EngineeringInspectPageProps>();
  const { user } = useAuth();
  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const [values, setValues] = useState<ControlledInputTypes>({
    status: ProjectStatus.VISTORIA_SOLICITADA,
  });

  const feedbackOptions = [
    {
      label: ProjectStatus.VISTORIA_SOLICITADA,
      value: ProjectStatus.VISTORIA_SOLICITADA,
    },
    {
      label: ProjectStatus.DOCUMENTACAO_REPROVADA,
      value: ProjectStatus.DOCUMENTACAO_REPROVADA,
    },
  ];

  const schema = yup.object().shape({
    status: yup.string().required('Concessionária é um campo obrigatório'),
    description: yup.string().required('Descrição é um campo obrigatório'),
  });

  useEffect(() => {
    if (!loading) {
      projectsApi
        .get<ProjectInspectionType[]>(`/api/projects/${id}/project-inspection`)
        .then((res) => {
          setInspections(res.data);
        });
    }
  }, [loading]);

  useEffect(() => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });
    setValue('status', values.status);
    projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
      setProject(res.data);
    });
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<InspectionType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: InspectionType) => {
    try {
      setLoading(true);
      await projectsApi.post(`/api/projects/${id}/project-inspection`, {
        userId: user.id,
        observation: data.description,
        status: data.status,
      });

      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: data.status,
      });

      toast.success('Vistoria realizada com sucesso');
      history.push('/projetos/central-atendimento');
      setLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Vistoria</span>
        </PageHeader>
        <FormContainer>
          <FilesContainer>
            <InspectionFiles
              title="1. Fotos do padrão de entrada"
              loadedFiles={getFiles(UploadTypes.VISTORIA_PADRAO_ENTRADA)}
            />
            <InspectionFiles
              title="2. Fotos das placas de dados dos inversores"
              loadedFiles={getFiles(
                UploadTypes.VISTORIA_FOTOS_DADOS_INVERSORES
              )}
            />
            <InspectionFiles
              title="3. Fotos da instalação dos inversores"
              loadedFiles={getFiles(UploadTypes.VISTORIA_INSTALACAO_INVERSORES)}
            />
            <InspectionFiles
              title="4. Fotos da instalação dos módulos"
              loadedFiles={getFiles(UploadTypes.VISTORIA_INSTALACAO_MODULOS)}
            />
            <InspectionFiles
              title="5. Fotos do aterramento"
              loadedFiles={getFiles(UploadTypes.VISTORIA_ATERRAMENT)}
            />
            <InspectionFiles
              title="6. Documento de responsabilidade técnica"
              loadedFiles={getFiles(
                UploadTypes.VISTORIA_DOCUMENT_RESPONSABILIDADE_TECNICA
              )}
            />
            <InspectionFiles
              title="7. Relatorio de comissionamento"
              loadedFiles={getFiles(
                UploadTypes.VISTORIA_RELATORIO_COMISSIONAMENTO
              )}
            />
          </FilesContainer>
          <Observation>
            <span>Observações</span>
            <p>{project?.inspectionRequestObs}</p>
          </Observation>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <span>Status do projeto:</span>
              <Radio
                options={feedbackOptions}
                name="status"
                onChange={(e: any) => {
                  setValue('status', e.target.value);
                  setValues({
                    ...values,
                    status: e.target.value,
                  });
                }}
                value={values.status}
              />
              <p>{errors.status?.message}</p>
            </InputGroup>
            <InputGroup>
              <Textarea
                name="description"
                label="Observações"
                onChange={(e) => {
                  setValue('description', e.target.value);
                }}
              />
              <p>{errors.description?.message}</p>
            </InputGroup>

            <ButtonContainer>
              <div>
                <Button
                  text="Voltar"
                  backgroundColor="#DFE0E2"
                  onClick={() => history.push('/projetos/engenharia')}
                />
              </div>
              <div>
                <Button
                  type="submit"
                  text="Salvar"
                  backgroundColor="#001FFF"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>

            <TableContainer>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Usuário</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspection.map((inspection) => (
                      <tr key={inspection.id}>
                        <td>{inspection.status}</td>
                        <td>{inspection.user.name}</td>
                        <td>{inspection.observation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default EngineeringInspectPage;
