import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Header, Menu } from '../../components';
import { AcceptTermsModal } from '../../components/modal/accept-terms';
import { InfoProfitabilityModal } from '../../components/modal/info-profitability';
import { useAuth } from '../../contexts/auth';
import productsApi from '../../services/products';
import usersApi from '../../services/users';
import { UserRoles } from '../../types/users';
import { Container, Content, Wrapper } from './styles';

export type Profitability = {
  minimun: number;
  ideal: number;
  salesCommission: number;
  referralCommission: number;
  invoiceValue: number;
  royalties: number;
  franchiseId: string;
};

type Modals = {
  profitability: boolean;
  terms: boolean;
};

const DefaultLayout: React.FC = ({ children }) => {
  const history = useHistory();

  const [modals, setModals] = useState<Modals>({
    profitability: false,
    terms: false,
  });

  const { selectedFranchise, user, franchises, updateUser } = useAuth();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebar = () => {
    setOpenSidebar((state) => !state);
  };

  const handleOutSideClick = () => {
    setOpenSidebar(false);
  };

  const getProfitability = async (
    franchiseId: string
  ): Promise<Profitability> => {
    try {
      const { data } = await productsApi.get<Profitability>(
        '/budgets/profitability',
        {
          params: {
            franchiseId,
          },
        }
      );

      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleAcceptTerms = async (userId: string) => {
    try {
      const { data } = await usersApi.put(`/api/users/${userId}/accept-terms`);

      updateUser(data);

      setModals((state) => ({
        ...state,
        terms: false,
      }));
    } catch (error) {
      toast.error('Ops, algo deu errado! Tente novamente.');

      throw error;
    }
  };

  const handleProfitabilityConfigMessage = async () => {
    const isProvider = user.role === UserRoles.FORNECEDOR;

    const integratorWithoutBudgetModule =
      (user.role === UserRoles.INTEGRADOR_ADMIN ||
        user.role === UserRoles.INTEGRADOR) &&
      !franchises.some(({ modules }) => modules.includes('ERP'));

    if (isProvider || integratorWithoutBudgetModule) {
      return;
    }

    try {
      if (!selectedFranchise) {
        return;
      }

      const profitability = await getProfitability(selectedFranchise);

      const notConfiguredFranchise =
        !profitability.invoiceValue || profitability.invoiceValue === 0;

      if (notConfiguredFranchise) {
        setModals((state) => ({
          ...state,
          profitability: true,
        }));
      }
    } catch (error) {
      console.error('Error handling profitability config message: ', error);
    }
  };

  useEffect(() => {
    if (user.acceptedTerms) {
      handleProfitabilityConfigMessage();

      return;
    }

    setModals((state) => ({
      ...state,
      terms: true,
    }));
  }, [selectedFranchise, user]);

  return (
    <Container>
      <Menu
        open={openSidebar}
        handleSidebar={handleSidebar}
        handleOutSideClick={handleOutSideClick}
      />
      <Wrapper>
        <Header onOpenSidebar={handleSidebar} />
        <Content>{children}</Content>
      </Wrapper>

      <AcceptTermsModal
        isOpen={modals.terms}
        onConfirm={() => handleAcceptTerms(user.id)}
      />

      <InfoProfitabilityModal
        isOpen={modals.profitability}
        onConfirm={() => {
          history.push('/orcamentos/configurar-lucratividade');

          setModals((state) => ({ ...state, profitability: false }));
        }}
        onCancel={() =>
          setModals((state) => ({ ...state, profitability: false }))
        }
      />
    </Container>
  );
};

export default DefaultLayout;
