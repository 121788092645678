import React from 'react';
import { UserRoles } from '../../../../../types/users';
import { FranchiseTable } from './innerTables/franchise';
import { ConsultantTable } from './innerTables/consultant';
import { Container, Header } from './styles';
import { ProviderTable } from './innerTables/provider';
import { IntegratorTable } from './innerTables/integrator';

type Props = {
  handleLoading: (isLoading: boolean) => void;
  userRole: string;
};

export const Orders: React.FC<Props> = (props) => {
  const { handleLoading, userRole } = props;

  const isIntegrator =
    userRole === 'IntegratorAdmin' || userRole === 'Vendedor';

  return (
    <Container>
      <Header>
        <h1>Pedidos enviados</h1>
      </Header>

      {UserRoles.FRANQUEADO.includes(userRole) && (
        <FranchiseTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {UserRoles.CONSULTOR_SUCESSO.includes(userRole) && (
        <ConsultantTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {UserRoles.ADMIN.includes(userRole) && (
        <ConsultantTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {UserRoles.FORNECEDOR.includes(userRole) && (
        <ProviderTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}

      {isIntegrator && (
        <IntegratorTable setLoading={(isLoading) => handleLoading(isLoading)} />
      )}
    </Container>
  );
};
