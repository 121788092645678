import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Breadcrumb, Button, FileUpload } from '../../../components/';
import Textarea from '../../../components/form-elements/textarea';
import { Divider, FormContainer, InputGroup } from '../../styles';
import { Container, PageHeader, Wrapper } from '../styles';
import { InspectionRequestType, ProjectStatus, UploadTypes } from '../types';
import { ButtonContainer, Error } from './style';

import { useAuth } from '../../../contexts/auth';
import FileUploadService from '../../../services/FileUploadService';
import projectsApi from '../../../services/projects';
import { Files } from '../../../types/files';
import { ProjectType } from '../../../types/project';

type InspectionRequestPageProps = {
  id: string;
};

const InspectionRequestPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<InspectionRequestPageProps>();
  const [files, setFiles] = useState<Files[]>([]);
  const [project, setProject] = useState<ProjectType>();

  useEffect(() => {
    projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
      setProject(res.data);
      if (res.data.inspectionRequestObs) {
        setValue('inspectionRequestObs', res.data.inspectionRequestObs);
      }
    });

    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
      }));
      setFiles(normalizied);
    });
  }, []);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    inspectionRequestObs: yup.string().optional(),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<InspectionRequestType>({ resolver: yupResolver(schema) });

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  const onSubmit = async (data: InspectionRequestType) => {
    try {
      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
      });

      await projectsApi.patch(`/api/projects/${id}/project-inspection`, {
        description: data.inspectionRequestObs,
      });

      await projectsApi.post(`/api/projects/${id}/status-log`, {
        userId: user.id,
        observation: ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
        status: ProjectStatus.AGUARDANDO_ANALISE_VISTORIA,
      });

      toast.success('Status atualizado com sucesso');
      history.push('/projetos');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Solicitar vistoria</span>
        </PageHeader>
        <FormContainer>
          Documentação de Vistoria
          <Form
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            initialData={project}
          >
            <InputGroup>
              <FileUpload
                title="1. Fotos do padrão de entrada"
                helpMessage="Que apresentem a placa de advertência e a corrente nominal do disjuntor geral da unidade consumidora."
                loadedFiles={getFiles(UploadTypes.VISTORIA_PADRAO_ENTRADA)}
                projectId={id}
                type={UploadTypes.VISTORIA_PADRAO_ENTRADA}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="2. Fotos das placas de dados dos inversores"
                helpMessage="Que permitam verificar o modelo e a potência conforme liberado."
                loadedFiles={getFiles(
                  UploadTypes.VISTORIA_FOTOS_DADOS_INVERSORES
                )}
                projectId={id}
                type={UploadTypes.VISTORIA_FOTOS_DADOS_INVERSORES}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="3. Fotos da instalação dos inversores"
                helpMessage="Que apresentem o local de instalação e a quantidade de inversores."
                loadedFiles={getFiles(
                  UploadTypes.VISTORIA_INSTALACAO_INVERSORES
                )}
                projectId={id}
                type={UploadTypes.VISTORIA_INSTALACAO_INVERSORES}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="4. Fotos da instalação dos módulos"
                helpMessage="Que apresentem o local de instalação e a quantidade de módulos fotovoltaicos."
                loadedFiles={getFiles(UploadTypes.VISTORIA_INSTALACAO_MODULOS)}
                projectId={id}
                type={UploadTypes.VISTORIA_INSTALACAO_MODULOS}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="5. Fotos do aterramento"
                helpMessage="Que apresentem o local da instalação."
                loadedFiles={getFiles(UploadTypes.VISTORIA_ATERRAMENT)}
                projectId={id}
                type={UploadTypes.VISTORIA_ATERRAMENT}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="6. Documento de responsabilidade técnica "
                helpMessage=""
                loadedFiles={getFiles(
                  UploadTypes.VISTORIA_DOCUMENT_RESPONSABILIDADE_TECNICA
                )}
                projectId={id}
                type={UploadTypes.VISTORIA_DOCUMENT_RESPONSABILIDADE_TECNICA}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <FileUpload
                title="7. Relatorio de comissionamento"
                helpMessage=""
                loadedFiles={getFiles(
                  UploadTypes.VISTORIA_RELATORIO_COMISSIONAMENTO
                )}
                projectId={id}
                type={UploadTypes.VISTORIA_RELATORIO_COMISSIONAMENTO}
              />
            </InputGroup>
            <Divider />
            <InputGroup>
              <Textarea
                name="inspectionRequestObs"
                label="Observações"
                onChange={(e) => {
                  setValue('inspectionRequestObs', e.target.value);
                }}
              />
              <Error>
                <span>{errors.inspectionRequestObs?.message}</span>
              </Error>
            </InputGroup>

            <ButtonContainer>
              <div>
                <Button
                  text="Salvar"
                  backgroundColor="#001FFF"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default InspectionRequestPage;
