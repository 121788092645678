import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, Checkbox } from '../../../components/';
import Input from '../../../components/input';
import MaskInput from '../../../components/mask-input';
import usersApi from '../../../services/users';
import { userSchema } from '../validation';
import {
  ButtonGroup,
  Container,
  FormContainer,
  Group,
  GroupedInputs,
  InputGroup,
  PageHeader,
  Wrapper,
} from './../../styles';
import Select, { Option } from '../../../components/select';

interface FormTypes {
  id: string;
  name: string;
  cpf: string;
  email: string;
  role: Option;
  enabled: boolean;
  password: string;
  confirmPassword: string;
}

type NewUserPageProps = {
  id: string;
};

const NewUserPage: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const { id } = useParams<NewUserPageProps>();

  const links = [
    {
      id: 1,
      title: 'Usuários',
      link: '/usuarios',
      active: false,
    },
    {
      id: 2,
      title: !id ? 'Novo' : 'Editar',
      link: !id ? '/usuarios/novo' : `/usuarios/editar/${id}`,
      active: true,
    },
  ];

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    reset,
    control,
    watch,
  } = useForm<FormTypes>({
    resolver: yupResolver(userSchema),
    defaultValues: {
      enabled: true,
    },
  });

  const roleOptions = [
    {
      label: 'Admin',
      value: 'Admin',
    },
    {
      label: 'Engenheiro',
      value: 'Engenheiro',
    },
  ];

  const onSubmit = async (data: FormTypes) => {
    try {
      if (!id) {
        await usersApi.post('/api/users', {
          ...data,
          role: data.role.value,
          cpf: data.cpf || undefined,
        });

        toast.success('Usuário cadastrado com sucesso');
      } else {
        await usersApi.put(`/api/users/${id}`, {
          ...data,
          role: data.role.value,
          cpf: data.cpf || undefined,
        });

        toast.success('Usuário alterado com sucesso');
      }

      history.push('/usuarios/');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      } else {
        toast.error('Erro desconhecido, tente novamente mais tarde.');
      }
    }
  };

  const handleEditUser = async () => {
    try {
      const { data } = await usersApi.get(`/api/users/${id}/`);

      reset({
        name: data.name,
        email: data.email,
        cpf: data.cpf,
        enabled: data.enabled,
        role: roleOptions.find((role) => role.value === data.role),
      });
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar as informações do usuário'
      );

      throw error;
    }
  };

  useEffect(() => {
    if (id) {
      handleEditUser();
    }
  }, []);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{!id ? 'Novo usuário' : 'Editar usuário'}</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite o nome completo"
                    error={errors.name?.message}
                    label="Nome completo"
                    {...register('name')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite o e-mail"
                    error={errors.email?.message}
                    label="E-mail"
                    {...register('email')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <MaskInput
                    label="CPF"
                    mask="999.999.999-99"
                    placeholder="Digite o CPF"
                    {...register('cpf')}
                    error={errors.cpf?.message}
                  />
                </InputGroup>
                <InputGroup>
                  <Controller
                    name="role"
                    control={control}
                    render={({
                      field: { onChange, value, name, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <Select
                          label="Perfil do usuário"
                          required
                          ref={ref}
                          name={name}
                          onChange={onChange}
                          value={value}
                          placeholder="Selecione um perfil"
                          options={roleOptions}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite a senha"
                    error={errors.password?.message}
                    label="Senha"
                    type="password"
                    {...register('password')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite a senha novamente"
                    error={errors.confirmPassword?.message}
                    label="Repetir Senha"
                    type="password"
                    {...register('confirmPassword')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <label>Status</label>
                  <Checkbox
                    name="enabled"
                    handleOnclick={(e) => {
                      setValue('enabled', e);
                    }}
                    checked={watch('enabled')}
                  >
                    Ativo
                  </Checkbox>
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <ButtonGroup>
                    <Button text="Salvar" backgroundColor="#001FFF" />
                  </ButtonGroup>
                </InputGroup>
              </GroupedInputs>
            </Group>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default NewUserPage;
