import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import axios from 'axios';
import Dropzone from 'react-dropzone';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { BRFlag, Breadcrumb, Button, CloseIcon } from '../../../components';
import Checkbox from '../../../components/checkbox';
import Input from '../../../components/input';
import InputPhone from '../../../components/input-phone';
import Loading from '../../../components/loading';
import MaskInput from '../../../components/mask-input';
import SelectInput from '../../../components/select';
import Textarea from '../../../components/textarea';
import { useAuth } from '../../../contexts/auth';
import franchisesApi from '../../../services/franchises';
import { statusOptions } from '../selectOptions';
import { FranchiseForm, FranchiseType } from '../types';
import { Container, PageHeader } from './../../styles';
import {
  CheckboxWrapper,
  Content,
  Divisor,
  FileItem,
  Grid,
  InputFile,
  Wrapper,
} from './styles';

const modulesOptions = [
  { label: 'CRM', value: 'CRM' },
  { label: 'Engenharia', value: 'Engenharia' },
  { label: 'Orçamentos', value: 'ERP' },
  { label: 'Clientes', value: 'Clientes' },
];

type NewFranchisePageProps = {
  id: string;
};

const NewFranchisePage: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const [franchise, setFranchises] = useState<FranchiseType>();

  const { id } = useParams<NewFranchisePageProps>();

  const links = [
    {
      id: 1,
      title: 'Integradores',
      link: '/integradores',
      active: false,
    },
    {
      id: 2,
      title: !id ? 'Novo' : 'Editar',
      link: !id ? '/integradores/novo' : `/integradores/${id}/editar`,
      active: true,
    },
  ];

  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    status: yup.object().nullable().required('Status é um campo obrigatório'),
    cnpj: yup.string().required('CNPJ é um campo obrigatório'),
    name: yup.string().required('Razão social é um campo obrigatório'),
    nickname: yup.string().required('Apelido é um campo obrigatório'),
    email: yup
      .string()
      .email('Digite um e-mail válido')
      .required('E-mail é um campo obrigatório'),
    phone: yup.string().nullable().optional(),
    cellphone: yup.string().nullable().optional(),
    zipcode: yup.string().required('CEP é um campo obrigatório'),
    state: yup.string().required('Estado é um campo obrigatório'),
    city: yup.string().required('Cidade é um campo obrigatório'),
    address: yup.string().required('Endereço é um campo obrigatório'),
    district: yup.string().required('Bairro é um campo obrigatório'),
    buildingNumber: yup.string().required('Número é um campo obrigatório'),
    usersQuantity: yup
      .string()
      .required('Número de usuários é um campo obrigatório'),
    modules: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().optional(),
          value: yup.string().optional(),
        })
      )
      .required('Campo obrigatório'),
    files: yup.mixed<File[] | string>(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
  } = useForm<FranchiseForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      files: [],
    },
  });

  const modulesError = (errors.modules as Partial<FieldError>)?.message;

  const getFranchise = async () => {
    setLoading(true);

    try {
      const { data } = await franchisesApi.get<FranchiseType>(
        `/api/franchises/${id}`
      );

      reset({
        status: statusOptions.find((status) => status.value === data.status),
        cnpj: data.cnpj,
        name: data.name,
        nickname: data.nickname,
        franchiseUserId: data.franchiseUserId,
        email: data.email,
        emailSecondary: data.emailSecondary,
        phone: data.phone === 'null' ? '' : data.phone,
        cellphone: data.cellphone === 'null' ? '' : data.cellphone,
        zipcode: data.zipcode,
        state: data.state,
        city: data.city,
        address: data.address,
        district: data.district,
        buildingNumber: data.buildingNumber,
        complement: data.complement,
        observations: data.observations,
        usersQuantity: String(data.usersQuantity),
        modules: modulesOptions.filter((module) =>
          data.franchiseModules.some(
            (franchiseModule) => franchiseModule.name === module.value
          )
        ),
        files: data.image ? [data.image] : [],
      });

      setFranchises(data);
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar os dados desse integrador.'
      );

      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFranchise();
    }
  }, []);

  const { user, updateUserFranchises } = useAuth();

  const onSubmit = async (data: FranchiseForm) => {
    try {
      const { modules, files } = data;

      const normalizedModules = modules?.map((module) => module.value);

      const formData = new FormData();

      formData.append('status', data.status.value as string);
      formData.append('enabled', JSON.stringify(data.status.value === 'Ativa'));
      formData.append('cnpj', data.cnpj);
      formData.append('name', data.name);
      formData.append('nickname', data.nickname);
      files[0] && formData.append('file', files[0]);
      formData.append('usersQuantity', data.usersQuantity);
      formData.append('email', data.email);
      formData.append('emailSecondary', data.emailSecondary);
      formData.append('phone', JSON.stringify(data.phone || null));
      formData.append('cellphone', JSON.stringify(data.cellphone || null));
      formData.append('zipcode', data.zipcode);
      formData.append('state', data.state);
      formData.append('city', data.city);
      formData.append('address', data.address);
      formData.append('district', data.district);
      formData.append('buildingNumber', data.buildingNumber);
      formData.append('observations', data.observations);

      if (id) {
        await franchisesApi.put(`/api/franchises/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        await franchisesApi.put(`/api/franchises/${id}/module`, {
          modules: normalizedModules,
        });
      } else {
        const { data } = await franchisesApi.post('/api/franchises', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        await franchisesApi.post(`/api/franchises/${data.id}/module`, {
          modules: normalizedModules,
        });
      }

      const successMessage = id
        ? 'Integrador editado com sucesso'
        : 'Integrador cadastrado com sucesso';

      toast.success(successMessage);

      history.push(`/integradores/`);

      if (id) {
        updateUserFranchises(user.id, id);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      } else {
        toast.error('Erro desconhecido, tente novamente mais tarde.');
      }
    }
  };

  if (loading) {
    return <Loading style={{ alignItems: 'center', height: '100%' }} />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>{id ? 'Editar integrador' : 'Novo integrador'}</span>
      </PageHeader>

      <Content>
        <Form
          ref={formRef}
          initialData={franchise}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Wrapper>
            <legend>Dados do integrador</legend>

            <Grid>
              <Controller
                name="status"
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      label="Status"
                      required
                      name="status"
                      placeholder="Selecione um status"
                      options={statusOptions}
                      error={error?.message}
                      onChange={onChange}
                      value={value}
                    />
                  );
                }}
              />

              <MaskInput
                label="CNPJ"
                mask="99.999.999/9999-99"
                placeholder="Digite o CNPJ"
                required
                {...register('cnpj')}
                error={errors.cnpj?.message}
              />

              <Input
                required
                label="Razão social"
                placeholder="Digite a razão social"
                {...register('name')}
                error={errors.name?.message}
              />

              <Input
                required
                placeholder="Digite o apelido"
                error={errors.nickname?.message}
                label="Apelido"
                {...register('nickname')}
              />

              <Controller
                control={control}
                name="files"
                render={({ field: { onChange, value } }) => {
                  const hasValue = value.length > 0;

                  const handleFileValue = () => {
                    if (hasValue) {
                      return value[0] instanceof File
                        ? { name: value[0].name }
                        : { name: value[0], href: value[0] };
                    }

                    return { name: '' };
                  };

                  const { name, href } = handleFileValue();

                  return (
                    <Dropzone
                      accept={{
                        'image/*': [],
                      }}
                      maxFiles={1}
                      onDropAccepted={onChange}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <InputFile hasValue={hasValue}>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p>
                              <RiUploadCloud2Line size={20} />
                              Imagem
                            </p>
                          </div>

                          {hasValue && (
                            <FileItem
                              as={href ? 'a' : 'span'}
                              href={href ?? ''}
                              target="_blank"
                            >
                              <div>
                                <span>{name}</span>
                              </div>
                              <button
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();

                                  onChange([]);
                                }}
                              >
                                <CloseIcon />
                              </button>
                            </FileItem>
                          )}
                        </InputFile>
                      )}
                    </Dropzone>
                  );
                }}
              />
            </Grid>
          </Wrapper>

          <Divisor />

          <Wrapper>
            <legend>Configurações de acesso</legend>

            <Grid columns={1}>
              <MaskInput
                width="20rem"
                label="Número de usuários"
                placeholder="Informe o número"
                required
                mask="99999999999999"
                {...register('usersQuantity')}
                error={errors.usersQuantity?.message}
              />

              <CheckboxWrapper>
                <label htmlFor="modules">Módulos*</label>

                <div>
                  {modulesOptions.map((module) => {
                    return (
                      <Fragment key={module.value}>
                        <Controller
                          name="modules"
                          control={control}
                          render={({ field: { onChange, ref, value } }) => {
                            const checkedModules = value ?? [];

                            const isChecked = !!checkedModules?.some(
                              (checkedModule) =>
                                checkedModule.value === module.value
                            );

                            return (
                              <Checkbox
                                ref={ref}
                                name={module.label}
                                label={module.label}
                                isChecked={isChecked}
                                onChange={() => {
                                  if (isChecked) {
                                    const filteredModules = value?.filter(
                                      (checkedModule) =>
                                        checkedModule.value !== module.value
                                    );

                                    onChange(filteredModules);

                                    return;
                                  }

                                  onChange([...checkedModules, module]);
                                }}
                              />
                            );
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </div>

                {modulesError && <span>{modulesError}</span>}
              </CheckboxWrapper>
            </Grid>
          </Wrapper>

          <Divisor />

          <Wrapper>
            <legend>Informações de contato</legend>

            <Grid columns={2}>
              <Input
                required
                placeholder="Digite o e-mail"
                error={errors.email?.message}
                label="E-mail"
                {...register('email')}
              />

              <Input
                placeholder="Digite o e-mail secundário"
                error={errors.emailSecondary?.message}
                label="E-mail secundário"
                {...register('emailSecondary')}
              />

              <InputPhone
                label="Celular"
                mask="(99) 99999-9999"
                placeholder="Digite o celular"
                iconLeft={<BRFlag />}
                error={errors.cellphone?.message}
                {...register('cellphone')}
              />

              <InputPhone
                label="Celular 2"
                mask="(99) 9999-9999"
                placeholder="Digite o celular 2"
                iconLeft={<BRFlag />}
                error={errors.phone?.message}
                {...register('phone')}
              />
            </Grid>
          </Wrapper>

          <Divisor />

          <Wrapper>
            <legend>Localização</legend>

            <Grid>
              <MaskInput
                label="CEP"
                mask="99999-999"
                placeholder="Digite o CEP"
                required
                {...register('zipcode')}
                error={errors.zipcode?.message}
              />

              <Input
                required
                placeholder="Digite o estado"
                error={errors.state?.message}
                label="Estado"
                {...register('state')}
              />

              <Input
                required
                placeholder="Digite a cidade"
                error={errors.city?.message}
                label="Cidade"
                {...register('city')}
              />

              <Input
                required
                placeholder="Digite o endereço"
                error={errors.address?.message}
                label="Endereço"
                {...register('address')}
              />

              <Input
                required
                placeholder="Digite o bairro"
                error={errors.district?.message}
                label="Bairro"
                {...register('district')}
              />

              <MaskInput
                label="Número"
                placeholder="Digite o número"
                mask="99999999999"
                required
                error={errors.buildingNumber?.message}
                {...register('buildingNumber')}
              />
            </Grid>

            <div className="text-area">
              <Textarea
                resize="vertical"
                height="12.5rem"
                label="Observações"
                {...register('observations')}
              />
            </div>
          </Wrapper>

          <Button
            style={{ marginLeft: 'auto' }}
            width="9.5rem"
            text="Salvar"
            backgroundColor="#001FFF"
          />
        </Form>
      </Content>
    </Container>
  );
};

export default NewFranchisePage;
