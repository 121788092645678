import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import React, { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ButtonGroup,
  Container,
  FormContainer,
  Group,
  GroupedInputs,
  InputGroup,
  PageHeader,
  Wrapper,
} from '../../../styles';
import * as yup from 'yup';
import { Breadcrumb } from '../../../../components/breadcrumb';
import Input from '../../../../components/input';
import Select, { Option } from '../../../../components/select';
import { Button } from '../../../../components/button';
import franchisesApi from '../../../../services/franchises';
import usersApi from '../../../../services/users';
import { Checkbox } from '../../../../components/form-elements/checkbox';

interface FormTypes {
  id: string;
  name: string;
  email: string;
  role: Option;
  enabled: boolean;
  password: string;
  confirmPassword: string;
}

type NewIntegratorPageProps = {
  franchiseId: string;
  userId?: string;
};

const schema = yup.object().shape({
  name: yup.string().required('Nome é um campo obrigatório'),
  email: yup.string().required('E-mail é um campo obrigatório'),
  role: yup.object().nullable().required('Perfil é um campo obrigatório'),
  password: yup
    .string()
    .min(6, 'A senha deve ter no mínimo 6 dígitos')
    .required('Senha é um campo obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf(
      [yup.ref('password'), null],
      'Senha e repetir senha devem ser iguais'
    )
    .required('Repetir senha é um campo obrigatório'),
});

const NewIntegratorPage: React.FC = () => {
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const { franchiseId, userId } = useParams<NewIntegratorPageProps>();

  const links = [
    {
      id: 1,
      title: 'Integradores',
      link: '/integradores',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de integradores',
      link: '/integradores',
      active: false,
    },
    {
      id: 3,
      title: 'Usuários',
      link: `/integradores/${franchiseId}/membros`,
      active: false,
    },
    {
      id: 4,
      title: userId ? 'Editar usuário' : 'Novo Usuário',
      link: userId
        ? `/integradores/${franchiseId}/membros/${userId}/editar`
        : `/integradores/${franchiseId}/membros/novo`,
      active: true,
    },
  ];

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
    setValue,
    watch,
  } = useForm<FormTypes>({
    resolver: yupResolver(schema),
    defaultValues: {
      enabled: true,
    },
  });

  const roleOptions = [
    {
      label: 'Administrador',
      value: 'IntegratorAdmin',
    },
    {
      label: 'Vendedor',
      value: 'Vendedor',
    },
  ];

  const onSubmit = async (data: FormTypes) => {
    try {
      if (!userId) {
        await franchisesApi.post(`/api/franchises/${franchiseId}/member`, {
          ...data,
          role: data.role.value,
        });

        toast.success('Usuário cadastrado com sucesso');
      } else {
        await usersApi.put(`/api/users/${userId}`, {
          ...data,
          role: data.role.value,
        });

        toast.success('Usuário alterado com sucesso');
      }

      history.push(`/integradores/${franchiseId}/membros`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data.message;

        switch (errorMessage) {
          case 'Email address already used.':
            toast.error('Endereço de e-mail já utilizado');

            return;

          default:
            toast.error(errorMessage);

            return;
        }
      }

      toast.error('Erro desconhecido, tente novamente mais tarde.');
    }
  };

  const handleEditUser = async () => {
    try {
      const { data } = await usersApi.get(`/api/users/${userId}/`);

      reset({
        name: data.name,
        email: data.email,
        role: roleOptions.find((role) => role.value === data.role),
        enabled: data.enabled,
      });
    } catch (error) {
      toast.error(
        'Desculpe, não foi possível buscar as informações do usuário'
      );

      throw error;
    }
  };

  useEffect(() => {
    if (userId) {
      handleEditUser();
    }
  }, [userId]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{userId ? 'Editar usuário' : 'Novo usuário'}</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite o nome completo"
                    error={errors.name?.message}
                    label="Nome completo"
                    {...register('name')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite o e-mail"
                    error={errors.email?.message}
                    label="E-mail"
                    {...register('email')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup>
                  <Controller
                    name="role"
                    control={control}
                    render={({
                      field: { onChange, value, name, ref },
                      fieldState: { error },
                    }) => {
                      return (
                        <Select
                          label="Perfil do usuário"
                          required
                          ref={ref}
                          name={name}
                          onChange={onChange}
                          value={value}
                          placeholder="Selecione um perfil"
                          options={roleOptions}
                          error={error?.message}
                        />
                      );
                    }}
                  />
                </InputGroup>
                <InputGroup sideSpace="right" />
              </GroupedInputs>
            </Group>
            <Group>
              <GroupedInputs>
                <InputGroup sideSpace="right">
                  <Input
                    required
                    placeholder="Digite a senha"
                    error={errors.password?.message}
                    label="Senha"
                    type="password"
                    {...register('password')}
                  />
                </InputGroup>
                <InputGroup>
                  <Input
                    required
                    placeholder="Digite a senha novamente"
                    error={errors.confirmPassword?.message}
                    label="Repetir Senha"
                    type="password"
                    {...register('confirmPassword')}
                  />
                </InputGroup>
              </GroupedInputs>
            </Group>

            <Group>
              <GroupedInputs>
                <InputGroup>
                  <label>Status</label>
                  <Checkbox
                    name="enabled"
                    handleOnclick={(e) => {
                      setValue('enabled', e);
                    }}
                    checked={watch('enabled')}
                  >
                    Ativo
                  </Checkbox>
                </InputGroup>
              </GroupedInputs>
            </Group>

            <Group>
              <GroupedInputs>
                <InputGroup>
                  <ButtonGroup>
                    <Button text="Salvar" backgroundColor="#001FFF" />
                  </ButtonGroup>
                </InputGroup>
              </GroupedInputs>
            </Group>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default NewIntegratorPage;
