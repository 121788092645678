export const UserRoles = {
  ADMIN: 'Admin',
  CENTRAL_ATENDIMENTO: 'Central Atendimento',
  CONSULTOR_SUCESSO: 'Consultor Sucesso',
  ENGENHEIRO: 'Engenheiro',
  FRANQUEADO: 'Franqueado',
  FORNECEDOR: 'Fornecedor',
  INTEGRADOR_ADMIN: 'IntegratorAdmin',
  INTEGRADOR: 'Vendedor',
};

export const getUserRolesOptions = () => {
  const options = Object.values(UserRoles).map((role) => ({
    label: role,
    value: role,
  }));

  return options;
};
