import React, { CSSProperties, useState } from 'react';
import { DraggableProvided, DraggingStyle } from 'react-beautiful-dnd';
import { getStyle } from '../utils/getStyles';
import {
  Container,
  Footer,
  Header,
  Price,
  Tag,
  Tags,
  TitlePrimary,
  TitleSecondary,
  Button,
} from './styles';
import {
  Lead,
  LeadStatus,
} from '../../../../../../contexts/sales-funnel/types';
import { RiArrowRightSLine, RiCalendar2Fill } from 'react-icons/ri';
import { DataCardByColumn } from '../utils/cardByColumn';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { UserRoles } from '../../../../../../types/users';
import { useAuth } from '../../../../../../contexts/auth';
import { KanbanModals } from '../Modals';
import { useSalesFunnel } from '../../../../../../contexts/sales-funnel';
import Skeleton from 'react-loading-skeleton';

export type Modals = {
  [key in LeadStatus]: {
    isOpen: boolean;
    data: Lead | null;
  };
};

interface Props {
  provided: DraggableProvided;
  item: Lead;
  style?: CSSProperties;
  isDragging?: boolean;
}

export function Item(props: Props) {
  const { isDragging, item, provided, style } = props;

  const { user } = useAuth();

  const { loading } = useSalesFunnel();

  const { status } = item.status;

  const isAdminOrConsultant = [
    UserRoles.CONSULTOR_SUCESSO,
    UserRoles.ADMIN,
  ].includes(user.role);

  const price = DataCardByColumn[status](item).hasPrice
    ? item.total
    : undefined;

  const date = format(
    new Date(DataCardByColumn[status](item).date),
    "dd 'de' MMMM yyyy",
    {
      locale: ptBR,
    }
  );

  const isCapture = item.status.status === 'captacao';

  const [modals, setModals] = useState<Modals>({
    captacao: {
      isOpen: false,
      data: null,
    },
    orcamento: {
      isOpen: false,
      data: null,
    },
    negociacao: {
      isOpen: false,
      data: null,
    },
    propostaFinal: {
      isOpen: false,
      data: null,
    },
    recuperacao: {
      isOpen: false,
      data: null,
    },
    agendado: {
      isOpen: false,
      data: null,
    },
    fechado: {
      isOpen: false,
      data: null,
    },
    perdido: {
      isOpen: false,
      data: null,
    },
  });

  const handleOpenModals = (leadStatus: LeadStatus, data: Lead) => {
    setModals((state) => {
      if (leadStatus in state) {
        return {
          ...state,
          [leadStatus]: {
            isOpen: true,
            data,
          },
        };
      }

      return state;
    });
  };

  const handleCloseModals = (leadStatus: LeadStatus) => {
    setModals((state) => {
      if (leadStatus in state) {
        return {
          ...state,
          [leadStatus]: {
            isOpen: false,
            data: null,
          },
        };
      }

      return state;
    });
  };

  if (loading.leads) {
    return (
      <Container
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        style={getStyle({
          draggableStyle: provided.draggableProps.style as DraggingStyle,
          virtualStyle: style,
          isDragging,
        })}
        isDragging={isDragging}
        className="item-card"
      >
        <Header>
          <div>
            <Tags>
              <Skeleton containerClassName="lead-tags-skeleton" />
            </Tags>
            <TitlePrimary>
              <Skeleton width="8rem" />
            </TitlePrimary>
            <TitleSecondary>
              <Skeleton width="6rem" />
            </TitleSecondary>
          </div>
        </Header>

        {/* {price && !isCapture && <Price>{price}</Price>} */}

        <Footer>
          <p>
            <Skeleton containerClassName="lead-date-skeleton" width="4rem" />
          </p>

          <Skeleton width="2rem" height="2rem" />
        </Footer>
      </Container>
    );
  }

  return (
    <Container
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      style={getStyle({
        draggableStyle: provided.draggableProps.style as DraggingStyle,
        virtualStyle: style,
        isDragging,
      })}
      isDragging={isDragging}
      className="item-card"
    >
      <Header>
        <div>
          <Tags>
            {item.tags.map((tag) => {
              return <Tag key={tag.id} bgColor={tag.franchiseTag.color} />;
            })}
          </Tags>
          <TitlePrimary>{item.customer.name}</TitlePrimary>
          <TitleSecondary>
            {isAdminOrConsultant && item.franchise.name}
            {!isAdminOrConsultant && item.franchiseUser?.user?.name}
          </TitleSecondary>
        </div>
      </Header>

      {/* {price && !isCapture && <Price>{price}</Price>} */}

      <Footer>
        <p>
          <RiCalendar2Fill fontSize={18} />
          {date}
        </p>

        <Button type="button" onClick={() => handleOpenModals(status, item)}>
          <RiArrowRightSLine color="#61646B" fontSize={20} />
        </Button>
      </Footer>

      <KanbanModals
        modals={modals}
        onClose={(modal) => handleCloseModals(modal)}
      />
    </Container>
  );
}
