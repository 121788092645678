import React from 'react';

import {
  ChartContainer,
  Container,
  CurrentClassification,
  Details,
  LastClassification,
  Level,
  Title,
} from './styles';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/dist/types/index';
import { numberFormat } from '../../../../../../../utils/numberFormat';

ChartJS.register(ArcElement, Tooltip, Legend);

const chartOptions: ChartOptions<'doughnut'> = {
  circumference: 360,
  responsive: true,
  aspectRatio: 1,
  cutout: 38,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export const levelColors = {
  A: '#001FFF',
  B: '#2EDB09',
  C: '#FAD200',
  D: '#F95300',
  E: '#931010',
};

export type Level = 'A' | 'B' | 'C' | 'D' | 'E';

type Props = {
  remaining: number;
  lastClassification: Level;
  currentClassification: Level;
};

export const Classification: React.FC<Props> = (props) => {
  const { remaining, lastClassification, currentClassification } = props;

  const chartData = {
    labels: ['Loaded', 'Remaining'],
    datasets: [
      {
        label: 'Quantidade',
        data: [100 - remaining, remaining],
        backgroundColor: [levelColors[currentClassification], '#DFE0E2'],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Container>
      <LastClassification>
        <Title>Classificação em 2022</Title>
        <Level version="primary" level={lastClassification}>
          {lastClassification}
        </Level>
      </LastClassification>

      <CurrentClassification>
        <Title>Classificação atual</Title>

        <Details>
          <div>
            <p>Grupo</p>
            <Level version="secondary" level={currentClassification}>
              {currentClassification}
            </Level>
          </div>
          <div>
            <p>Para o próximo nível</p>
            <ChartContainer>
              <Doughnut data={chartData} options={chartOptions} />
              <p>-{String(numberFormat(remaining, 1))}%</p>
            </ChartContainer>
          </div>
        </Details>
      </CurrentClassification>
    </Container>
  );
};
