import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Breadcrumb, Button } from '../../../components';
import Input from '../../../components/input';
import Loading from '../../../components/loading';
import { BackConfirmModal } from '../../../components/modal/profitability-cancel-confirm';
import { useAuth } from '../../../contexts/auth';
import productsApi from '../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../styles';
import { Content, InputWrapper, Warning, Wrapper } from './styles';

type Profitability = {
  minimun: number;
  ideal: number;
  salesCommission: number;
  referralCommission: number;
  invoiceValue: number;
};

const ProfitabilitySchema = yup.object().shape({
  minimun: yup
    .number()
    .typeError('Insira um valor')
    .max(yup.ref('ideal'), 'Deve ser menor ou igual ao lucro ideal')
    .min(0, 'Deve ser maior ou igual a 0'),
  ideal: yup
    .number()
    .typeError('Insira um valor')
    .min(yup.ref('minimun'), 'Deve ser maior ou igual ao lucro ideal'),
  salesComission: yup
    .number()
    .typeError('Insira um valor')
    .min(0, 'Deve ser maior ou igual 0'),
  referralCommission: yup
    .number()
    .typeError('Insira um valor')
    .min(0, 'Deve ser maior ou igual 0'),
  invoiceValue: yup
    .number()
    .typeError('Insira um valor')
    .min(1, 'Deve ser maior que 0'),
});

const links = [
  {
    id: 1,
    title: 'Orçamentos',
    link: '/orcamentos',
    active: false,
  },
  {
    id: 2,
    title: 'Configurar lucratividade',
    link: '/orcamentos/configurar-lucratividade',
    active: true,
  },
];

const BudgetsConfigProfitabilityPage: React.FC = () => {
  const history = useHistory();

  const { selectedFranchise: franchiseId } = useAuth();

  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm<Profitability>({
    resolver: yupResolver(ProfitabilitySchema),
    defaultValues: {
      ideal: 0,
      minimun: 0,
      referralCommission: 0,
      salesCommission: 0,
      invoiceValue: 0,
    },
    mode: 'onChange',
  });

  const [loading, setLoading] = useState(true);

  const getProfitability = async () => {
    if (!franchiseId) {
      toast.info('Selecione um integrador');

      setLoading(false);

      throw new Error('Franchise not selected');
    }

    try {
      const { data } = await productsApi.get<Profitability>(
        '/budgets/profitability',
        {
          params: {
            franchiseId,
          },
        }
      );

      reset({
        minimun: data.minimun,
        ideal: data.ideal,
        salesCommission: data.salesCommission,
        referralCommission: data.referralCommission,
        invoiceValue: data.invoiceValue,
      });

      await trigger();
    } catch (error) {
      toast.error('Desculpe, não foi possível buscar os dados.');

      throw error;
    } finally {
      setLoading(false);
    }
  };

  const updateProfitability = async (profitability: Profitability) => {
    try {
      await productsApi.post<Profitability>('/budgets/profitability', {
        ...profitability,
        franchiseId,
        isPercentage: true,
      });

      reset({}, { keepValues: true });

      toast.success('Configurações salvas!');
    } catch (error) {
      toast.error('Não foi possível salvar suas alterações.');

      throw error;
    }
  };

  useEffect(() => {
    getProfitability();
  }, [franchiseId]);

  if (loading)
    return (
      <Loading
        style={{
          height: '100%',
          alignItems: 'center',
        }}
      />
    );

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Configurações de lucratividade'}</span>
      </PageHeader>

      <Content onSubmit={handleSubmit(updateProfitability)}>
        <Wrapper>
          <h1>Margem de Lucro Bruto</h1>

          <InputWrapper>
            <Input
              step="0.01"
              label="Margem de instalação mínima"
              type="number"
              iconLeft="%"
              {...register('minimun', {
                valueAsNumber: true,
              })}
              error={errors.minimun?.message}
            />

            <Input
              step="0.01"
              label="Margem de instalação ideal"
              type="number"
              iconLeft="%"
              {...register('ideal', {
                valueAsNumber: true,
              })}
              error={errors.ideal?.message}
            />

            <Warning>
              <strong>Atenção</strong>

              <p>
                Recomendamos que a margem de instalação mínima não seja inferior
                a 30%.
              </p>
            </Warning>
          </InputWrapper>
        </Wrapper>

        <Wrapper>
          <h1>Comissões</h1>

          <InputWrapper>
            <Input
              step="0.01"
              label="Comissão do vendedor"
              type="number"
              iconLeft="%"
              {...register('salesCommission', {
                valueAsNumber: true,
              })}
              error={errors.salesCommission?.message}
            />

            <Input
              step="0.01"
              label="Comissão por indicação"
              type="number"
              iconLeft="%"
              {...register('referralCommission', {
                valueAsNumber: true,
              })}
              error={errors.referralCommission?.message}
            />
          </InputWrapper>
        </Wrapper>

        <Wrapper>
          <h1>Nota Fiscal</h1>

          <InputWrapper>
            <Input
              step="0.01"
              label="Valor da Nota"
              type="number"
              iconLeft="%"
              {...register('invoiceValue', {
                valueAsNumber: true,
              })}
              error={errors.invoiceValue?.message}
            />
          </InputWrapper>
        </Wrapper>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              isDirty
                ? setOpenConfirmationModal(true)
                : history.replace('/orcamentos/')
            }
          />
          <Button
            type="submit"
            disabled={!isDirty || !isValid}
            text="Salvar alterações"
          />
        </ButtonGroup>
      </Content>
      <BackConfirmModal
        isOpen={openConfirmationModal}
        onCancel={() => setOpenConfirmationModal(false)}
        onConfirm={() => history.replace('/orcamentos')}
      />
    </Container>
  );
};

export default BudgetsConfigProfitabilityPage;
