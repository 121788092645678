export const statusOptions = [
  {
    label: 'Ativa',
    value: 'Ativa',
  },
  {
    label: 'Inativa',
    value: 'Inativa',
  },
];
