import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Breadcrumb } from '../../../components';
import { Container, PageHeader, Wrapper } from '../../styles';
import { Budgets } from './components/budgets';
import { Orders } from './components/orders';
import { Content, Tab, Tabs, TabsContainer } from './styles';
import { useAuth } from '../../../contexts/auth';
import { UserRoles } from '../../../types/users';

type IsLoading = {
  budgets: boolean;
  orders: boolean;
};

type StateLocation = {
  activeTab: string;
};

const BudgetsOrdersPage: React.FC = () => {
  const { state } = useLocation<StateLocation>();
  const { user } = useAuth();

  const userRole = user.role;

  const isProvider = user.role === UserRoles.FORNECEDOR;

  const links = isProvider
    ? [
        {
          id: 1,
          title: 'Pedidos',
          link: '/orcamentos',
          active: true,
        },
      ]
    : [
        {
          id: 1,
          title: 'Orçamentos',
          link: '/orcamentos',
          active: false,
        },
        {
          id: 2,
          title: 'Orçamentos e Pedidos',
          link: '/orcamentos/orcamentos-e-pedidos',
          active: true,
        },
      ];

  const [activeTab, setActiveTab] = useState(state?.activeTab || 'Orçamentos');
  const [isLoading, setIsLoading] = useState<IsLoading>({
    budgets: false,
    orders: false,
  });

  const handleActiveTab = (tabName: string) => {
    setActiveTab(tabName);
  };

  return (
    <Container style={{ paddingBottom: '4rem' }}>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{isProvider ? 'Pedidos' : 'Orçamentos e Pedidos'}</span>
        </PageHeader>

        <Content>
          {!isProvider && (
            <>
              {' '}
              <TabsContainer>
                <Tabs>
                  <Tab
                    onClick={() => handleActiveTab('Orçamentos')}
                    isActive={activeTab === 'Orçamentos'}
                    disabled={isLoading.orders}
                  >
                    Orçamentos
                  </Tab>
                  <Tab
                    onClick={() => handleActiveTab('Pedidos')}
                    isActive={activeTab === 'Pedidos'}
                    disabled={isLoading.budgets}
                  >
                    Pedidos enviados
                  </Tab>
                </Tabs>
              </TabsContainer>
              {activeTab.includes('Orçamentos') && (
                <Budgets
                  handleLoading={(loading) =>
                    setIsLoading((state) => ({ ...state, budgets: loading }))
                  }
                />
              )}
              {activeTab.includes('Pedidos') && (
                <Orders
                  handleLoading={(loading) =>
                    setIsLoading((state) => ({ ...state, orders: loading }))
                  }
                  userRole={userRole}
                />
              )}
            </>
          )}

          {isProvider && (
            <Orders
              handleLoading={(loading) =>
                setIsLoading((state) => ({ ...state, orders: loading }))
              }
              userRole={userRole}
            />
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default BudgetsOrdersPage;
