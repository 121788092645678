import { UserRoles } from '../types/users';

export const getBudgetOptions = (role: string) => {
  switch (role) {
    case UserRoles.ADMIN:
      return [
        {
          title: 'Novo orçamento',
          route: '/orcamentos/selecionar-produto/cadastro-cliente',
        },
        {
          title: 'Orçamentos e Pedidos',
          route: '/orcamentos/orcamentos-e-pedidos',
        },
        {
          title: 'Configurações de lucratividade',
          route: '/orcamentos/configurar-lucratividade',
        },
        {
          title: 'Lucratividade nas vendas',
          route: '/orcamentos/lucratividade',
        },
      ];

    case UserRoles.FRANQUEADO:
    case UserRoles.CONSULTOR_SUCESSO:
      return [
        {
          title: 'Novo orçamento',
          route: '/orcamentos/selecionar-produto/cadastro-cliente',
        },
        {
          title: 'Orçamentos e Pedidos',
          route: '/orcamentos/orcamentos-e-pedidos',
        },
        {
          title: 'Configurações de lucratividade',
          route: '/orcamentos/configurar-lucratividade',
        },
        {
          title: 'Lucratividade nas vendas',
          route: '/orcamentos/lucratividade',
        },
      ];

    case UserRoles.INTEGRADOR:
    case UserRoles.INTEGRADOR_ADMIN:
      return [
        {
          title: 'Novo orçamento',
          route: '/orcamentos/selecionar-produto/cadastro-cliente',
        },
        {
          title: 'Orçamentos e Pedidos',
          route: '/orcamentos/orcamentos-e-pedidos',
        },
        {
          title: 'Configurações de lucratividade',
          route: '/orcamentos/configurar-lucratividade',
        },
        {
          title: 'Lucratividade nas vendas',
          route: '/orcamentos/lucratividade',
        },
      ];

    default:
      return [
        {
          title: 'Novo orçamento',
          route: '/orcamentos/selecionar-produto/cadastro-cliente',
        },
        {
          title: 'Orçamentos e Pedidos',
          route: '/orcamentos/orcamentos-e-pedidos',
        },
      ];
  }
};

export const getProductsOptions = (role: string) => {
  switch (role) {
    case UserRoles.CONSULTOR_SUCESSO:
      return [{ title: 'Lista de produtos', route: '/produtos/lista' }];

    default:
      return [
        { title: 'Lista de produtos', route: '/produtos/lista' },
        { title: 'Importação', route: '/produtos/importacao' },
        { title: 'Desconto e rebate', route: '/produtos/descontos-rebates' },
      ];
  }
};
