import React from 'react';

import {
  Breadcrumb,
  CoinsIcon,
  DocListIcon,
  ImportIcon,
  ProfitabilityIcon,
} from '../../components';
import {
  Container,
  LinkBox,
  LinkBoxGroup,
  PageHeader,
  Wrapper,
} from './../styles';

const links = [
  {
    id: 1,
    title: 'Orçamentos',
    link: '/orcamentos',
    active: true,
  },
];

const BudgetsMenuPage: React.FC = () => {
  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Orçamentos'}</span>
        </PageHeader>

        <LinkBoxGroup>
          <LinkBox href="/orcamentos/selecionar-produto/cadastro-cliente">
            <DocListIcon />
            <span>Novo orçamento</span>
            <p>Cadastre seu cliente e crie um novo orçamento</p>
          </LinkBox>
          <LinkBox href="/orcamentos/orcamentos-e-pedidos">
            <ImportIcon />
            <span>Orçamentos e pedidos</span>
            <p>Consulte e analise os orçamentos enviados para seus clientes</p>
          </LinkBox>

          {/* <LinkBox href="/orcamentos/solicitacoes">
            <CoinsIcon />
            <span>Solicitações ao consultor</span>
            <p>Veja os status dos pedidos encaminhados ao seu consultor</p>
          </LinkBox> */}

          <LinkBox href="/orcamentos/configurar-lucratividade">
            <CoinsIcon />
            <span>Configurar lucratividade</span>
            <p>
              Configure os parâmetros que irão definir sua lucratividade nas
              vendas
            </p>
          </LinkBox>

          <LinkBox href="/orcamentos/lucratividade">
            <ProfitabilityIcon />
            <span>Lucratividade nas vendas</span>
            <p>
              Acompanhe a margem de lucro líquida em cada uma das suas vendas.
            </p>
          </LinkBox>
        </LinkBoxGroup>
      </Wrapper>
    </Container>
  );
};

export default BudgetsMenuPage;
