import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { Breadcrumb, Button } from '../../../components';
import Input from '../../../components/form-elements/input';
import { FormContainer, GroupedInputs, InputGroup } from '../../styles';
import { Container, PageHeader, Wrapper } from '../styles';
import {
  ButtonContainer,
  PaymentButtonContainer,
  PaymentContainer,
  PaymentIcon,
} from './style';

import imgLink from '../../../assets/icon/link.svg';
import { useAuth } from '../../../contexts/auth';
import projectsApi from '../../../services/projects';
import { ProjectStatus } from '../types';
import PaymentModal from './components/PaymentModal';

type CallCenterPaymentPageProps = {
  id: string;
};

type PaymentType = {
  value: string;
  discount: string;
  payment_link: string;
};

const CallCenterPaymentPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const { id } = useParams<CallCenterPaymentPageProps>();

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    // value: yup.string().required('Valor é um campo obrigatório'),
    // discount: yup.string().required('Desconto é um campo obrigatório'),
    // payment_link: yup
    //   .string()
    //   .required('Link do pagamento é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<PaymentType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: PaymentType) => {
    try {
      await projectsApi.post(`/api/projects/${id}/status-log`, {
        userId: user.id,
        observation: 'Link de pagamento gerado',
        status: ProjectStatus.AGUARDANDO_PAGAMENTO,
      });

      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: ProjectStatus.AGUARDANDO_PAGAMENTO,
      });

      toast.success('Status do projeto atualizado com sucesso');
      history.push('/projetos/central-atendimento');
    } catch (error: any) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Pagamento</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <GroupedInputs>
              <InputGroup size="small" sideSpace="right">
                <Input
                  placeholder="R$ 0000.00"
                  name="value"
                  onChange={(e: any) => setValue('value', e.target.value)}
                  label="Valor do projeto"
                  error={errors.value?.message}
                />
              </InputGroup>
              <InputGroup size="small">
                <Input
                  placeholder="R$ 0000.00"
                  name="discount"
                  onChange={(e: any) => setValue('discount', e.target.value)}
                  label="Desconto"
                  error={errors.discount?.message}
                />
              </InputGroup>
            </GroupedInputs>
            <InputGroup>
              <PaymentIcon>
                <span className="icon">
                  <img src={imgLink} alt="link" />
                </span>
                <span className="title">Link do pagamento</span>
              </PaymentIcon>
              <PaymentContainer>
                <Input
                  placeholder=""
                  name="payment_link"
                  onChange={(e: any) =>
                    setValue('payment_link', e.target.value)
                  }
                  label="Link de Pagamento"
                  error={errors.payment_link?.message}
                />
                <span>Copiar link</span>
              </PaymentContainer>
              <PaymentButtonContainer>
                <Button
                  text="Gerar link"
                  backgroundColor="#DFE0E2"
                  backgroundHoverColor="#DFE0E2"
                />
              </PaymentButtonContainer>
            </InputGroup>

            <ButtonContainer>
              <Button
                text="Cancelar"
                backgroundColor="#DFE0E2"
                backgroundHoverColor="#B2B3B5"
                onClick={() => history.push('/projetos/central-atendimento')}
                style={{ marginRight: 16 }}
              />

              <PaymentModal id={id} handleOnClick={() => null} />

              <Button
                text="Salvar"
                backgroundColor="#001FFF"
                backgroundHoverColor="#2772f8"
              />
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default CallCenterPaymentPage;
