import React, { useState } from 'react';
import Modal from 'react-modal';

import { useAuth } from '../../../../../contexts/auth';
import projectsApi from '../../../../../services/projects';
import { ProjectStatus } from '../../../types';
import { toast } from 'react-toastify';

import {
  Buttons,
  ButtonsContainer,
  CancelButtonContainer,
  Container,
  ModalContainer,
} from './styles';

import { ActionButton, Button } from '../../../../../components';
import { FiFile } from 'react-icons/fi';

type InputProps = {
  id?: string;
  handleOnClick: () => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const InspectionResult: React.FC<InputProps> = ({ id, handleOnClick }) => {
  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleAproveInspect = async (id?: string) => {
    await projectsApi.post(`/api/projects/${id}/status-log`, {
      userId: user.id,
      observation: ProjectStatus.VISTORIA_APROVADA,
      status: ProjectStatus.VISTORIA_APROVADA,
    });

    await projectsApi.patch(`/api/projects/${id}/status`, {
      status: ProjectStatus.VISTORIA_APROVADA,
    });

    toast.success('Vistoria aprovada com sucesso');
    setIsOpen(false);
    handleOnClick();
  };

  const handleReproveInspect = async (id?: string) => {
    await projectsApi.post(`/api/projects/${id}/status-log`, {
      userId: user.id,
      observation: ProjectStatus.VISTORIA_REPROVADA,
      status: ProjectStatus.VISTORIA_REPROVADA,
    });

    await projectsApi.patch(`/api/projects/${id}/status`, {
      status: ProjectStatus.VISTORIA_REPROVADA,
    });

    toast.success('Vistoria reprovada com sucesso');
    setIsOpen(false);
    handleOnClick();
  };

  return (
    <Container>
      <ActionButton
        tooltip="Resultado Vistoria"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FiFile />
      </ActionButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <p>Você tem certeza que deseja concluir o projeto?</p>
          <Buttons>
            <ButtonsContainer>
              <Button
                type="submit"
                text="Vistoria Reprovada"
                backgroundColor="#ff0000"
                backgroundHoverColor="#ff4040"
                style={{ marginRight: 30 }}
                onClick={() => {
                  handleReproveInspect(id);
                }}
              />
              <Button
                type="submit"
                text="Vistoria aprovada"
                backgroundColor="#001FFF"
                backgroundHoverColor="#2772f8"
                onClick={() => {
                  handleAproveInspect(id);
                }}
              />
            </ButtonsContainer>
            <CancelButtonContainer>
              <Button
                type="submit"
                text="Cancelar"
                backgroundColor="#DFE0E2"
                backgroundHoverColor="#DFE0E2"
                style={{ marginRight: 30 }}
                onClick={() => setIsOpen(false)}
              />
            </CancelButtonContainer>
          </Buttons>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default InspectionResult;
