import React, { useEffect, useRef, useState } from 'react';
import { FiCpu, FiUsers } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo-white.png';
import { useAuth } from '../../contexts/auth';
import {
  getBudgetOptions,
  getProductsOptions,
} from '../../helper/MenuOptionsByRole';
import { getPermissionsByRole } from '../../helper/PermissionsByRole';
import { useOnClickOutside } from '../../hooks/outsideClick';
import { CollapsibleOptions } from '../collapsible-options';
import {
  ArrowRightIcon,
  FranchisersIcon,
  KanbanIcon,
  LogoutIcon,
  MoneyIcon,
  ProductsIcon,
  ProjectsIcon,
  ProvidersIcon,
  SettingsIcon,
} from '../icons';
import { CloseButton, Container, Content, Divider, Logo } from './styles';

type Props = {
  open: boolean;
  handleSidebar: () => void;
  handleOutSideClick: () => void;
};

export type Permissions = {
  users: boolean;
  franchises: boolean;
  providers: boolean;
  products: boolean;
  budgets: boolean;
  projects: boolean;
  salesFunnel: boolean;
  projectsURL: string;
  customers: boolean;
  orders: boolean;
  config: boolean;
  shipping: boolean;
  paymentMethods: boolean;
};

export const Menu: React.FC<Props> = (props) => {
  const { open, handleSidebar, handleOutSideClick } = props;

  const { signOut, user, franchises } = useAuth();

  const franchiseModules = franchises.flatMap(({ modules }) => modules);

  const [permissions, setPermissions] = useState<Permissions | null>(null);

  const [collapsibleOpened, setCollapsibleOpened] = useState<string[]>([]);

  const menuRef = useRef<HTMLElement>(null);
  useOnClickOutside(menuRef, handleOutSideClick);

  const budgetOptions = getBudgetOptions(user.role);

  const productOptions = getProductsOptions(user.role);

  const toggCollapsibleOpened = (menu: string): void => {
    if (!collapsibleOpened.includes(menu)) {
      return setCollapsibleOpened((state) => [...state, menu]);
    }

    return setCollapsibleOpened(() =>
      collapsibleOpened.filter((item) => {
        return item !== menu;
      })
    );
  };

  useEffect(() => {
    setPermissions(getPermissionsByRole(user.role, franchiseModules));
  }, [user.role]);

  return (
    <Container open={open} ref={menuRef}>
      <div>
        <div className="menu-wrapper">
          <Logo>
            <img src={logo} alt="ItiSun" />
          </Logo>
          <CloseButton onClick={handleSidebar}>
            <ArrowRightIcon />
          </CloseButton>
        </div>
        <Content onClick={handleSidebar}>
          <ul>
            {permissions?.salesFunnel && (
              <li>
                <NavLink to="/funil-de-vendas">
                  <KanbanIcon />
                  <span>Funil de vendas</span>
                </NavLink>
              </li>
            )}

            {permissions?.franchises && (
              <li>
                <NavLink to="/integradores">
                  <FranchisersIcon />
                  <span>Integradores</span>
                </NavLink>
              </li>
            )}

            {permissions?.users && (
              <li>
                <NavLink to="/usuarios">
                  <FiUsers />
                  <span>Usuários sistema</span>
                </NavLink>
              </li>
            )}

            {permissions?.providers && (
              <li>
                <NavLink to="/fornecedores">
                  <ProvidersIcon />
                  <span>Fornecedores</span>
                </NavLink>
              </li>
            )}

            {permissions?.products && (
              <CollapsibleOptions
                className={
                  collapsibleOpened.includes('Produtos') ? 'active' : ''
                }
                icon={<FiCpu />}
                title="Produtos"
                titleLink="/produtos"
                options={productOptions}
                toggleOpen={(menu: string): void => toggCollapsibleOpened(menu)}
                opened={collapsibleOpened.includes('Produtos')}
              />
            )}

            {permissions?.budgets && (
              <CollapsibleOptions
                className={
                  collapsibleOpened.includes('Orçamentos') ? 'active' : ''
                }
                icon={<ProductsIcon />}
                title="Orçamentos"
                titleLink="/orcamentos"
                options={budgetOptions}
                toggleOpen={(menu: string): void => toggCollapsibleOpened(menu)}
                opened={collapsibleOpened.includes('Orçamentos')}
              />
            )}

            {permissions?.customers && (
              <li>
                <NavLink to="/clientes">
                  <FiUsers />
                  <span>Clientes</span>
                </NavLink>
              </li>
            )}

            {permissions?.projects && (
              <li>
                <NavLink to={permissions?.projectsURL}>
                  <ProjectsIcon />
                  <span>Engenharia</span>
                </NavLink>
              </li>
            )}

            {permissions?.orders && (
              <li>
                <NavLink to="/orcamentos/orcamentos-e-pedidos">
                  <ProductsIcon />
                  <span>Pedidos</span>
                </NavLink>
              </li>
            )}

            {permissions?.config && (
              <li>
                <NavLink to={`/fornecedor/${user.name}/editar`}>
                  <SettingsIcon />
                  <span>Configurações</span>
                </NavLink>
              </li>
            )}

            {permissions?.shipping && (
              <li>
                <NavLink to={`/fornecedor/${user.name}/frete`}>
                  <ProvidersIcon />
                  <span>Frete</span>
                </NavLink>
              </li>
            )}

            {permissions?.paymentMethods && (
              <li>
                <NavLink to={`/fornecedor/${user.name}/formas-de-pagamento`}>
                  <MoneyIcon />
                  <span>Formas de pagamento</span>
                </NavLink>
              </li>
            )}
          </ul>
          <Divider />
          <ul>
            <li>
              <button onClick={() => signOut()}>
                <LogoutIcon />
                <span>Desconectar</span>
              </button>
            </li>
          </ul>
        </Content>
      </div>
    </Container>
  );
};
