import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import SelectInput from '../select';
import { Container } from './styles';
import { UserRoles } from '../../types/users';

type OptionsType = {
  label: string;
  value: string;
  enabled: boolean;
};

type FranchiseSelectorProps = {};

export const FranchiseSelector: React.FC<FranchiseSelectorProps> = () => {
  const history = useHistory();

  const { pathname } = history.location;

  const { franchises, selectFranchise, selectedFranchise, user } = useAuth();

  const hasFranchises = franchises.length > 0;

  const isIntegrator =
    user.role === UserRoles.INTEGRADOR_ADMIN ||
    user.role === UserRoles.INTEGRADOR;

  const isEditBudgetFlow =
    pathname.split('/').includes('orcamentos') &&
    pathname.split('/').includes('editar');

  const [selected, setSelected] = useState<string>();

  const [franchiseOptions, setFranchiseOptions] = useState<OptionsType[]>([]);

  useEffect(() => {
    setSelected(selectedFranchise ?? '');
  }, [selectedFranchise]);

  useEffect(() => {
    if (hasFranchises) {
      setFranchiseOptions(
        franchises.map(({ franchise }) => {
          return {
            label: !franchise.enabled
              ? franchise.name + ' (Inativa)'
              : franchise.name,
            value: franchise.id,
            enabled: franchise.enabled,
          };
        })
      );
    }
  }, [franchises]);

  const handleSelectChange = (franchise: string) => {
    selectFranchise(franchise);
  };

  if (isIntegrator) {
    return null;
  }

  return (
    <Container>
      <SelectInput
        isDisabled={isEditBudgetFlow}
        name="franchise"
        onChange={(option) => {
          handleSelectChange((option?.value as string) ?? '');
        }}
        options={franchiseOptions}
        placeholder="Selecione um integrador"
        isClearable
        isOptionDisabled={(option) => !(option as OptionsType).enabled}
        value={
          franchiseOptions.find((franchise) => franchise.value === selected) ??
          ''
        }
      />
    </Container>
  );
};
