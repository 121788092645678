import styled from 'styled-components';

export const Container = styled.div`
  max-height: calc(100vh - 14rem);
  min-height: calc(100vh - 14rem);
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  min-width: 17.5rem;

  margin: 0 1rem;
`;

type HeaderProps = {
  color: string;
};

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  padding-top: 0.25rem;

  background-color: ${(props) => props.color};

  border-radius: 4px;

  & > div {
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0.5rem;

    background-color: #ffffff;

    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    border-width: 0 1px 1px;
    border-style: solid;
    border-color: #c9cbcf;

    & > h2 {
      font-family: 'DunbarTallRegular';
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.03em;
      color: ${(props) => props.color};
    }

    & span {
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #27282b;

      background-color: ${(props) => props.color + '40'};

      padding: 0.25rem;
      border-radius: 4px;
    }
  }
`;

