import React, { useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../../contexts/auth';
import projectsApi from '../../../../../services/projects';
import { ProjectStatus } from '../../../types';
import { toast } from 'react-toastify';

import { Buttons, Container, ModalContainer } from './styles';
import { ActionButton, Button } from '../../../../../components';
import { FiCheckCircle } from 'react-icons/fi';

type InputProps = {
  id?: string;
  handleOnClick: () => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ConcludeModal: React.FC<InputProps> = ({ id, handleOnClick }) => {
  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleConcludeProject = async (id?: string) => {
    await projectsApi.post(`/api/projects/${id}/status-log`, {
      userId: user.id,
      observation: 'Projeto concluído',
      status: ProjectStatus.PROJETO_CONCLUIDO,
    });

    await projectsApi.patch(`/api/projects/${id}/status`, {
      status: ProjectStatus.PROJETO_CONCLUIDO,
    });

    toast.success('Projeto concluído com sucesso');
    setIsOpen(false);
    handleOnClick();
  };

  return (
    <Container>
      <ActionButton
        tooltip="Concluir projeto"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <FiCheckCircle />
      </ActionButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <p>Você tem certeza que deseja concluir o projeto?</p>
          <Buttons>
            <Button
              type="submit"
              text="Cancelar"
              backgroundColor="#DFE0E2"
              backgroundHoverColor="#DFE0E2"
              style={{ marginRight: 30 }}
              onClick={() => setIsOpen(false)}
            />
            <Button
              type="submit"
              text="Concluir"
              backgroundColor="#001FFF"
              backgroundHoverColor="#2772f8"
              onClick={() => {
                handleConcludeProject(id);
              }}
            />
          </Buttons>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default ConcludeModal;
