import { UserRoles } from '../types/users';

export const userURLByRole = (role: keyof typeof UserRoles) => {
  const usersURL = {
    [UserRoles.ADMIN]: {
      loggedIn: '/funil-de-vendas',
      signIn: '/funil-de-vendas',
    },
    [UserRoles.CENTRAL_ATENDIMENTO]: {
      loggedIn: '/projetos/central-atendimento',
      signIn: '/dashboard',
    },
    [UserRoles.ENGENHEIRO]: {
      loggedIn: '/projetos/engenharia/listar',
      signIn: '/projetos/engenharia/listar',
    },
    [UserRoles.FRANQUEADO]: {
      loggedIn: '/orcamentos',
      signIn: '/orcamentos',
    },
    [UserRoles.CONSULTOR_SUCESSO]: {
      loggedIn: '/orcamentos',
      signIn: '/orcamentos',
    },
    [UserRoles.FORNECEDOR]: {
      loggedIn: '/orcamentos/orcamentos-e-pedidos',
      signIn: '/orcamentos/orcamentos-e-pedidos',
    },
    [UserRoles.INTEGRADOR_ADMIN]: {
      loggedIn: '/orcamentos',
      signIn: '/orcamentos',
    },
    [UserRoles.INTEGRADOR]: {
      loggedIn: '/orcamentos',
      signIn: '/orcamentos',
    },
  };

  return usersURL[role];
};
