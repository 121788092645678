import { Permissions } from '../components';
import { UserRoles } from '../types/users';

const initialState: Permissions = {
  users: false,
  franchises: false,
  providers: false,
  products: false,
  budgets: false,
  projects: true,
  salesFunnel: false,
  projectsURL: '/projetos',
  customers: false,
  orders: false,
  config: false,
  paymentMethods: false,
  shipping: false,
};

export const getPermissionsByRole = (role: string, modules: string[]) => {
  const permissionsByRole = {
    [UserRoles.ADMIN]: {
      ...initialState,
      users: true,
      franchises: true,
      providers: true,
      products: true,
      budgets: true,
      salesFunnel: true,
    },
    [UserRoles.CENTRAL_ATENDIMENTO]: {
      ...initialState,
      projectsURL: '/projetos/central-atendimento',
    },
    [UserRoles.ENGENHEIRO]: {
      ...initialState,
      projectsURL: '/projetos/engenharia/listar',
    },
    [UserRoles.FRANQUEADO]: {
      ...initialState,
      salesFunnel: true,
      budgets: true,
      customers: true,
    },
    [UserRoles.CONSULTOR_SUCESSO]: {
      ...initialState,
      budgets: true,
      franchises: true,
      users: true,
      providers: true,
      salesFunnel: true,
      products: true,
    },
    [UserRoles.FORNECEDOR]: {
      ...initialState,
      projects: false,
      orders: true,
      config: true,
      paymentMethods: true,
      shipping: true,
    },
    [UserRoles.INTEGRADOR_ADMIN]: {
      ...initialState,
      salesFunnel: modules.includes('CRM'),
      customers: modules.includes('Clientes'),
      budgets: modules.includes('ERP'),
      projects: modules.includes('Engenharia'),
    },
    [UserRoles.INTEGRADOR]: {
      ...initialState,
      salesFunnel: modules.includes('CRM'),
      customers: modules.includes('ERP'),
      budgets: modules.includes('ERP'),
      projects: modules.includes('Engenharia'),
    },
  };

  const permissions = permissionsByRole[role];

  if (permissions) return permissions;

  return initialState;
};
