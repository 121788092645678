import { yupResolver } from '@hookform/resolvers/yup';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../components';
import Input from '../../components/form-elements/input';
import { useAuth } from '../../contexts/auth';
import { userURLByRole } from '../../helper/UserURLByRole';
import { UserRoles } from '../../types/users';
import { Container, ForgotPassword, FormWrapper, Group } from './styles';

type LoginType = {
  email: string;
  password: string;
};

const HomePage: React.FC = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const schema = yup.object().shape({
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é um campo obrigatório'),
    password: yup.string().required('Senha é um campo obrigatório'),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: LoginType) => {
    try {
      const { user } = await signIn({
        email: data.email,
        password: data.password,
      });

      history.push(userURLByRole(user.role as keyof typeof UserRoles).signIn);
    } catch (error) {
      toast.error('Dados de acesso inválidos');
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <span>ItiSun Hub: a sua plataforma de energia solar</span>

        <FormWrapper>
          <Group>
            <Input
              style={{
                height: '2.5rem',
              }}
              name="email"
              label="E-mail"
              placeholder="Digite o seu e-mail"
              onChange={(e) => setValue('email', e.target.value)}
              error={errors.email?.message}
            />
          </Group>
          <Group>
            <Input
              style={{
                height: '2.5rem',
              }}
              name="password"
              label="Senha"
              placeholder="Digite sua senha"
              onChange={(e) => setValue('password', e.target.value)}
              error={errors.password?.message}
              type="password"
            />
          </Group>
          <ForgotPassword>
            <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
          </ForgotPassword>
          <Button
            text="Entrar"
            size="large"
            backgroundColor="#FFC129"
            backgroundHoverColor="#eda800"
            fontColor="#00299B"
            type="submit"
          />
        </FormWrapper>
      </Form>
    </Container>
  );
};

export default HomePage;
