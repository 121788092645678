import React from 'react';
import { ShapeModal } from '..';
import { Button } from '../..';
import { Buttons } from '../styles';
import { Content } from './styles';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

export const InfoProfitabilityModal: React.FC<Props> = (props) => {
  const { onConfirm, isOpen } = props;

  return (
    <ShapeModal
      isOpen={isOpen}
      onRequestClose={onConfirm}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Content>
        <h1>Bem vindo ao Sistema ItiSun! </h1>

        <article>
          <p>
            Antes de começar a criar orçamentos, gostaríamos de lembrá-lo da
            importância de configurar os padrões de lucratividade. Essa etapa
            crucial ajudará a garantir que seus orçamentos sejam precisos e
            eficazes.
          </p>

          <p>
            Uma vez que você tenha concluído essa configuração, estará pronto
            para começar a criar orçamentos de forma mais precisa e eficiente.
          </p>

          <p>Atenciamente, Equipe ItiSun.</p>
        </article>

        <Buttons>
          <Button
            type="button"
            typeStyle="confirm"
            backgroundColor="#001FFF"
            text="Configurar lucratividade"
            onClick={onConfirm}
          />
        </Buttons>
      </Content>
    </ShapeModal>
  );
};
