import React from 'react';
import { Switch } from 'react-router-dom';
import ScrollToTop from '../hooks/scrollToTop';
import { AnnouncementsPage } from '../pages/announcements';
import BudgetsMenuPage from '../pages/budgets';
import BudgetsOrdersPage from '../pages/budgets/budgets-orders';
import OrdersAccessoriesPage from '../pages/budgets/budgets-orders/orders-accessories';
import OrdersCostsPage from '../pages/budgets/budgets-orders/orders-costs';
import OrdersDocumentsPage from '../pages/budgets/budgets-orders/orders-documents';
import OrdersPaymentPage from '../pages/budgets/budgets-orders/orders-payment-methods';
import OrdersSelectionKitPage from '../pages/budgets/budgets-orders/orders-selection-kit';
import OrdersSendTermsPage from '../pages/budgets/budgets-orders/orders-send-terms';
import { OrdersSubmitted } from '../pages/budgets/budgets-orders/orders-submitted';
import OrdersSummaryPage from '../pages/budgets/budgets-orders/orders-summary';
import OrdersTermsPage from '../pages/budgets/budgets-orders/orders-terms';
import BudgetsConfigProfitabilityPage from '../pages/budgets/config-profitability';
import BudgetsNewPage from '../pages/budgets/new-budget';
import BudgetsCustomKitPage from '../pages/budgets/new-budget/new-budget-custom-kit';
import CustomKitAnalysisRequestPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-build/analysis-request';
import BudgetsCustomerRegistrationPage from '../pages/budgets/new-budget/new-budget-customer-registration';
import BudgetsProposalPage from '../pages/budgets/new-budget/new-budget-proposal';
import BudgetsSelectionKitPage from '../pages/budgets/new-budget/new-budget-selection-kit';
import BudgetsServicesPage from '../pages/budgets/new-budget/new-budget-services';
import BudgetsProfitabilityPage from '../pages/budgets/profitability';
import BudgetsProfitabilityDetailsPage from '../pages/budgets/profitability/profitability-details';
import { CampaignsPage } from '../pages/campaigns';
import CustomersListPage from '../pages/customers/list';
import CustomersEditPage from '../pages/customers/list/edit';
import DashboardPage from '../pages/dashboard';
import ForgotPasswordPage from '../pages/forgot-password';
import IntegratorsPage from '../pages/integrators';
import NewIntegratorsPage from '../pages/integrators/new';
import IntegratorUsersPage from '../pages/integrators/users';
import HomePage from '../pages/home';
import { JuridicalPage } from '../pages/juridical';
import { ManagementPage } from '../pages/management';
import { NPSPage } from '../pages/nps';
import ProductsMenuPage from '../pages/products';
import ProductsDiscountAbatementPage from '../pages/products/discount-abatement';
import EditKitSettedPage from '../pages/products/edit-kit-setted';
import EditKitSettedDiscountAbatementPage from '../pages/products/edit-kit-setted/edit-discount-abatement';
import ProductsImportPage from '../pages/products/import';
import ProductsImportSettedKitsPage from '../pages/products/import-setted-kits';
import ProductsImportSettedKitsAnalyzePage from '../pages/products/import-setted-kits/import-setted-kits-analyze';
import ProductsImportSettedKitsProcessPage from '../pages/products/import-setted-kits/import-setted-kits-process';
import KitListPage from '../pages/products/kit-list';
import ProductsListPage from '../pages/products/list';
import ProjectsPage from '../pages/projects';
import ProjectCallCenterPage from '../pages/projects/call-center';
import CallCenterDocumentationPage from '../pages/projects/call-center-documentation';
import ProjectCallCenterExtrasPage from '../pages/projects/call-center-extras';
import ProjectCallCenterFilesPage from '../pages/projects/call-center-files';
import CallCenterPaymentPage from '../pages/projects/call-center-payment';
import CallCenterResponsiblePage from '../pages/projects/call-center-responsible';
import ProjectCallCenterShowPage from '../pages/projects/call-center-show';
import DonwloadProjectFilesPage from '../pages/projects/download-files';
import ProjectEngineeringPage from '../pages/projects/engineering';
import EngineeringInspectPage from '../pages/projects/engineering-inspect';
import EngineeringProjectFilesPage from '../pages/projects/engineering-project-files';
import ImpedimentPage from '../pages/projects/impediment';
import InspectionRequestPage from '../pages/projects/inspection-request';
import NewProjectPage from '../pages/projects/new-project';
import NewProjectDocumentPage from '../pages/projects/new-project-document';
import NewProjectDocumentExtraPage from '../pages/projects/new-project-document-extra';
import NewProjectFormPage from '../pages/projects/new-project-form';
import PowerCompanyFeedbackPage from '../pages/projects/power-company-feedback';
import ProvidersPage from '../pages/providers';
import NewProviderPage from '../pages/providers/new';
import ProvidersPaymentMethodsPage from '../pages/providers/payment-methods';
import ProvidersShippingPage from '../pages/providers/shipping';
import ResetPasswordPage from '../pages/reset-password';
import SalesFunnelPage from '../pages/sales-funnel';
import UsersPage from '../pages/users';
import LinkedIntegratorsPage from '../pages/users/franchise';
import NewUserPage from '../pages/users/new';
import Route from './Route';
import CustomKitBuildKitPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-build';
import CustomKitProductSelectionPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-build/product-selection';
import CustomKitCompositionPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-composition';
import CustomKitSelectKitsPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-select-kits';
import CustomKitServicesPage from '../pages/budgets/new-budget/new-budget-custom-kit/custom-kit-services';
import NewIntegratorPage from '../pages/integrators/users/new';

const Routes: React.FC = () => {
  return (
    <ScrollToTop>
      <Switch>
        <Route path="/" component={HomePage} exact />

        <Route path="/dashboard" component={DashboardPage} exact isPrivate />

        <Route
          path="/funil-de-vendas"
          component={SalesFunnelPage}
          exact
          isPrivate
        />

        {/* Begin Integrator */}
        <Route
          path="/integradores"
          component={IntegratorsPage}
          exact
          isPrivate
        />

        <Route
          path="/integradores/:id/membros"
          component={IntegratorUsersPage}
          exact
          isPrivate
        />

        <Route
          path="/integradores/:franchiseId/membros/novo"
          component={NewIntegratorPage}
          exact
          isPrivate
        />

        <Route
          path="/integradores/:franchiseId/membros/:userId/editar"
          component={NewIntegratorPage}
          exact
          isPrivate
        />

        <Route
          path="/integradores/novo"
          component={NewIntegratorsPage}
          exact
          isPrivate
        />

        <Route
          path="/integradores/:id/editar"
          component={NewIntegratorsPage}
          exact
          isPrivate
        />

        {/* End integrators */}

        {/* Begin Users */}

        <Route path="/usuarios" component={UsersPage} exact isPrivate />

        <Route path="/usuarios/novo" component={NewUserPage} exact isPrivate />

        <Route
          path="/usuarios/:id/franquias"
          component={LinkedIntegratorsPage}
          exact
          isPrivate
        />

        <Route
          path="/usuarios/editar/:id"
          component={NewUserPage}
          exact
          isPrivate
        />

        {/* End Users */}

        {/* Begin Products */}
        <Route path="/produtos" component={ProductsMenuPage} exact isPrivate />

        <Route
          path="/produtos/lista"
          component={ProductsListPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/lista/:type"
          component={KitListPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/lista/kit-pronto/:id/editar"
          component={EditKitSettedPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/lista/kit-pronto/:id/editar/desconto-e-rebate"
          component={EditKitSettedDiscountAbatementPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/importacao"
          component={ProductsImportPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/importacao/kit-pronto"
          component={ProductsImportSettedKitsPage}
          exact
          isPrivate
        />
        <Route
          path="/produtos/importacao/kit-pronto/:id/conferencia"
          component={ProductsImportSettedKitsAnalyzePage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/importacao/kit-pronto/:id/processamento"
          component={ProductsImportSettedKitsProcessPage}
          exact
          isPrivate
        />

        <Route
          path="/produtos/descontos-rebates"
          component={ProductsDiscountAbatementPage}
          exact
          isPrivate
        />
        {/* End Products */}

        {/* Begin Budgets */}

        <Route path="/orcamentos" component={BudgetsMenuPage} exact isPrivate />

        <Route
          path="/orcamentos/selecionar-produto"
          component={BudgetsNewPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id?"
          component={BudgetsCustomerRegistrationPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id?/editar"
          component={BudgetsCustomerRegistrationPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits"
          component={BudgetsSelectionKitPage}
          exact
          isPrivate
        />
        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits/editar"
          component={BudgetsSelectionKitPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits/servicos/"
          component={BudgetsServicesPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits/servicos/editar"
          component={BudgetsServicesPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits/servicos/proposta/"
          component={BudgetsProposalPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/selecao-de-kits/servicos/proposta/editar"
          component={BudgetsProposalPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/servicos/proposta/"
          component={BudgetsProposalPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit"
          component={BudgetsCustomKitPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/selecionar-kits"
          component={CustomKitSelectKitsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/selecionar-kits/:customItemId/editar"
          component={CustomKitSelectKitsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/selecionar-kits/composicao/:customItemId"
          component={CustomKitCompositionPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/selecionar-kits/composicao/:customItemId/editar"
          component={CustomKitCompositionPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/montar-kit"
          component={CustomKitBuildKitPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/montar-kit/selecao-de-produtos/"
          component={CustomKitProductSelectionPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/montar-kit/selecao-de-produtos/composicao"
          component={CustomKitCompositionPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/montar-kit/selecao-de-produtos/solicitacao-de-analise"
          component={CustomKitAnalysisRequestPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/selecionar-produto/cadastro-cliente/:id/personalizar-kit/servicos/"
          component={CustomKitServicesPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos"
          component={BudgetsOrdersPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione/"
          component={OrdersSelectionKitPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/resumo"
          component={OrdersSummaryPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/acessorios"
          component={OrdersAccessoriesPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/custos"
          component={OrdersCostsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/formas-de-pagamento"
          component={OrdersPaymentPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/documentos"
          component={OrdersDocumentsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/termo-de-aceite"
          component={OrdersTermsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/gerar-pedido/:id/termo-de-aceite/enviar"
          component={OrdersSendTermsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/configurar-lucratividade"
          component={BudgetsConfigProfitabilityPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/lucratividade"
          component={BudgetsProfitabilityPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/lucratividade/:id/detalhes"
          component={BudgetsProfitabilityDetailsPage}
          exact
          isPrivate
        />

        <Route
          path="/orcamentos/orcamentos-e-pedidos/pedidos-enviados/:orderId/:status"
          component={OrdersSubmitted}
          exact
          isPrivate
        />

        {/* End Budgets */}

        {/* Begin Customers */}

        <Route path="/clientes" component={CustomersListPage} exact isPrivate />

        <Route
          path="/clientes/:id/editar"
          component={CustomersEditPage}
          exact
          isPrivate
        />

        {/* End Customers */}

        {/* Begin Projects */}
        <Route path="/projetos" component={ProjectsPage} exact isPrivate />

        <Route
          path="/projeto/novo"
          component={NewProjectPage}
          exact
          isPrivate
        />
        <Route
          path="/projeto/novo/formulario"
          component={NewProjectFormPage}
          exact
          isPrivate
        />
        <Route
          path="/projeto/:id/editar"
          component={NewProjectFormPage}
          exact
          isPrivate
        />
        <Route
          path="/projeto/novo/formulario/documentos/:id"
          component={NewProjectDocumentPage}
          exact
          isPrivate
        />
        <Route
          path="/projeto/novo/formulario/documentos/extra/:id"
          component={NewProjectDocumentExtraPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/central-atendimento"
          component={ProjectCallCenterPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id"
          component={ProjectCallCenterShowPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/arquivos"
          component={ProjectCallCenterFilesPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/extras"
          component={ProjectCallCenterExtrasPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/pagamento"
          component={CallCenterPaymentPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/responsavel"
          component={CallCenterResponsiblePage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/analise-documentacao"
          component={CallCenterDocumentationPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/engenharia/listar"
          component={ProjectEngineeringPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/engenharia/projeto/:id/arquivos"
          component={EngineeringProjectFilesPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/engenharia/:id/parecer-concessionaria"
          component={PowerCompanyFeedbackPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/engenharia/:id/vistoria"
          component={EngineeringInspectPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/impedimento"
          component={ImpedimentPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/solicitar-vistoria"
          component={InspectionRequestPage}
          exact
          isPrivate
        />
        <Route
          path="/projetos/:id/download"
          component={DonwloadProjectFilesPage}
          exact
          isPrivate
        />
        {/* End Projects */}

        {/* Begin Providers */}
        <Route path="/fornecedores" component={ProvidersPage} exact isPrivate />

        <Route
          path="/fornecedor/novo"
          component={NewProviderPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedor/:name/editar"
          component={NewProviderPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedores/:id/editar"
          component={NewProviderPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedor/:name/formas-de-pagamento"
          component={ProvidersPaymentMethodsPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedores/:id/formas-de-pagamento"
          component={ProvidersPaymentMethodsPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedor/:name/frete"
          component={ProvidersShippingPage}
          exact
          isPrivate
        />

        <Route
          path="/fornecedores/:id/frete"
          component={ProvidersShippingPage}
          exact
          isPrivate
        />
        {/* End Providers */}

        {/* Begin Management */}
        <Route path="/gestao" component={ManagementPage} exact isPrivate />

        {/* End Management */}

        {/* Begin Announcements */}
        <Route
          path="/comunicados"
          component={AnnouncementsPage}
          exact
          isPrivate
        />

        {/* End Announcements */}

        {/* Begin Campaigns */}
        <Route path="/campanhas" component={CampaignsPage} exact isPrivate />

        {/* End Campaigns */}

        {/* Begin NPS */}
        <Route path="/nps" component={NPSPage} exact isPrivate />

        {/* End NPS */}

        {/* Begin Juridical */}
        <Route path="/juridico" component={JuridicalPage} exact isPrivate />

        {/* End Juridical */}

        <Route
          path="/esqueci-minha-senha"
          component={ForgotPasswordPage}
          exact
        />
        <Route path="/resetar-senha" component={ResetPasswordPage} exact />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
