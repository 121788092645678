import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, { useMemo, useState } from 'react';
import { FiXCircle } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb } from '../../../../../../components/breadcrumb';
import { Button } from '../../../../../../components/button';
import { CloudIcon } from '../../../../../../components/icons';
import { CancelOrderModal } from '../../../../../../components/modal/cancel-order';
import { Order, OrderFile } from '../../../../../../contexts/orders/types';
import productsApi from '../../../../../../services/products';
import { downloadFile } from '../../../../../../utils/downloadFile';
import { ButtonGroup, Container, PageHeader } from '../../../../../styles';
import { ORDER_STATUS } from '../../../components/orders/innerTables/consultant';
import { SentDate } from '../../../styles';
import { Content, Documents, Voucher, Wrapper } from './styles';

type ConsultantBoletoNffProps = {
  order: Order;
};

type OrderFiles = {
  BOLETO: OrderFile[];
  NFE: OrderFile[];
  NFF: OrderFile[];
};

export const ConsultantBoletoNff: React.FC<ConsultantBoletoNffProps> = (
  props
) => {
  const { order } = props;

  const { orderId, status } = useParams<{
    orderId: string;
    status: ORDER_STATUS;
  }>();

  const files = useMemo(() => {
    return (
      order.files.reduce(
        (acc: OrderFiles, file: File) => {
          if (file.type in acc) {
            return {
              ...acc,
              [file.type]: [...acc[file.type as keyof OrderFiles], file],
            };
          }

          return acc;
        },
        {
          BOLETO: [],
          NFE: [],
          NFF: [],
        }
      ) ?? {
        BOLETO: [],
        NFE: [],
        NFF: [],
      }
    );
  }, [order]);

  const hasBoleto = !!files.BOLETO.length;

  const hasNFE = !!files.NFE.length;

  const hasNFF = !!files.NFF.length;

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e Pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Pedidos enviados',
      link: `/orcamentos/orcamentos-e-pedidos/pedidos-enviados/${orderId}/${status}`,
      active: true,
    },
  ];

  const [cancelModal, setCancelModal] = useState(false);

  const cancelOrder = () => {
    productsApi
      .patch(`/orders/${order.id}/status`, {
        status: 'Pedido cancelado',
        franchiseStatus: 'Pedido cancelado',
      })
      .then(() => {
        toast.success('Status do pedido atualizado');

        history.replace('/orcamentos/orcamentos-e-pedidos/');
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;

        toast.error(errorMessage);
      });
  };

  const handleCancelModal = () => {
    setCancelModal((state) => !state);
  };

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pedidos enviados'}</span>
      </PageHeader>

      <Content>
        <Documents>
          <div>
            <h1>Documentos enviados pelo fornecedor</h1>

            <Wrapper>
              {hasBoleto && (
                <ul>
                  <label>Boleto/espelho</label>

                  {files.BOLETO.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => downloadFile(file.path)}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}

              {hasNFE && (
                <ul>
                  <label>NFE</label>

                  {files.NFE.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => downloadFile(file.path)}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}

              {hasNFF && (
                <ul>
                  <label>NFF/espelho</label>

                  {files.NFF.map((file) => {
                    return (
                      <li key={file.id}>
                        <Button
                          type="button"
                          backgroundColor="#FFFFFF"
                          typeStyle="default"
                          text="Fazer download"
                          onClick={() => downloadFile(file.path)}
                          icon={<CloudIcon />}
                        />
                        <SentDate>
                          Enviado em:{' '}
                          {format(
                            new Date(file.updatedAt as string),
                            'dd/MM/yyyy',
                            {
                              locale: ptBR,
                            }
                          )}
                        </SentDate>
                      </li>
                    );
                  })}
                </ul>
              )}
            </Wrapper>
          </div>
        </Documents>

        <Voucher>
          <h1>Comprovante de pagamento</h1>

          <div>
            <p>O integrador ainda não enviou o comprovante de pagamento.</p>

            <Button
              type="button"
              backgroundColor="#E01919"
              backgroundHoverColor="#e01919c1"
              typeStyle="default"
              text="Cancelar pedido"
              onClick={handleCancelModal}
              icon={<FiXCircle />}
            />
          </div>
        </Voucher>

        <ButtonGroup>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.replace('/orcamentos/orcamentos-e-pedidos', 'Pedidos')
            }
          />
        </ButtonGroup>
      </Content>

      <CancelOrderModal
        isOpen={cancelModal}
        onConfirm={cancelOrder}
        onCancel={handleCancelModal}
      />
    </Container>
  );
};
