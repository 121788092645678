import { yupResolver } from '@hookform/resolvers/yup';
import axios, { AxiosError } from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  Breadcrumb,
  Button,
  InfoIcon,
  StepProgress,
} from '../../../../components';
import Checkbox from '../../../../components/checkbox';
import InputPrice from '../../../../components/input-price';
import Loading from '../../../../components/loading';
import RadioGroup from '../../../../components/radio-group';
import SelectInput from '../../../../components/select';
import SelectAsync, { Option } from '../../../../components/select-async';
import { useOrders } from '../../../../contexts/orders';
import productsApi from '../../../../services/products';
import { normalize } from '../../../../utils/normalize';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import {
  Cards,
  Content,
  OrderTotalPrice,
  PaymentDetailsContainer,
  PaymentMethods,
  PaymentMethodsContainer,
} from './styles';

enum PaymentTypes {
  bankSlip = 'BANK_SLIP',
  creditCard = 'CREDIT_CARD',
  pix = 'PIX',
  financing = 'FINANCING',
  bankTransfer = 'TRANSFER',
  entryCreditCard = 'ENTRY_CREDIT_CARD',
  entryFinancing = 'ENTRY_FINANCING',
}

type Card = {
  id?: string;
  name: number;
  value: string;
  normalizedValue: number;
};

type Form = {
  paymentType: string;
  bankName: string;
  energyPartnership: boolean;
  entryType: string;
  entryValue: string;
  multipleCreditCard: boolean;
  creditCardInstalment: InstallmentOption | null;
  cards: Card[];
};

export type InstallmentOption = {
  value: number | string;
  label: string;
  id: string;
  supplierPaymentMethodId: string;
  instalmentNumber: number;
  fee: string;
};

type Supplier = {
  id: string;
  name: string;
  personName: string;
  website: string;
  email: string;
  phone: string;
  zipcode: string;
  state: string;
  city: string;
  address: string;
  district: string;
  addressNumber: string;
  complement: string;
  deleted: boolean;
};

export type PaymentMethods = {
  bankSlip: boolean;
  bankTransfer: boolean;
  creditCard: boolean;
  entryCreditCard: boolean;
  entryFinancing: boolean;
  financing: boolean;
  pix: boolean;
  installmentsNumber: number;
  id: string;
  paymentMethodInstalmentOptions: InstallmentOption[];
  supplier: Supplier;
  supplierId: string;
};

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: false, complete: true },
  { key: 3, title: 'Acessórios', active: false, complete: true },
  { key: 4, title: 'Custos', active: false, complete: true },
  { key: 5, title: 'Pagamento', active: true, complete: false },
  { key: 6, title: 'Documentos', active: false, complete: false },
  { key: 7, title: 'Termo de aceite', active: false, complete: false },
];

const OrdersPaymentPage: React.FC = () => {
  const history = useHistory();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: false,
    },
    {
      id: 6,
      title: 'Acessórios',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`,
      active: false,
    },
    {
      id: 7,
      title: 'Custos',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`,
      active: false,
    },
    {
      id: 8,
      title: 'Pagamento',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/formas-de-pagamento`,
      active: true,
    },
  ];

  const { order, handleOrder, getOrder } = useOrders();

  const { kits, total } = order?.items?.reduce(
    (acc, item) => {
      return {
        ...acc,
        kits: acc.kits + item.price,
        total: acc.total + item.price + item.maintenance + item.income,
      };
    },
    {
      kits: 0,
      total: 0,
    }
  ) || {
    kits: 0,
    total: 0,
  };

  const [totalInCreditCard, setTotalInCreditCard] = useState(kits);

  const schema = yup.object().shape(
    {
      paymentType: yup.string().required('Selecione uma opção'),
      bankName: yup.string().when('paymentType', (paymentType, schema) => {
        if (paymentType === 'entryFinancing' || paymentType === 'financing') {
          return schema.required('Selecione uma opção');
        } else {
          schema;
        }
      }),
      energyPartnership: yup.boolean(),
      entryType: yup.string(),
      entryValue: yup.string().when('paymentType', (paymentType, schema) => {
        if (paymentType === 'entryCreditCard') {
          return schema
            .required('Insira um valor')
            .test({
              name: 'is empty',
              test: (value: string) => normalize(value ?? '') !== 0,
              message: 'Insira um valor',
            })
            .test({
              name: 'is greater than total',
              test: (value: string) =>
                normalize(value ?? '') <= totalInCreditCard,
              message: 'Valor muito alto',
            });
        } else if (paymentType === 'entryFinancing') {
          return schema
            .required('Insira um valor')
            .test({
              name: 'is empty',
              test: (value: string) => normalize(value ?? '') !== 0,
              message: 'Insira um valor',
            })
            .test({
              name: 'is greater than total',
              test: (value: string) => normalize(value ?? '') <= totalFinanced,
              message: 'Valor muito alto',
            });
        } else {
          schema;
        }
      }),
      multipleCreditCard: yup.boolean(),
      creditCardInstalment: yup.mixed<InstallmentOption>().nullable(),
      cards: yup.array().when('multipleCreditCard', {
        is: true,
        then: yup
          .array<Card>()
          .of(
            yup.object().shape({
              value: yup
                .string()
                .required('Insira um valor')
                .test({
                  name: 'not-zero',
                  test: (value) => normalize(value ?? '') !== 0,
                  message: 'O valor deve ser maior que 0',
                }),
            })
          )
          .test({
            name: 'total',
            test: (values) => {
              const total =
                values?.reduce((acc, card) => {
                  return acc + (card.normalizedValue as number);
                }, 0) ?? 0;

              return Number(total?.toFixed(2)) === totalInCreditCard;
            },
            message:
              'A soma dos cartões deve ser igual ao valor em cartão de crédito',
          }),
        otherwise: yup.array().of(
          yup.object().shape({
            value: yup.string(),
          })
        ),
      }),
    },
    [
      ['paymentType', 'entryValue'],
      ['multipleCreditCard', 'cards'],
      ['cards', ''],
    ]
  );

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, errors },
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      paymentType: '',
      bankName: '',
      energyPartnership: false,
      entryType: 'Boleto',
      entryValue: '',
      multipleCreditCard: false,
      cards: [
        {
          name: 1,
          value: '',
          normalizedValue: 0,
        },
        { name: 2, value: '', normalizedValue: 0 },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const paymentType = watch('paymentType');
  const multipleCreditCard = watch('multipleCreditCard');
  const entry = watch('entryValue');
  const instalmentNumber = watch('creditCardInstalment.instalmentNumber');
  const cards = watch('cards');

  const [methods, setMethods] = useState({} as PaymentMethods);

  const [loading, setLoading] = useState(true);

  const { fields, append, update } = useFieldArray({
    control,
    name: 'cards',
    keyName: 'key',
  });

  const totalFinanced = total - normalize(entry);

  const cardValueKit = kits - normalize(entry);

  const onSubmit = async (data: Form) => {
    setLoading(true);

    const normalizedCards = fields.map((card) => {
      return {
        value: card.normalizedValue,
        ...(card.id && { id: card.id }),
      };
    });

    const updatedPayment = {
      paymentType: PaymentTypes[data.paymentType as keyof typeof PaymentTypes],
      bankName: data.bankName.length ? data.bankName : null,
      creditCardInstalmentNumber: data.creditCardInstalment?.instalmentNumber,
      energyPartnership: data.energyPartnership,
      entryType: data.entryType,
      multipleCreditCard: data.multipleCreditCard,
      creditCardValue: totalInCreditCard,
      entryValue: normalize(entry),
      creditCards: normalizedCards,
    };

    try {
      const { data } = await productsApi.put(
        `/orders/${orderId}/payment`,
        updatedPayment
      );

      handleOrder(data);

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/documentos/`
      );
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);

      setLoading(false);
    }
  };

  const getPaymentsMethods = async (supplierName: string) => {
    try {
      const { data } = await productsApi.get<PaymentMethods>(
        `/products/suppliers/supplier-payment-method/${supplierName}/name`
      );

      setMethods(data);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      switch (errorMessage) {
        case `Payment methods for supplier ${supplierName} not found`:
          toast.error('Meio de pagamento não encontrado');

          break;

        default:
          toast.error(errorMessage);

          break;
      }

      history.push(
        `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos`
      );
    } finally {
      setLoading(false);
    }
  };

  const getBanks = async (inputValue: string) => {
    const { data } = await axios.get('/banks.json');

    const filteredOptions: Option[] = data.filter((bank: Option) =>
      bank.label.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredOptions;
  };

  const normalizeInstallmentOptions = (options: InstallmentOption[] = []) => {
    const orderedInstallmentOptions = options.sort(
      (a, b) => a.instalmentNumber - b.instalmentNumber
    );

    return orderedInstallmentOptions.map((option) => {
      return {
        ...option,
        label: `${option.instalmentNumber}x (${option.fee}% de juros)`,
        value: option.fee,
      };
    });
  };

  const installmentOptions =
    normalizeInstallmentOptions(methods.paymentMethodInstalmentOptions) ?? [];

  const calculateFee = async (instalmentNumber: number, entryValue: number) => {
    try {
      const { data } = await productsApi.post(
        `/orders/${methods.supplierId}/${orderId}/calculate-fee`,
        {
          instalmentNumber,
          entryValue,
        }
      );

      const totalWithTax = data.total;

      setTotalInCreditCard(totalWithTax);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      toast.error(errorMessage);
    }
  };

  const debouncedFunc = debounce(
    async (installmentNumber, entry) =>
      await calculateFee(installmentNumber, entry),
    300
  );

  const fetchData = async () => {
    const { items } = await getOrder(orderId);

    const supplierName = items.reduce((acc, item) => {
      const customItem = item.customItem;

      return (acc = customItem?.supplier || item.product?.supplier || '');
    }, '');

    await getPaymentsMethods(supplierName);
  };

  useEffect(() => {
    reset({
      paymentType,
      bankName: '',
      cards: [
        {
          name: 1,
          value: '',
          normalizedValue: 0,
        },
        { name: 2, value: '', normalizedValue: 0 },
      ],
      creditCardInstalment: installmentOptions[0],
      entryValue: '',
      energyPartnership: false,
      entryType: 'Boleto',
      multipleCreditCard: false,
    });
  }, [paymentType]);

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Pagamento'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content onSubmit={handleSubmit(onSubmit)}>
        <h1>Formas de pagamento</h1>
        <PaymentMethodsContainer>
          <PaymentMethods>
            {methods.bankSlip && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="bankSlip"
                      label="Boleto"
                      isChecked={value === 'bankSlip'}
                      onChange={() => {
                        onChange('bankSlip');
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.bankTransfer && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="bankTransfer"
                      label="Transferência bancária"
                      isChecked={value === 'bankTransfer'}
                      onChange={() => {
                        onChange('bankTransfer');
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.creditCard && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="creditCard"
                      label="Cartão de crédito"
                      isChecked={value === 'creditCard'}
                      onChange={() => {
                        onChange('creditCard');

                        calculateFee(1, 0);
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.entryCreditCard && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="entryCreditCard"
                      label="Entrada + cartão de crédito"
                      isChecked={value === 'entryCreditCard'}
                      onChange={() => {
                        onChange('entryCreditCard');

                        calculateFee(1, 0);
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.entryFinancing && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="entryFinancing"
                      label="Entrada + financiamento"
                      isChecked={value === 'entryFinancing'}
                      onChange={() => {
                        onChange('entryFinancing');
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.financing && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="financing"
                      label="Financiamento"
                      isChecked={value === 'financing'}
                      onChange={() => {
                        onChange('financing');
                      }}
                    />
                  );
                }}
              />
            )}

            {methods.pix && (
              <Controller
                name="paymentType"
                control={control}
                render={({ field: { onChange, ref, value } }) => {
                  return (
                    <Checkbox
                      ref={ref}
                      name="pix"
                      label="Pix"
                      isChecked={value === 'pix'}
                      onChange={() => {
                        onChange('pix');
                      }}
                    />
                  );
                }}
              />
            )}
          </PaymentMethods>
          <OrderTotalPrice>
            <span>Valor total</span>
            <strong>{toMoneyFormat(total)}</strong>
          </OrderTotalPrice>
        </PaymentMethodsContainer>

        {paymentType === 'financing' && (
          <>
            <h1>Detalhes do pagamento</h1>
            <PaymentDetailsContainer className="financing">
              <Controller
                name="bankName"
                control={control}
                render={({
                  field: { ref, onChange },
                  formState: { errors },
                }) => {
                  return (
                    <SelectAsync
                      ref={ref}
                      className="input-bank"
                      label="Banco"
                      placeholder="Informe o banco"
                      style={{ width: '33.75rem' }}
                      getOptions={(inputValue) => getBanks(inputValue)}
                      onChange={(value) => {
                        const option = value as SingleValue<Option>;

                        onChange(option?.label);
                      }}
                      error={errors.bankName?.message}
                    />
                  );
                }}
              />

              <Controller
                name="energyPartnership"
                control={control}
                render={({ field: { ref, onChange, value } }) => {
                  return (
                    <RadioGroup
                      ref={ref}
                      label="Em parceria com a  ItiSun?"
                      options={[
                        { label: 'Sim', value: 'true' },
                        { label: 'Não', value: 'false' },
                      ]}
                      onChange={(event) => {
                        const value = JSON.parse(event.target.value);

                        onChange(value);
                      }}
                      rowMode
                      value={JSON.stringify(value)}
                    />
                  );
                }}
              />
            </PaymentDetailsContainer>
          </>
        )}

        {paymentType === 'entryFinancing' && (
          <>
            <h1>Detalhes do pagamento</h1>
            <PaymentDetailsContainer className="financing-entry">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1.5rem',
                  flex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4rem',
                  }}
                >
                  <Controller
                    name="entryType"
                    control={control}
                    render={({ field: { ref, onChange, value } }) => {
                      return (
                        <RadioGroup
                          label="Forma da entrada?"
                          options={[
                            { label: 'Pix', value: 'Pix' },
                            { label: 'Boleto', value: 'Boleto' },
                          ]}
                          rowMode
                          ref={ref}
                          onChange={(event) => {
                            const value = event.target.value;

                            onChange(value);
                          }}
                          value={value}
                        />
                      );
                    }}
                  />

                  <InputPrice
                    className="entry-value"
                    label="Valor da entrada"
                    placeholder="R$ 1.000,00"
                    style={{ width: '14.5rem' }}
                    error={errors.entryValue?.message}
                    {...register('entryValue')}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    gap: '1.75rem',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    flex: 1,
                  }}
                >
                  <Controller
                    name="bankName"
                    control={control}
                    render={({
                      field: { ref, onChange },
                      formState: { errors },
                    }) => {
                      return (
                        <SelectAsync
                          ref={ref}
                          className="input-bank"
                          label="Banco"
                          placeholder="Informe o banco"
                          style={{ maxWidth: '33.75rem', width: '100%' }}
                          getOptions={(inputValue) => getBanks(inputValue)}
                          onChange={(value) => {
                            const option = value as SingleValue<Option>;

                            onChange(option?.label);
                          }}
                          error={errors.bankName?.message}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="energyPartnership"
                    control={control}
                    render={({ field: { ref, onChange, value } }) => {
                      return (
                        <RadioGroup
                          ref={ref}
                          label="Em parceria com a  ItiSun?"
                          options={[
                            { label: 'Sim', value: 'true' },
                            { label: 'Não', value: 'false' },
                          ]}
                          onChange={(event) => {
                            const value = JSON.parse(event.target.value);

                            onChange(value);
                          }}
                          rowMode
                          value={JSON.stringify(value)}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '1rem',
                }}
              >
                <OrderTotalPrice style={{ marginBottom: '1.5rem' }}>
                  <span>Valor financiado</span>
                  <strong>{toMoneyFormat(totalFinanced)}</strong>
                </OrderTotalPrice>
              </div>
            </PaymentDetailsContainer>
          </>
        )}

        {(paymentType === 'entryCreditCard' ||
          paymentType === 'creditCard') && (
          <>
            <h1>Detalhes do pagamento</h1>
            <PaymentDetailsContainer className="entry-creditcard">
              {paymentType === 'entryCreditCard' && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      width: '100%',
                      gap: '1.75rem',
                    }}
                  >
                    <Controller
                      name="entryType"
                      control={control}
                      render={({ field: { ref, onChange, value } }) => {
                        return (
                          <RadioGroup
                            style={{ maxWidth: '16.125rem', width: '100%' }}
                            label="Forma da entrada?"
                            options={[
                              { label: 'Pix', value: 'Pix' },
                              { label: 'Boleto', value: 'Boleto' },
                            ]}
                            rowMode
                            ref={ref}
                            onChange={(event) => {
                              const value = event.target.value;

                              onChange(value);
                            }}
                            value={value}
                          />
                        );
                      }}
                    />

                    <InputPrice
                      label="Valor da entrada"
                      placeholder="R$ 1.000,00"
                      style={{ width: '16.125rem' }}
                      error={errors.entryValue?.message}
                      {...register('entryValue', {
                        onChange: async (event) =>
                          await debouncedFunc(
                            instalmentNumber ?? 1,
                            normalize(event.target.value) ?? 0
                          ),
                      })}
                    />
                  </div>
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  gap: '4rem',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    gap: '1.75rem',
                  }}
                >
                  <Controller
                    name="creditCardInstalment"
                    control={control}
                    render={({
                      field: { ref, onChange, value },
                      fieldState: { error },
                    }) => {
                      return (
                        <SelectInput
                          ref={ref}
                          style={{ maxWidth: '16.125rem' }}
                          label="Parcelas"
                          placeholder="Número de parcelas"
                          options={installmentOptions}
                          onChange={(option) => {
                            onChange(option);

                            debouncedFunc(
                              (option as InstallmentOption).instalmentNumber,
                              normalize(entry)
                            );
                          }}
                          defaultValue={installmentOptions[0]}
                          value={value as Option}
                          message={
                            <>
                              <InfoIcon />
                              Valor em cartão do kit:{' '}
                              {toMoneyFormat(cardValueKit)}
                            </>
                          }
                          error={error?.message}
                        />
                      );
                    }}
                  />

                  <Controller
                    name="multipleCreditCard"
                    control={control}
                    render={({ field: { ref, onChange, value } }) => {
                      return (
                        <RadioGroup
                          label="Mais de um cartão de crédito?"
                          options={[
                            { label: 'Sim', value: 'true' },
                            { label: 'Não', value: 'false' },
                          ]}
                          rowMode
                          ref={ref}
                          onChange={(event) => {
                            const value = JSON.parse(event.target.value);

                            onChange(value);
                          }}
                          value={JSON.stringify(value)}
                        />
                      );
                    }}
                  />
                </div>

                <OrderTotalPrice hasErrors={!isValid}>
                  <span>Valor em cartão de crédito</span>
                  <strong>{toMoneyFormat(totalInCreditCard)}</strong>
                </OrderTotalPrice>
              </div>

              {multipleCreditCard && (
                <Cards>
                  <div>
                    {fields.map((card, index) => {
                      return (
                        <InputPrice
                          key={card.name}
                          label={`Valor no cartão ${card.name}`}
                          placeholder="R$ 1.000,00"
                          error={errors.cards?.[index]?.value?.message}
                          {...register(`cards.${index}.value`, {
                            onChange: (event) => {
                              const value = event.target.value ?? '';

                              update(index, {
                                ...fields[index],
                                value,
                                normalizedValue: normalize(value ?? ''),
                              });
                            },
                          })}
                        />
                      );
                    })}
                  </div>
                  <Button
                    type="button"
                    text="Adicionar mais cartões"
                    onClick={() => {
                      const card = {
                        name: cards.length + 1,
                        value: '',
                        normalizedValue: 0,
                      };

                      append(card);

                      // dispatch({
                      //   type: ActionType.ADD_CREDITCARD_CARD,
                      //   payload: {
                      //     ...payments,
                      //     creditCard: {
                      //       ...payments.creditCard,
                      //       cards: [...payments.creditCard.cards, card],
                      //     },
                      //   },
                      // });
                    }}
                    icon="add"
                  />
                </Cards>
              )}
            </PaymentDetailsContainer>
          </>
        )}
        <ButtonGroup style={{ margin: 'auto 0 0' }}>
          <Button
            type="button"
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() =>
              history.push(
                `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/custos/`
              )
            }
          />
          <Button type="submit" text="Avançar" disabled={!isValid} />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default OrdersPaymentPage;
