import React from 'react';
import { Background, Container, HiddenLogo, Wrapper } from './auth-styles';

import { Redirect } from 'react-router-dom';
import logo from '../../assets/logo-big.png';
import { useAuth } from '../../contexts/auth';
import { userURLByRole } from '../../helper/UserURLByRole';
import { UserRoles } from '../../types/users';

const AuthLayout: React.FC = ({ children }) => {
  const { user } = useAuth();

  if (user) {
    return (
      <Redirect
        to={userURLByRole(user.role as keyof typeof UserRoles).loggedIn}
      />
    );
  }

  return (
    <Background>
      <Container>
        <Wrapper>
          <HiddenLogo>
            <img src={logo} alt="ItiSun" />
          </HiddenLogo>
          {children}
        </Wrapper>
        <small>Copyright © 2023. Todos os direitos reservados</small>
      </Container>
    </Background>
  );
};

export default AuthLayout;
