import styled from 'styled-components';

type InputProps = {
  text?: string;
};

export const Content = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  border-radius: 16px;

  & > h1 {
    font-family: 'DunbarTallRegular';
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 100%;
    letter-spacing: 0.03375em;
    color: #27282b;
  }

  & > article {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;

    & > p {
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 1.125rem;

      letter-spacing: 0.0225em;

      color: #27282b;
    }
  }

  & > article.seller {
    min-height: 18rem;
  }
`;
