import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiEye } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import {
  ActionButton,
  Breadcrumb,
  Button,
  StepProgress,
} from '../../../../components';
import Count from '../../../../components/count';
import InputPrice from '../../../../components/input-price';
import Loading from '../../../../components/loading';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import SelectInput, { Option } from '../../../../components/select';
import { BudgetItem } from '../../../../contexts/budgets/types';
import { useOrders } from '../../../../contexts/orders';
import useMediaQuery from '../../../../hooks/mediaQuery';
import productsApi from '../../../../services/products';
import { normalize } from '../../../../utils/normalize';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { ButtonGroup, Container, PageHeader, Wrapper } from '../../../styles';
import {
  Actions,
  AdditionalsContainer,
  AdditionalsSummaryWrapper,
  Content,
  Kit,
  KitsMobile,
  SummaryContainer,
  SummaryContent,
  SummaryHeader,
  SummaryTotal,
  TableContainer,
  TableFooter,
  TableHeader,
  ValuesData,
} from './styles';
import { formatQuantity } from '../../../../utils/formatQuantity';

type SummaryForm = {
  bonusInsurance: string;
  discountOrAddition: Option;
  discountOrAdditionValue: string;
  price: string;
  quantityPerYear: number;
  installationService: string;
};

type Summary = {
  items: BudgetItem[];
  income: string;
  price: string;
  addition: string;
  discount: string;
  total: string;
};

type Modals = {
  description: string | null;
};

const steps = [
  { key: 1, title: 'Selecionar kit', active: false, complete: true },
  { key: 2, title: 'Resumo', active: true, complete: false },
  { key: 3, title: 'Pagamento', active: false, complete: false },
  { key: 4, title: 'Documentos', active: false, complete: false },
  { key: 5, title: 'Termo de aceite', active: false, complete: false },
];

const schema = yup.object().shape(
  {
    discountOrAddition: yup
      .object()
      .when('discountOrAdditionValue', (discountOrAdditionValue: string) =>
        discountOrAdditionValue
          ? yup
              .object()
              .required('Selecione uma opção')
              .typeError('Selecione uma opção')
          : yup.object().nullable()
      ),
    discountOrAdditionValue: yup
      .string()
      .when('discountOrAddition', (discountOrAddition: string) =>
        discountOrAddition
          ? yup.string().required('Informe o valor')
          : yup.string().optional()
      ),
    price: yup
      .string()
      .when('quantityPerYear', (quantityPerYear: string) =>
        quantityPerYear
          ? yup.string().required('Informe o valor da manutenção')
          : yup.string().optional()
      ),
    quantityPerYear: yup.string().optional(),
  },
  [
    ['discountOrAddition', 'discountOrAdditionValue'],
    ['price', 'quantityPerYear'],
  ]
);

const discountOrAdditionOptions = [
  { label: 'Desconto', value: 'discount' },
  { label: 'Acréscimo', value: 'addition' },
];

const OrdersSummaryPage: React.FC = () => {
  const { order, handleOrder, getOrder } = useOrders();

  const orderItems = order?.items ?? [];

  const isBudgetCustom = orderItems.some((item) => item.type === 'custom');

  const [modals, setModals] = useState({
    description: null,
  } as Modals);

  const { isMobile, isTablet } = {
    isTablet: useMediaQuery('(max-width: 992px)'),
    isMobile: useMediaQuery('(max-width: 520px)'),
  };

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const { id: orderId } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Orçamentos e pedidos',
      link: '/orcamentos/orcamentos-e-pedidos',
      active: false,
    },
    {
      id: 3,
      title: 'Gerar pedido',
      link: '/orcamentos/orcamentos-e-pedidos/',
      active: false,
    },
    {
      id: 4,
      title: 'Selecionar kit',
      link: '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione',
      active: false,
    },
    {
      id: 5,
      title: 'Resumo',
      link: `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/resumo`,
      active: true,
    },
  ];

  const kitsValue = String(
    orderItems.reduce((acc, item) => acc + item.price, 0)
  );

  const summary = {
    price: kitsValue,
    total: order?.finalValue,
    income: order?.grossRevenue,
    addition: order?.addition,
    discount: order?.discount,
  };

  const hasDiscount = !!Number(summary.discount);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<SummaryForm>({
    defaultValues: {
      bonusInsurance: 'false',
      discountOrAdditionValue: '',
    },
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  const updateOrderSummary = async ({
    discountOrAddition,
    discountOrAdditionValue,
  }: SummaryForm) => {
    const normalizedData = String(discountOrAddition?.value).includes(
      'addition'
    )
      ? {
          addition: normalize(discountOrAdditionValue),
        }
      : {
          discount: normalize(discountOrAdditionValue),
        };

    try {
      const { data } = await productsApi.post<Summary>(
        `/orders/${orderId}/resume`,
        normalizedData
      );

      const summary = {
        addition: data.addition,
        discount: data.discount,
        grossRevenue: data.income,
        items: data.items,
        finalValue: data.total,
      };

      if (order) {
        handleOrder({ ...order, ...summary });
      }
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      switch (errorMessage) {
        case 'Maintenance per year should be greater than zero':
          setError('quantityPerYear', {
            type: 'field-empty',
            message: 'Insira um valor',
          });

          break;

        case 'order not found':
          toast.error('Pedido não encontrado');
          break;

        case 'Discount value is too high':
          toast.error('Valor do desconto é mais alto que o permitido');
          break;

        default:
          toast.error(errorMessage, { toastId: '1' });
          break;
      }
    }
  };

  const debouncedFunc = debounce(handleSubmit(updateOrderSummary), 500);

  const handleDescriptionModal = (description: string | null) => {
    setModals((state) => {
      return {
        ...state,
        description,
      };
    });
  };

  const fetchData = async () => {
    if (!order) {
      await getOrder(orderId);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>{'Resumo'}</span>
          <div>
            <StepProgress steps={steps} />
          </div>
        </PageHeader>

        <Content>
          {!isMobile && isBudgetCustom && (
            <TableContainer>
              <TableHeader>
                <h1>Kits Personalizados</h1>
              </TableHeader>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Composição</th>
                      <th>Tipo</th>
                      <th>Marca</th>
                      <th>Geração</th>
                      <th>Kits</th>
                      <th>Valores</th>
                      <th>Quantidade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems.map((item, i) => {
                      const { customItem } = item;

                      const quantity =
                        customItem?.kits.reduce(
                          (acc, kit) => acc + kit.quantity,
                          0
                        ) ?? 0;

                      const kitsDescription =
                        customItem?.kits
                          .map((kit) => kit.product.description)
                          .join(', ') ?? '';

                      return (
                        <tr key={item.id}>
                          <td>
                            <strong>{i + 1}</strong>
                          </td>

                          <td>
                            <strong>{customItem?.type}</strong>
                          </td>
                          <td>
                            <strong>{customItem?.supplier}</strong>
                          </td>
                          <td>
                            <strong>{item.totalGeneration + 'KWh'}</strong>
                          </td>
                          <td>
                            <strong>
                              {formatQuantity(quantity, 'Kit', 'Kits')}
                            </strong>
                          </td>
                          <td>
                            <ValuesData>
                              <span>Kit: {item.formatted.price}</span>
                              <span>Instalação: {item.formatted.income}</span>
                              <span className="hr" />
                              <strong>Total: {item.formatted.total}</strong>
                            </ValuesData>
                          </td>
                          <td>
                            <Count
                              name={item.id}
                              value={item.quantity}
                              onDecrement={() => null}
                              onIncrement={() => null}
                              disabled
                            />
                          </td>
                          <td>
                            <Actions>
                              <ActionButton
                                tooltip="Visualizar"
                                onClick={() => {
                                  handleDescriptionModal(kitsDescription);
                                }}
                              >
                                <FiEye />
                              </ActionButton>
                            </Actions>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <TableFooter />
            </TableContainer>
          )}

          {!isMobile && !isBudgetCustom && (
            <TableContainer>
              <TableHeader>
                <h1>Kits</h1>
              </TableHeader>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Geração</th>
                      <th>Marca</th>
                      <th>Categoria</th>
                      <th>Kits</th>
                      <th>Valores</th>
                      <th>Quantidade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderItems.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <strong>{item.totalGeneration + 'KWh'}</strong>
                          </td>
                          <td>
                            <strong>{item.product?.supplier}</strong>
                          </td>
                          <td>
                            <strong>{item.product?.category + 'W'}</strong>
                          </td>
                          <td>
                            <strong>
                              {item.product?.power} KWp |{' '}
                              {item.product?.voltage}V
                              <span>
                                {item.product?.solarPlatesNumber} módulos |{' '}
                                {item.totalGeneration}KWh
                              </span>
                            </strong>
                          </td>
                          <td>
                            <ValuesData>
                              <span>Kit: {item.formatted.price}</span>
                              <span>Instalação: {item.formatted.income}</span>
                              <span className="hr" />
                              <strong>Total: {item.formatted.total}</strong>
                            </ValuesData>
                          </td>
                          <td>
                            <Count
                              name={item.id}
                              value={item.quantity}
                              onDecrement={() => null}
                              onIncrement={() => null}
                              disabled
                            />
                          </td>
                          <td>
                            <Actions>
                              <ActionButton
                                tooltip="Visualizar"
                                onClick={() => {
                                  handleDescriptionModal(
                                    item.product?.description ?? ''
                                  );
                                }}
                              >
                                <FiEye />
                              </ActionButton>
                            </Actions>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <TableFooter />
            </TableContainer>
          )}

          {isMobile && isBudgetCustom && (
            <KitsMobile>
              <h1>Kits Personalizados</h1>

              {orderItems.map((item, i) => {
                const { customItem } = item;

                const quantity =
                  customItem?.kits.reduce(
                    (acc, kit) => acc + kit.quantity,
                    0
                  ) ?? 0;

                const kitsDescription =
                  customItem?.kits
                    .map((kit) => kit.product.description)
                    .join(', ') ?? '';

                return (
                  <Kit key={item.id}>
                    <header>
                      <div>
                        <span>{i + 1}</span>
                      </div>
                      <div>
                        <span>{customItem?.type}</span>
                      </div>
                      <div>
                        <span>{customItem?.supplier}</span>
                      </div>
                      <div>
                        <span>{item.totalGeneration}KWh</span>
                      </div>
                    </header>
                    <main>
                      <div className="kit-details">
                        <strong>
                          {formatQuantity(quantity, 'Kit', 'Kits')}
                        </strong>
                      </div>
                      <div className="kit-wrapper">
                        <div className="kit-values">
                          <ValuesData>
                            <span>Kit: {item.formatted.price}</span>
                            <span>Instalação: {item.formatted.income}</span>
                            <span className="hr" />
                            <strong>Total: {item.formatted.total}</strong>
                          </ValuesData>
                        </div>
                        <div className="kit-actions">
                          <Count
                            name="default"
                            value={item.quantity}
                            disabled
                            onDecrement={() => null}
                            onIncrement={() => null}
                          />
                          <Actions>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() => {
                                handleDescriptionModal(kitsDescription);
                              }}
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </div>
                      </div>
                    </main>
                  </Kit>
                );
              })}
            </KitsMobile>
          )}

          {isMobile && !isBudgetCustom && (
            <KitsMobile>
              <h1>Kits</h1>

              {orderItems.map((item) => {
                return (
                  <Kit key={item.id}>
                    <header>
                      <div>
                        <span>{item.totalGeneration}KWh</span>
                      </div>
                      <div>
                        <span>{item.product?.supplier}</span>
                      </div>
                      <div>
                        <span>{item.product?.category + 'W'}</span>
                      </div>
                    </header>
                    <main>
                      <div className="kit-details">
                        <strong>
                          {item.product?.power} KWp | {item.product?.voltage}V
                          <span>
                            {item.product?.solarPlatesNumber} módulos |{' '}
                            {item.totalGeneration}KWh
                          </span>
                        </strong>
                      </div>
                      <div className="kit-wrapper">
                        <div className="kit-values">
                          <ValuesData>
                            <span>Kit: {item.formatted.price}</span>
                            <span>Instalação: {item.formatted.income}</span>
                            <span className="hr" />
                            <strong>Total: {item.formatted.total}</strong>
                          </ValuesData>
                        </div>
                        <div className="kit-actions">
                          <Count
                            name="default"
                            value={item.quantity}
                            disabled
                            onDecrement={() => null}
                            onIncrement={() => null}
                          />
                          <Actions>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() => {
                                handleDescriptionModal(
                                  item.product?.description ?? ''
                                );
                              }}
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </div>
                      </div>
                    </main>
                  </Kit>
                );
              })}
            </KitsMobile>
          )}

          <AdditionalsSummaryWrapper>
            <AdditionalsContainer>
              <Controller
                name="discountOrAddition"
                control={control}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      ref={ref}
                      isSearchable={false}
                      isClearable
                      label="Desconto ou acréscimo"
                      placeholder="Selecione uma opção"
                      options={discountOrAdditionOptions}
                      onChange={async (option) => {
                        onChange(option);

                        await debouncedFunc();
                      }}
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
              />

              {!isTablet && (
                <>
                  <InputPrice
                    label="Valor"
                    placeholder="R$ 0,00"
                    error={errors.discountOrAdditionValue?.message}
                    {...register('discountOrAdditionValue', {
                      onChange: async () => await debouncedFunc(),
                    })}
                  />
                </>
              )}

              {isTablet && (
                <div className="mobile-wrapper">
                  <InputPrice
                    label="Valor"
                    placeholder="R$ 0,00"
                    error={errors.discountOrAdditionValue?.message}
                    {...register('discountOrAdditionValue', {
                      onChange: async () => await debouncedFunc(),
                    })}
                  />
                </div>
              )}
            </AdditionalsContainer>

            <SummaryContainer>
              <SummaryHeader>Resumo</SummaryHeader>
              <SummaryContent>
                <span>
                  Kits:
                  <strong>{toMoneyFormat(Number(summary.price))}</strong>
                </span>

                {hasDiscount && (
                  <span>
                    Desconto integrador:
                    <strong>
                      {'-' + toMoneyFormat(Number(summary.discount))}
                    </strong>
                  </span>
                )}

                {!hasDiscount && (
                  <span>
                    Acréscimo integrador:
                    <strong>
                      {'+' + toMoneyFormat(Number(summary.addition))}
                    </strong>
                  </span>
                )}

                <span>
                  Receita bruta:
                  <strong>{toMoneyFormat(Number(summary.income))}</strong>
                </span>
              </SummaryContent>
              <SummaryTotal>
                <span className="total">
                  Total:
                  <sub>{toMoneyFormat(Number(summary.total))}</sub>
                </span>
              </SummaryTotal>
            </SummaryContainer>
          </AdditionalsSummaryWrapper>

          <ButtonGroup>
            <Button
              type="button"
              text="Voltar"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              onClick={() =>
                history.push(
                  '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione'
                )
              }
            />

            <Button
              type="button"
              text="Avançar"
              onClick={() => {
                history.push(
                  `/orcamentos/orcamentos-e-pedidos/gerar-pedido/${orderId}/acessorios`
                );
              }}
            />
          </ButtonGroup>
        </Content>
      </Wrapper>
      <KitDescriptionModal
        isOpen={!!modals.description}
        description={modals.description as string}
        onCancel={() => {
          handleDescriptionModal(null);
        }}
        onRequestClose={() => {
          handleDescriptionModal(null);
        }}
      />
    </Container>
  );
};

export default OrdersSummaryPage;
