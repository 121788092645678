import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from '../../../components';
import { Container, Wrapper, PageHeader } from './../styles';
import { useHistory, useParams } from 'react-router-dom';
import projectsApi from '../../../services/projects';
import { Divider, FormContainer, GroupHeader } from '../../styles';
import { InfoContainer, Group, Columns, ButtonContainer } from './style';
import { ProjectType } from '../../../types/project';
import { getProjectUrl } from '../../../helper/UserLinkRole';
import { useAuth } from '../../../contexts/auth';

type ProjectCallCenterPageProps = {
  id: string;
};

const ProjectCallCenterPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams<ProjectCallCenterPageProps>();
  const [project, setProject] = useState<ProjectType>();

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  useEffect(() => {
    projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
      setProject(res.data);
    });
  }, [id]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Dados do cliente</span>
        </PageHeader>
        <FormContainer>
          <Group>
            <GroupHeader>
              <h2>Dados do Solicitante</h2>
              <span>
                Informações de contato de quem será responsavel pela
                fiscalização deste projeto.
              </span>
            </GroupHeader>
          </Group>
          <Group size="medium">
            <InfoContainer>
              <span className="label">Concessionária:</span>
              <span className="info">{project?.powerCompany?.name}</span>
            </InfoContainer>
            <InfoContainer>
              <span className="label">Tipo de conta:</span>
              <span className="info">{project?.personType}</span>
            </InfoContainer>
            <InfoContainer>
              <span className="label">Solicitação de Acesso:</span>
              <span className="info">{project?.accessType}</span>
            </InfoContainer>
          </Group>
          <Divider />
          <Group>
            <GroupHeader>
              <h2>Dados do Solicitante</h2>
              <span>
                Informações de contato de quem será responsavel pela
                fiscalização deste projeto.
              </span>
            </GroupHeader>
          </Group>
          <Group size="medium">
            <InfoContainer>
              <span className="label">Procurador legal:</span>
              <span className="info">{project?.requesterName}</span>
            </InfoContainer>
            <InfoContainer>
              <span className="label">Telefone:</span>
              <span className="info">{project?.requesterPhone}</span>
            </InfoContainer>
            <InfoContainer>
              <span className="label">E-mail: </span>
              <span className="info">{project?.requesterEmail}</span>
            </InfoContainer>
          </Group>
          <Divider />
          <Group>
            <GroupHeader>
              <h2>Indentificação da unidade consumidora (UC)</h2>
              <span>
                Informações de contato de quem será responsavel pela unidade
                consumidora
              </span>
            </GroupHeader>
          </Group>
          <Group>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Código da UC:</span>
                <span className="info">{project?.ucCode}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">Classe:</span>
                <span className="info">{project?.ucClass}</span>
              </InfoContainer>
            </Columns>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Títular da UC:</span>
                <span className="info">{project?.ucOwner}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">CPF / CNPJ:</span>
                <span className="info">{project?.ucDocument}</span>
              </InfoContainer>
            </Columns>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Estado:</span>
                <span className="info">{project?.ucState}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">Cidade:</span>
                <span className="info">{project?.ucCity}</span>
              </InfoContainer>
            </Columns>
            <InfoContainer>
              <span className="label">Logradouro:</span>
              <span className="info">{project?.ucAddress}</span>
            </InfoContainer>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Número:</span>
                <span className="info">{project?.ucNumber}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">CEP: </span>
                <span className="info">{project?.ucZipcode}</span>
              </InfoContainer>
            </Columns>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">E-mail:</span>
                <span className="info">{project?.ucEmail}</span>
              </InfoContainer>
              <InfoContainer rightSpace={true}>
                <span className="label">Telefone: </span>
                <span className="info">{project?.ucPhone}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">Celular: </span>
                <span className="info">{project?.ucPhone}</span>
              </InfoContainer>
            </Columns>
          </Group>
          <Divider />
          <Group>
            <GroupHeader>
              <h2>Dados da unidade consumidora</h2>
              <span>Informações tecnicas de onde será feito a instalação</span>
            </GroupHeader>
          </Group>
          <Group>
            <Columns>
              <InfoContainer>
                <span className="label">Tensão de atendimento:</span>
                <span className="info">{project?.ucNeutralPhaseVoltage}</span>
              </InfoContainer>
            </Columns>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Tipo de conexão:</span>
                <span className="info">{project?.ucConnectionType}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">Tipo de ramal:</span>
                <span className="info">{project?.ucBranch}</span>
              </InfoContainer>
            </Columns>
          </Group>
          <Divider />
          <Group>
            <GroupHeader>
              <h2>Dados da Geração</h2>
              <span>Informações tecnicas de onde será feito a instalação</span>
            </GroupHeader>
          </Group>
          <Group>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Tipo da fonte de geração:</span>
                <span className="info">{project?.ucGenerationSource}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">
                  Potência instalada de geração (em KW):
                </span>
                <span className="info">
                  {project?.ucInstalledGenerationPower}
                </span>
              </InfoContainer>
            </Columns>
          </Group>
          <Divider />
          <Group>
            <GroupHeader>
              <h2>Latitude e longitude do local</h2>
            </GroupHeader>
          </Group>
          <Group>
            <Columns>
              <InfoContainer rightSpace={true}>
                <span className="label">Latitude:</span>
                <span className="info">{project?.ucLatitude}</span>
              </InfoContainer>
              <InfoContainer>
                <span className="label">Longitude:</span>
                <span className="info">{project?.ucLongitude}</span>
              </InfoContainer>
            </Columns>
          </Group>
          <Divider />
          <ButtonContainer>
            <div>
              <Button
                text="Voltar"
                backgroundColor="#DFE0E2"
                onClick={() => history.push(getProjectUrl(user.role))}
              />
            </div>
            <div>
              <Button
                text="Próximo"
                backgroundColor="#001FFF"
                backgroundHoverColor="#2772f8"
                onClick={() => history.push(`/projetos/${id}/arquivos`)}
              />
            </div>
          </ButtonContainer>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default ProjectCallCenterPage;
