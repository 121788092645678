import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Breadcrumb, Button, SearchInput } from '../../../components';
import DynamicFilter from '../../../components/dynamic-filter';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import { Order } from '../../../contexts/orders/types';
import productsApi from '../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../types/pagination';
import { Container, PageHeader } from '../../styles';
import { Content, Profit, TableHeader } from './styles';

type ProfitabilityTableItems = {
  customer: string;
  seller: string;
  createdAt: string;
  profitPercentage: JSX.Element;
  actions: JSX.Element;
};

type Profitability = Order;

const links = [
  {
    id: 1,
    title: 'Orçamentos',
    link: '/orcamentos',
    active: false,
  },
  {
    id: 2,
    title: 'Lucratividade nas vendas',
    link: '/orcamentos/lucratividade',
    active: true,
  },
];

const BudgetsProfitabilityPage: React.FC = () => {
  const history = useHistory();

  const { selectedFranchise: franchiseId } = useAuth();

  const [profitabilities, setProfitabilities] =
    useState<PaginatedResponse<Profitability>>(EMPTY_PAGE);

  const [search, setSearch] = useState('');

  const debounceSearch = debounce(setSearch, 300);

  const tableColumns: ColumnStructure[] = useMemo(() => {
    return [
      {
        id: 'customer',
        label: 'Cliente',
      },
      {
        id: 'seller',
        label: 'Vendedor',
        isCentered: true,
      },
      {
        id: 'createdAt',
        label: 'Data',
      },
      {
        id: 'profitPercentage',
        label: 'Lucro Líquido',
        isCentered: true,
      },
      {
        id: 'actions',
        label: 'Ações',
        type: 'actionCell',
        isCentered: true,
      },
    ];
  }, []);

  const tableData: TableData<ProfitabilityTableItems>[] = useMemo(() => {
    return profitabilities.content.map((item) => {
      const profitPercentage =
        '+' +
        new Intl.NumberFormat('pt-BR').format(Number(item.profitPercentage)) +
        '%';

      return {
        id: item.id,
        customer: item.budget.customer.name,
        seller: '-',
        createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy', {
          locale: ptBR,
        }),
        profitPercentage: <Profit>{profitPercentage}</Profit>,
        actions: (
          <InnerActions>
            <Button
              type="button"
              text="Ver detalhes"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              style={{ height: '2rem', width: '8.5rem' }}
              onClick={() =>
                history.push(`/orcamentos/lucratividade/${item.id}/detalhes/`)
              }
            />
          </InnerActions>
        ),
      };
    });
  }, [franchiseId, search, profitabilities]);

  const getProfitabilities = useCallback(
    async (page: number, limit: number) => {
      if (!franchiseId) {
        return toast.info('Selecione um integrador');
      }

      setProfitabilities((state) => ({
        ...state,
        loading: true,
      }));

      try {
        const { data } = await productsApi.get<
          PaginatedResponse<Profitability>
        >(`/orders`, {
          params: {
            page,
            limit,
            'filters[franchiseId]': franchiseId,
            search: search || undefined,
          },
        });

        setProfitabilities(data);
      } catch (error) {
        toast.error('Desculpe, não foi possível buscar as lucratividades');

        throw error;
      } finally {
        setProfitabilities((state) => ({
          ...state,
          loading: false,
        }));
      }
    },
    [franchiseId, search, profitabilities]
  );

  useEffect(() => {
    getProfitabilities(1, 10);
  }, [franchiseId, search]);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>Lucratividade Nas Vendas</span>
      </PageHeader>

      <Content>
        <Table
          items={tableData}
          columns={tableColumns}
          isLoading={false}
          header={
            <TableHeader>
              <SearchInput
                placeholder="Digite aqui para pesquisar"
                onTextChange={(value) => debounceSearch(value)}
                name="search"
              />
              <DynamicFilter filters={[]} onChange={() => null} />
            </TableHeader>
          }
          pagination={profitabilities.pagination}
          onPageChange={(page) =>
            getProfitabilities(page, profitabilities.pagination.limit)
          }
          onLimitChange={(limit) => getProfitabilities(1, limit)}
        />
      </Content>
    </Container>
  );
};

export default BudgetsProfitabilityPage;
