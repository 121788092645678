import React, { useState } from 'react';
import Modal from 'react-modal';
import { useAuth } from '../../../../../contexts/auth';
import projectsApi from '../../../../../services/projects';
import { ProjectStatus } from '../../../types';
import { toast } from 'react-toastify';

import { Buttons, Container, ModalContainer } from './styles';

import { ActionButton, Button, ChecklistIcon } from '../../../../../components';

type InputProps = {
  id?: string;
  handleOnClick: () => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const SendToPowerComapanyModal: React.FC<InputProps> = ({
  id,
  handleOnClick,
}) => {
  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleConcludeProject = async (id?: string) => {
    await projectsApi.post(`/api/projects/${id}/status-log`, {
      userId: user.id,
      observation: 'Projeto enviado para a validação da concessionária',
      status: ProjectStatus.PROJETO_AGUARDANDO_CONCESSIONARIO,
    });

    await projectsApi.patch(`/api/projects/${id}/status`, {
      status: ProjectStatus.PROJETO_AGUARDANDO_CONCESSIONARIO,
    });

    toast.success('Projeto enviado com sucesso');
    setIsOpen(false);
    handleOnClick();
  };

  return (
    <Container>
      <ActionButton
        tooltip="Enviar para análise da concessionária"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <ChecklistIcon />
      </ActionButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <p>
            Você tem certeza que deseja enviar o projeto para a validação da
            concessionária?
          </p>
          <Buttons>
            <Button
              type="submit"
              text="Cancelar"
              backgroundColor="#DFE0E2"
              backgroundHoverColor="#DFE0E2"
              style={{ marginRight: 30 }}
              onClick={() => setIsOpen(false)}
            />
            <Button
              type="submit"
              text="Enviar"
              backgroundColor="#001FFF"
              backgroundHoverColor="#2772f8"
              onClick={() => {
                handleConcludeProject(id);
              }}
            />
          </Buttons>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default SendToPowerComapanyModal;
