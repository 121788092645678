import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Button = styled.button.attrs((props) => ({
  ...props,
  type: 'button',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  background-color: #00299b;
  color: #ffffff;

  font-weight: 500;
  font-size: 1rem;

  border: 0;
  border-radius: 8px;

  width: 9.5rem;
  height: 3rem;

  &:hover {
    background-color: #005af9;
  }
`;

type DropdownContainerProps = {
  isOpen: boolean;
};

export const DropdownContainer = styled.div<DropdownContainerProps>`
  background: #ffffff;

  border: 1px solid #c9cbcf;
  box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.18);
  border-radius: 8px;

  width: 21.875rem;
  max-height: 29.25rem;

  overflow: auto;

  position: absolute;
  top: 3.5rem;
  right: 0;

  z-index: 99;

  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};

  &::-webkit-scrollbar {
    width: 4px;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5f6;

    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8b0b5;

    border-radius: 8px;
  }
`;

export const DropdownHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem;

  border-bottom: 1px solid #c9cbcf;

  & > span {
    font-weight: 700;
    font-size: 1rem;

    color: #61646b;
  }
`;

export const DropdownContent = styled.div`
  padding: 1rem;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > span {
      font-weight: 500;
      font-size: 1.125rem;

      color: #61646b;

      margin-bottom: 0.5rem;
    }
  }

  & > div + div {
    &.show-more {
      font-weight: 500;
      font-size: 0.875rem;

      color: #005af9;
    }
    & > span {
      margin-top: 0.5rem;
    }
  }
`;

export const CloseButton = styled.button`
  border: 0;

  font-size: 0;

  background-color: transparent;
`;

type CheckboxProps = {
  isChecked: boolean;
};

export const Checkbox = styled.label<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  font-size: 1rem;

  color: #61646b;

  cursor: pointer;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 1px solid ${({ isChecked }) => (isChecked ? '#001FFF' : '#9fa1a8')};

    background-color: ${({ isChecked }) => isChecked && '#005af9'};

    border-radius: 4px;

    width: 1rem;
    height: 1rem;
  }

  & > input[type='checkbox'] {
    height: 1px;
    position: absolute;
    width: 1px;
  }
`;
