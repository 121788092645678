import React, { createContext, useCallback, useContext, useState } from 'react';
import franchisesApi from '../../services/franchises';

import usersMicroservice from '../../services/users';
import { UserRoles } from '../../types/users';

interface User {
  cpf: string | null;
  createdAt: string;
  email: string;
  enabled: boolean;
  id: string;
  name: string;
  role: string;
  updatedAt: string;
  acceptedTerms: string | null;
}

export interface Franchise {
  franchise: {
    id: string;
    name: string;
    enabled: boolean;
    email: string;
  };
  modules: string[];
  user: User;
}

interface SignInCredencials {
  email: string;
  password: string;
}

interface AuthState {
  user: User;
  token: string;
  franchises: Franchise[];
  selectedFranchise: string | null;
}

interface AuthContextData {
  user: User;
  franchises: Franchise[];
  selectedFranchise: string | null;
  signIn(credencials: SignInCredencials): Promise<AuthState>;
  signOut(): void;
  updateUser(user: User): void;
  selectFranchise(franchise: string): void;
  updateUserFranchises: (userId: string, franchiseId?: string) => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(
      `@energy:${process.env.REACT_APP_SITE_VERSION}-token`
    );

    const user = localStorage.getItem('@energy:user');

    const franchises = localStorage.getItem('@energy:franchises');

    const selectedFranchise = localStorage.getItem('@energy:selectedFranchise');

    if (token && user && franchises) {
      return {
        token,
        user: JSON.parse(user),
        franchises: JSON.parse(franchises),
        selectedFranchise,
      };
    }

    return {} as AuthState;
  });

  const getFranchiseByUserId = async (userId: string) => {
    try {
      const { data } = await franchisesApi.get<Franchise[] | Franchise>(
        `/api/franchises/by-user-id/${userId}`
      );

      const franchises = Array.isArray(data) ? data : [data];

      return franchises;
    } catch (error) {
      return [];
    }
  };

  const signIn = useCallback(async ({ email, password }) => {
    const { data } = await usersMicroservice.post<{
      user: User;
      token: string;
    }>('/api/users/login', {
      email,
      password,
    });

    const { user, token } = data;

    const { isIntegrator, isProvider } = {
      isIntegrator:
        user.role === UserRoles.INTEGRADOR_ADMIN ||
        user.role === UserRoles.INTEGRADOR,
      isProvider: user.role === UserRoles.FORNECEDOR,
    };

    const franchises = isProvider ? [] : await getFranchiseByUserId(user.id);

    if (isIntegrator) {
      const franchiseId = franchises[0].franchise.id;

      localStorage.setItem(
        `@energy:${process.env.REACT_APP_SITE_VERSION}-token`,
        token
      );
      localStorage.setItem('@energy:user', JSON.stringify(user));
      localStorage.setItem('@energy:franchises', JSON.stringify(franchises));
      localStorage.setItem('@energy:selectedFranchise', franchiseId);

      setData({
        user,
        token,
        franchises: franchises,
        selectedFranchise: franchiseId,
      });

      return {
        user,
        token,
        franchises: franchises,
        selectedFranchise: franchiseId,
      };
    }

    localStorage.setItem(
      `@energy:${process.env.REACT_APP_SITE_VERSION}-token`,
      token
    );
    localStorage.setItem('@energy:user', JSON.stringify(user));
    localStorage.setItem('@energy:franchises', JSON.stringify(franchises));

    setData({
      user,
      token,
      franchises: franchises,
      selectedFranchise: null,
    });

    return {
      user,
      token,
      franchises: franchises,
      selectedFranchise: null,
    };
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback((user: User) => {
    localStorage.setItem('@energy:user', JSON.stringify(user));

    setData((state) => ({ ...state, user }));
  }, []);

  const selectFranchise = useCallback((franchiseId: string) => {
    localStorage.setItem('@energy:selectedFranchise', franchiseId);

    setData((state) => ({
      ...state,
      selectedFranchise: franchiseId,
    }));
  }, []);

  const updateUserFranchises = async (userId: string, franchiseId?: string) => {
    const isLoggedIn = data.user.id === userId;

    if (isLoggedIn) {
      const franchises = await getFranchiseByUserId(userId);

      localStorage.setItem('@energy:franchises', JSON.stringify(franchises));

      const isSelectedFranchise =
        localStorage.getItem('@energy:selectedFranchise') === franchiseId;

      setData((state) => {
        return {
          ...state,
          franchises: franchises,
          selectedFranchise: isSelectedFranchise
            ? null
            : state.selectedFranchise,
        };
      });

      if (isSelectedFranchise) {
        localStorage.removeItem('@energy:selectedFranchise');
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        franchises: data.franchises,
        selectedFranchise: data.selectedFranchise,
        signIn,
        signOut,
        updateUser,
        selectFranchise,
        updateUserFranchises,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}
