import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { debounce } from 'lodash';
import {
  Breadcrumb,
  Button,
  SearchInput,
  StepProgress,
} from '../../../../components';
import DynamicFilter, {
  FilterOption,
  Option,
} from '../../../../components/dynamic-filter';
import Loading from '../../../../components/loading';
import productsApi from '../../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../../types/pagination';
import { DraftProduct } from '../../../../types/product';
import { booleanToYesNo } from '../../../../utils/booleanToYesNo';

import { ButtonGroup, Container, PageHeader } from '../../../styles';

import Table, {
  ColumnStructure,
  TableData,
} from '../../../../components/table';
import { useAuth } from '../../../../contexts/auth';
import { toMoneyFormat } from '../../../../utils/toMoneyFormat';
import { TableHeader } from './styles';

type Filters = {
  category: string[];
  fabricMaterial: string[];
  phase: string[];
  supplier: string[];
  type: string[];
  voltage: string[];
};

enum FiltersLabels {
  category = 'Categoria',
  fabricMaterial = 'Estrutura',
  phase = 'Fase',
  supplier = 'Fornecedores',
  type = 'Tipo',
  voltage = 'Tensão',
}

enum RestoredFiltersLabels {
  Categoria = 'category',
  Estrutura = 'fabricMaterial',
  Fornecedores = 'supplier',
  Tipo = 'type',
  Fase = 'phase',
  Tensão = 'voltage',
}

type TableItems = {
  id: string;
  supplier: string;
  promotional: string;
  power: string;
  category: string;
  fabricMaterial: string;
  price: string;
  description: string;
};

const ProductsImportSettedKitsAnalyzePage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const links = [
    {
      id: 1,
      title: 'Produtos',
      link: '/produtos',
      active: false,
    },
    {
      id: 2,
      title: 'Importação de Produtos',
      link: '/produtos/importacao',
      active: false,
    },
    {
      id: 3,
      title: 'Envio de dados',
      link: '/produtos/importacao/kit-pronto',
      active: false,
    },
    {
      id: 4,
      title: 'Processamento',
      link: `/produtos/importacao/kit-pronto/${id}/processamento`,
      active: false,
    },
    {
      id: 5,
      title: 'Conferência de dados',
      link: `/produtos/importacao/kit-pronto/${id}/conferencia`,
      active: true,
    },
  ];

  const [loading, setLoading] = useState(false);

  const steps = [
    { key: 1, title: 'Enviar dados', active: false, complete: true },
    { key: 2, title: 'Processamento', active: false, complete: true },
    { key: 3, title: 'Conferência de dados', active: true, complete: false },
  ];

  const [products, setProducts] =
    useState<PaginatedResponse<DraftProduct>>(EMPTY_PAGE);

  const [filterOptions, setFilterOptions] = useState([] as FilterOption[]);

  const [filters, setFilters] = useState({} as Filters);

  const [search, setSearch] = useState('');

  const handleFilters = (options: Option[]) => {
    const normalizeSelectedFilters = options.reduce(
      (acc, option) => {
        return {
          ...acc,
          [RestoredFiltersLabels[
            option.label as keyof typeof RestoredFiltersLabels
          ]]: [
            ...acc[
              RestoredFiltersLabels[
                option.label as keyof typeof RestoredFiltersLabels
              ] as keyof typeof filters
            ],
            option.value,
          ],
        };
      },
      {
        category: [],
        fabricMaterial: [],
        phase: [],
        supplier: [],
        type: [],
        voltage: [],
      } as Filters
    );

    setFilters(normalizeSelectedFilters);
  };

  const getFilters = useCallback(async () => {
    try {
      const { data } = await productsApi.get('/products/filters/', {
        params: {
          fields: 'category,supplier,fabricMaterial,phase,voltage',
          franchiseId: franchiseId || undefined,
        },
      });

      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os filtros.');
    }
  }, []);

  const getProducts = async (
    page: number,
    search: string,
    limit?: number
  ): Promise<PaginatedResponse<DraftProduct>> => {
    setProducts((state) => {
      return {
        ...state,
        loading: true,
      };
    });

    try {
      const { data } = await productsApi.get<PaginatedResponse<DraftProduct>>(
        `/products/kit-uploads/${id}/draft-products`,
        {
          params: {
            search,
            page,
            limit: limit ? limit : products.pagination.limit,
            category: filters.category,
            fabricMaterial: filters.fabricMaterial,
            phase: filters.phase,
            supplier: filters.supplier,
            voltage: filters.voltage,
          },
        }
      );
      return data;
    } catch (error) {
      toast.error('Não foi possível buscar os produtos.');
      throw error;
    } finally {
      setProducts((state) => {
        return {
          ...state,
          loading: false,
        };
      });
    }
  };

  const finishKitUpload = async (): Promise<void> => {
    setLoading(true);
    try {
      await productsApi.put<PaginatedResponse<DraftProduct>>(
        `/products/kit-uploads/${id}/finish`
      );
      toast.success('Importação finalizada com sucesso.');
    } catch (error) {
      toast.error('Não foi possivel finalizar a importação.');

      setLoading(false);

      throw error;
    }
  };

  const normalizeFilters = (options: Filters) => {
    const formatLabel = {
      category(value: string | number) {
        return `${value}W`;
      },
      type(value: string) {
        const translation: { [x: string]: string } = {
          default: 'Padrão',
        };

        return translation[value] || value;
      },
      voltage(value: string | number) {
        return `${value}V`;
      },
    };

    return Object.entries(options)
      .map(([field, value]) => {
        const label = FiltersLabels[field as keyof typeof FiltersLabels];

        const options = value
          .map((item) => ({
            label: formatLabel[field as keyof typeof formatLabel]
              ? formatLabel[field as keyof typeof formatLabel](item)
              : item,
            value: item,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));

        return {
          label,
          options,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const handlePageChange = useCallback(
    (page: number) => {
      getProducts(page, search, products.pagination.limit).then((products) => {
        setProducts(products);
      });
    },
    [products, search]
  );

  const handleLimitChange = useCallback(
    (limit: number) => {
      getProducts(products.pagination.currentPage, search, limit).then(
        (products) => {
          setProducts(products);
        }
      );
    },
    [products, search]
  );

  const handleSearchChange = debounce((textValue) => setSearch(textValue), 500);

  const handleSubmit = useCallback(() => {
    finishKitUpload().then(() => history.replace('/produtos/lista/kit-pronto'));
  }, []);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'supplier',
      label: 'Fornecedor',
    },
    {
      id: 'promotional',
      label: 'Promocional',
    },
    {
      id: 'power',
      label: 'Potência',
    },
    {
      id: 'category',
      label: 'Categoria',
    },
    {
      id: 'fabricMaterial',
      label: 'Estrutura',
    },
    {
      id: 'price',
      label: 'Valor (R$)',
    },
    {
      id: 'description',
      label: 'Descrição',
      onClick: () => {},
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return products.content.map((product) => {
      return {
        id: product.id,
        supplier: product.supplier,
        promotional: booleanToYesNo(product.promotional),
        power: product.power + 'KWp',
        category: product.category,
        fabricMaterial: product.fabricMaterial,
        price: toMoneyFormat(product.price),
        description: product.description,
      };
    });
  }, [products]);

  useEffect(() => {
    getFilters().then((filters) => setFilterOptions(normalizeFilters(filters)));
  }, []);

  useEffect(() => {
    getProducts(1, search).then((products) => {
      setProducts(products);
    });
  }, [filters, search]);

  if (loading)
    return (
      <Loading
        label="Finalizando importação"
        style={{
          height: '100%',
          alignItems: 'center',
        }}
      />
    );

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Conferência de Dados'}</span>
      </PageHeader>

      <StepProgress steps={steps} />

      <Table
        styles={{ height: '100%', margin: '3rem 0 0' }}
        isLoading={products.loading}
        items={tableItems}
        columns={tableColumns}
        header={
          <TableHeader>
            <SearchInput
              placeholder="Digite aqui para pesquisar"
              onTextChange={handleSearchChange}
              name="search"
            />
            <DynamicFilter filters={filterOptions} onChange={handleFilters} />
          </TableHeader>
        }
        placeholder="Nenhum kit encontrado"
        pagination={products.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />

      <ButtonGroup>
        <Button
          type="submit"
          text="Finalizar"
          onClick={handleSubmit}
          typeStyle="confirm"
        />
      </ButtonGroup>
    </Container>
  );
};

export default ProductsImportSettedKitsAnalyzePage;
