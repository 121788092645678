import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button } from '../../../../../components';
import { useAuth } from '../../../../../contexts/auth';
import projectsApi from '../../../../../services/projects';
import { ProjectPaymentType, ProjectStatus } from '../../../types';
import { toast } from 'react-toastify';
import { Buttons, Container, ModalContainer } from './styles';

type InputProps = {
  id?: string;
  handleOnClick: () => void;
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const PaymentModal: React.FC<InputProps> = ({ id, handleOnClick }) => {
  const { user } = useAuth();
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleConcludeProject = async (id?: string) => {
    await projectsApi.post(`/api/projects/${id}/status-log`, {
      userId: user.id,
      observation: ProjectStatus.PROJETO_EM_ELABORACAO,
      status: ProjectStatus.PROJETO_EM_ELABORACAO,
    });

    await projectsApi.patch(`/api/projects/${id}/status`, {
      status: ProjectStatus.PROJETO_EM_ELABORACAO,
    });

    await projectsApi.patch(`/api/projects/${id}/update-payment-type-date`, {
      paymentType: ProjectPaymentType.FREE_OF_CHARGE,
    });

    toast.success('Liberado o pagamento do projeto');
    setIsOpen(false);
    handleOnClick();
  };

  return (
    <Container>
      <Button
        type="button"
        text="Liberar pagamento"
        backgroundColor="#FF5E13"
        backgroundHoverColor="#A33C0C"
        onClick={() => setIsOpen(true)}
        style={{ marginRight: 16 }}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <ModalContainer>
          <p>Você tem certeza que deseja liberar o pagamento do projeto?</p>
          <Buttons>
            <Button
              type="button"
              text="Cancelar"
              backgroundColor="#DFE0E2"
              backgroundHoverColor="#DFE0E2"
              style={{ marginRight: 30 }}
              onClick={() => setIsOpen(false)}
            />
            <Button
              type="button"
              text="Concluir"
              backgroundColor="#001FFF"
              backgroundHoverColor="#2772f8"
              onClick={() => {
                handleConcludeProject(id);
              }}
            />
          </Buttons>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default PaymentModal;
