import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Breadcrumb,
  Button,
  CloudIcon,
  EmailIcon,
  FileIcon,
  StepProgress,
  WhatsappIcon,
} from '../../../../components';
import Loading from '../../../../components/loading';
import { useAuth } from '../../../../contexts/auth';
import { useBudgets } from '../../../../contexts/budgets';
import { Budget } from '../../../../contexts/budgets/types';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import crmApi from '../../../../services/crm';
import productsApi from '../../../../services/products';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import { Content, ContentHeader, EmbedContainer } from './styles';
import { UserRoles } from '../../../../types/users';

type Proposal = {
  phone: string;
  email: string;
  pdfURL: string;
};

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Serviços', active: false, complete: true },
  { key: 5, title: 'Proposta', active: true, complete: false },
];

const BudgetsProposalPage: React.FC = () => {
  const history = useHistory();

  const { selectedFranchise: franchiseId, user } = useAuth();

  const { id } = useParams<{ id: string }>();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const hasCustomKitPath = pathname.includes('personalizar-kit');

  const links = !hasCustomKitPath
    ? [
        {
          id: 1,
          title: 'Orçamentos',
          link: '/orcamentos',
          active: false,
        },
        {
          id: 2,
          title: 'Selecionar produto',
          link: '/orcamentos/selecionar-produto',
          active: false,
        },
        {
          id: 3,
          title: 'Cadastro do cliente',
          link: hasEditPath
            ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
            : `/orcamentos/selecionar-produto/cadastro-cliente`,
          active: false,
        },
        {
          id: 4,
          title: 'Seleção de kits',
          link: hasEditPath
            ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/editar`
            : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`,
          active: false,
        },
        {
          id: 5,
          title: 'Serviços',
          link: hasEditPath
            ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/editar`
            : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos`,
          active: false,
        },
        {
          id: 6,
          title: 'Proposta',
          link: hasEditPath
            ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/proposta/editar`
            : `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/servicos/proposta`,
          active: true,
        },
      ]
    : [
        {
          id: 1,
          title: 'Orçamentos',
          link: '/orcamentos',
          active: false,
        },
        {
          id: 2,
          title: 'Selecionar produto',
          link: '/orcamentos/selecionar-produto',
          active: false,
        },
        {
          id: 3,
          title: 'Cadastro do cliente',
          link: hasEditPath
            ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
            : `/orcamentos/selecionar-produto/cadastro-cliente`,
          active: false,
        },
        {
          id: 4,
          title: 'Personalizar kit',
          link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`,
          active: false,
        },
        {
          id: 5,
          title: 'Serviços',
          link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/servicos/`,
          active: false,
        },
        {
          id: 6,
          title: 'Proposta',
          link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/servicos/proposta/`,
          active: true,
        },
      ];

  const { budget, getBudget } = useBudgets();

  const {
    crmData,
    updateLeadByBudgetId,
    updateLeadStatus,
    updateLead,
    handleCRMData,
  } = useSalesFunnel();

  const [proposal, setProposal] = useState<Proposal | null>(null);

  const [loading, setLoading] = useState(true);

  const isAdminOrConsultant = [
    UserRoles.CONSULTOR_SUCESSO,
    UserRoles.ADMIN,
  ].includes(user.role);

  const normalizeCustomerData = () => {
    const { customer, address } = budget as Budget;

    return {
      name: customer.name,
      document: customer.document,
      email: customer.email,
      phone: customer.phone,
      zipcode: address.zipCode,
      state: address.state,
      city: address.city,
      district: address.neighborhood,
      address: address.street,
      addressNumber: String(address.number),
      addressComplement: address.complement || undefined,
    };
  };

  const createLeadWithBudgetID = async () => {
    const normalizedCustomerData = normalizeCustomerData();

    const newLead = {
      franchiseId,
      franchiseUserId: isAdminOrConsultant ? undefined : user.id,
      budgetId: budget?.id as string,
      customerData: {
        ...normalizedCustomerData,
      },
    };

    try {
      await crmApi.post('/leads', newLead);

      if (hasEditPath) {
        return updateLeadByBudgetId({
          budgetId: budget?.id as string,
          status: 'negociacao',
        });
      }
    } catch (error) {
      return toast.error('Não foi possível criar o lead');
    }
  };

  const createLeadByBudget = async () => {
    const { customer } = budget as Budget;

    const budgetLead = {
      franchiseId,
      franchiseUserId: isAdminOrConsultant ? undefined : user.id,
      customerId: customer.id,
      budgetId: budget?.id,
    };

    try {
      await crmApi.post('/leads/create-by-order', budgetLead);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      const isLeadCreationError = errorMessage.includes(
        'insert or update on table "leads"'
      );

      if (isLeadCreationError) {
        return createLeadWithBudgetID();
      }
    }
  };

  const createPdf = async () => {
    if (!budget) {
      await getBudget(id);
    }

    const normalizedCustomerData = normalizeCustomerData();

    try {
      const response = await productsApi.get(`/budgets/${id}/proposal`, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });

      const pdfURL = URL.createObjectURL(blob);

      const normalizedPhone =
        '55' + budget?.customer?.phone.replace(/\(|\)| |-/gm, '');

      const proposal = {
        phone: normalizedPhone,
        email: budget?.customer.email ?? '',
        pdfURL,
      };

      setProposal(proposal);

      if (crmData) {
        if (crmData.status === 'agendado') {
          await crmApi.delete('/leads/' + crmData.leadId);
        }

        if (crmData.status === 'agendado' || crmData.status === 'perdido') {
          handleCRMData(null);

          return createLeadByBudget();
        }

        const updatedLead = {
          customerData: normalizedCustomerData,
          budgetId: budget?.id as string,
        };

        await updateLeadStatus(
          crmData.leadId,
          hasEditPath ? 'negociacao' : 'orcamento'
        );

        await updateLead(crmData.leadId, updatedLead);

        return handleCRMData(null);
      }

      if (!crmData) {
        if (hasEditPath) {
          return await crmApi.put('/leads/by-budget-id/' + budget?.id, {
            status: 'negociacao',
          });
        }

        return createLeadByBudget();
      }
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      const isLeadCreationError = errorMessage.includes(
        'insert or update on table "leads"'
      );
      const isLeadEditError = errorMessage.includes('Lead not found');

      if (isLeadCreationError || isLeadEditError) {
        return createLeadWithBudgetID();
      }

      toast.error('Não foi possível gerar sua proposta');
    } finally {
      setLoading(false);
    }
  };

  const downloadProposal = (data: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
  };

  const sendByWhatsapp = (phone: string, pdfURL: string) => {
    downloadProposal(pdfURL, 'proposta.pdf');

    window.open('https://wa.me/' + phone, '_blank');
  };

  const handleFinishBudget = () => {
    history.replace('/orcamentos/orcamentos-e-pedidos/');

    URL.revokeObjectURL(String(proposal?.pdfURL));
  };

  const createOrder = () => {
    history.replace(
      '/orcamentos/orcamentos-e-pedidos/gerar-pedido/selecione/',
      id
    );

    URL.revokeObjectURL(String(proposal?.pdfURL));
  };

  useEffect(() => {
    createPdf();
  }, []);

  if (loading) {
    return (
      <Loading
        style={{ alignItems: 'center', height: '100%' }}
        label="Gerando proposta"
      />
    );
  }

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Proposta'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <ContentHeader>
          <div>
            <Button
              type="button"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              text="Enviar por e-mail"
              onClick={() => null}
              icon={<EmailIcon />}
              disabled
            />
            <Button
              type="button"
              typeStyle="default"
              backgroundHoverColor="#C9CBCF"
              text="Enviar por whatsapp"
              onClick={() =>
                sendByWhatsapp(
                  String(proposal?.phone),
                  String(proposal?.pdfURL)
                )
              }
              icon={<WhatsappIcon />}
            />
          </div>
          <div>
            <Button
              type="button"
              style={{ width: '16.125rem', border: '1px solid #C9CBCF' }}
              backgroundColor="#FFFFFF"
              typeStyle="default"
              text="Fazer download"
              onClick={() =>
                downloadProposal(String(proposal?.pdfURL), 'proposta.pdf')
              }
              icon={<CloudIcon />}
            />

            <Button
              type="button"
              text="Gerar pedido"
              onClick={createOrder}
              icon={<FileIcon />}
            />
          </div>
        </ContentHeader>

        <EmbedContainer src={proposal?.pdfURL} width="640" height="480" />

        <ButtonGroup>
          <Button type="submit" text="Finalizar" onClick={handleFinishBudget} />
        </ButtonGroup>
      </Content>
    </Container>
  );
};

export default BudgetsProposalPage;
