export const energyLightTheme = {
  white: '#FFFFFF',
  background: '#F5F5F6',
  text: '#27282B',
  menuTextActive: '#001FFF',
  menuTextinactive: '#61646B',
  gray: '#61646B',
  darkGray: '#9FA1A8',
  darkBlue: '#00299B',
  mediumBlue: '#4E6BB9',
  successPure: '#25B007',
  mediumRed: '#ED5A5A',
};
