import React, { useEffect, useMemo, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useParams, useHistory } from 'react-router-dom';
import {
  ActionButton,
  Breadcrumb,
  Button,
  SearchInput,
  Status,
} from '../../../components';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import franchisesApi from '../../../services/franchises';
import { FranchiseUserListType } from '../types';
import { Container, PageHeader, TableHeader } from './../../styles';

type FranchiseUsersPageProps = {
  id: string;
};

type TableItems = {
  id: string;
  name: string;
  email: string;
  role: string;
  enabled: JSX.Element;
  actions: JSX.Element;
};

const FranchiseUsersPage: React.FC = () => {
  const history = useHistory();

  const { id } = useParams<FranchiseUsersPageProps>();

  const [users, setUsers] = useState<FranchiseUserListType>({
    content: [],
    pagination: {
      currentPage: 0,
      lastPage: 0,
      limit: 0,
      total: 0,
    },
  });

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [name, setName] = useState<string>('');

  const [loading, setLoading] = useState(false);

  const { updateUserFranchises } = useAuth();

  const handlePageChange = (page: number): void => {
    setPage(page);
  };

  const handleLimitChange = (limit: number): void => {
    setLimit(limit);
  };

  const handleSearchChange = (search: string): void => {
    setName(search);
  };

  const links = [
    {
      id: 1,
      title: 'Integradores',
      link: '/integradores',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de integradores',
      link: '/integradores',
      active: false,
    },
    {
      id: 3,
      title: 'Usuários',
      link: `/integradores/${id}/membros`,
      active: true,
    },
  ];

  const loadUsers = () => {
    setLoading(true);

    franchisesApi
      .get<FranchiseUserListType>(
        `/api/franchises/${id}/member?page=${page}&limit=${limit}&name=${name}`
      )
      .then((res) => {
        setUsers(res.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
    },
    {
      id: 'email',
      label: 'E-mail',
      onClick: () => {},
    },
    {
      id: 'role',
      label: 'Tipo',
      onClick: () => {},
    },
    {
      id: 'enabled',
      label: 'Status',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
    },
  ];

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return users.content.map(({ user }) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        role: user.role,
        enabled: <Status text={user.enabled ? 'Ativo' : 'Desativado'} />,
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Editar"
              onClick={() => {
                history.push(`/integradores/${id}/membros/${user.id}/editar`);
              }}
            >
              <FiEdit />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [users]);

  useEffect(() => {
    loadUsers();
  }, [page, limit, name]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>Lista de usuários</span>
      </PageHeader>

      <Table
        items={tableItems}
        columns={tableColumns}
        isLoading={loading}
        header={
          <TableHeader>
            <SearchInput
              name="search"
              placeholder="Digite aqui para pesquisar"
              onTextChange={handleSearchChange}
            />
            <Button
              text="Novo usuário"
              icon="add"
              onClick={() => history.push(`/integradores/${id}/membros/novo`)}
            />
          </TableHeader>
        }
        placeholder="Nenhum usuário encontrado"
        pagination={users.pagination}
        onPageChange={handlePageChange}
        onLimitChange={handleLimitChange}
      />
    </Container>
  );
};

export default FranchiseUsersPage;
