import { ReactNode } from 'react';
import * as BudgetTypes from '../budgets/types';
import { Option } from '../../components/modal/sales-funnel/components/tags/tag-select';
import { DebouncedFunc } from 'lodash';
import { Steps } from '../../pages/sales-funnel/components/Kanban/TagsModal';

export type KanbanData = {
  id: string;
  label: string;
  color: string;
  total?: string;
  leads: Lead[];
};

export type Leads = {
  agendado: Lead[];
  captacao: Lead[];
  orcamento: Lead[];
  negociacao: Lead[];
  propostaFinal: Lead[];
  recuperacao: Lead[];
  fechado: Lead[];
  perdido: Lead[];
};

export type Lead = {
  id: string;
  customerId: string;
  statusId: string;
  franchiseId: string;
  franchiseUserId: string;
  budgetId: string | null;
  order: number;
  orderId: string | null;
  createdAt: string;
  updatedAt: string;
  total?: string;
  customer: {
    id: string;
    name: string;
    sector: string;
    origin: string;
    document: string | null;
    normalizedDocument: string;
    identification: string | null;
    email: string | null;
    phone: string | null;
    workPhone: string | null;
    zipcode: string | null;
    state: string | null;
    city: string | null;
    district: string | null;
    address: string | null;
    addressNumber: string | null;
    addressComplement: string | null;
  };
  status: {
    id: string;
    status: LeadStatus;
  };
  franchise: {
    id: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  };
  franchiseUser: {
    id: string;
    userId: string;
    franchiseId: string;
    role: string;
    user: {
      id: string;
      name: string;
      email: string;
      cpf: string | null;
      role: string;
      enabled: boolean;
    };
  };
  schedule?: Schedule;
  budgetItem: BudgetItem[];
  tags: SelectedTag[];
};

export type BudgetItem = {
  id: string;
  budgetId: string;
  parentId: string | null;
  total: string;
};

export type Schedule = {
  description: string;
  id: string;
  leadId: string;
  scheduledTo: string;
};

export type UpdatedLead = {
  customerData: {
    name: string;
    document: string;
    email: string;
    phone: string;
    zipcode: string;
    state: string;
    city: string;
    district: string;
    address: string;
    addressNumber: string;
    addressComplement?: string;
  };
  budgetId?: string;
  orderId?: string;
};

export type CRMData = {
  leadId: string;
  name: string;
  email: string;
  phone: string;
  documentType: string;
  cpf: string;
  cnpj: string;
  zipCode: string;
  street: string;
  state: string;
  city: string;
  neighborhood: string;
  complement: string;
  number: string;
  status: keyof typeof Status;
};

export type LostData = {
  reason: string;
  description: string;
};

export enum Status {
  captacao = 'captacao',
  orcamento = 'orcamento',
  negociacao = 'negociacao',
  propostaFinal = 'propostaFinal',
  fechado = 'fechado',
  perdido = 'perdido',
  recuperacao = 'recuperacao',
  agendado = 'agendado',
}

export enum STATES_UF {
  'Acre' = 'AC',
  'Alagoas' = 'AL',
  'Amapá' = 'AP',
  'Amazonas' = 'AM',
  'Bahia' = 'BA',
  'Ceará' = 'CE',
  'Distrito Federal' = 'DF',
  'Espírito Santo' = 'ES',
  'Goiás' = 'GO',
  'Maranhão' = 'MA',
  'Mato Grosso' = 'MT',
  'Mato Grosso do Sul' = 'MS',
  'Minas Gerais' = 'MG',
  'Pará' = 'PA',
  'Paraíba' = 'PB',
  'Paraná' = 'PR',
  'Pernambuco' = 'PE',
  'Piauí' = 'PI',
  'Rio de Janeiro' = 'RJ',
  'Rio Grande do Norte' = 'RN',
  'Rio Grande do Sul' = 'RS',
  'Rondônia' = 'RO',
  'Roraima' = 'RR',
  'Santa Catarina' = 'SC',
  'São Paulo' = 'SP',
  'Sergipe' = 'SE',
  'Tocantins' = 'TO',
}

export type LeadStatus = keyof typeof Status;

export type UpdateLeadByBudgetIdInput = {
  budgetId: string;
  orderId?: string;
  status: LeadStatus;
  customerData?: UpdatedLead['customerData'] & { id?: string };
};

export type Tag = {
  id: string;
  name: string | null;
  color: string;
  franchiseId: string;
};

export type SelectedTag = {
  id: string;
  leadId: string;
  franchiseTagId: string;
  franchiseTag: {
    id: string;
    name: string | null;
    color: string;
    franchiseId: string;
  };
};

export type LeadModal = {
  isOpen: boolean;
  data: Lead | null;
};

export type Filter = {
  name: string;
  tags: string[];
  userId: string;
  date: Date;
};

export type Date = {
  startDate: string;
  endDate: string;
};

export type TagModal = {
  isOpen: boolean;
  data: Tag | null;
  step: Steps;
};

export type Loading = {
  leadBudgetItems: boolean;
  leads: boolean;
};

export type SalesFunnelContextData = {
  kanban: KanbanData[];
  crmData: CRMData | null;
  leadBudgetItems: BudgetTypes.BudgetItem[] | null;
  loading: Loading;
  tags: Tag[];
  tagsSelected: Option[];
  leadModal: LeadModal;
  filters: Filter;
  tagModal: TagModal;
  getLeads: () => Promise<void>;
  getLeadsHistory: () => Promise<void>;
  getLeadBudgetItems: (budgetId: string) => Promise<void>;
  clearLeadBudgetItems: () => void;
  handleCRMData: (data: Lead | null) => void;
  updateLeadStatus: (leadId: string, status: LeadStatus) => Promise<void>;
  updateLead: (leadId: string, updatedLead: UpdatedLead) => Promise<void>;
  updateToLost: (leadId: string, data: LostData) => Promise<void>;
  updateLeadByBudgetId: (data: UpdateLeadByBudgetIdInput) => Promise<void>;
  addTag: (tag: Partial<Tag>) => Promise<void>;
  getTags: () => Promise<void>;
  deleteTag: (tagId: string) => Promise<void>;
  editTag: (tag: Omit<Tag, 'franchiseId'>) => Promise<void>;
  handleSelectedTag: (selectedTag: Option, leadId: string) => Promise<void>;
  handleUnselectTag: (tagId: string) => Promise<void>;
  handleDefaultSelectedTags: (lead: Lead) => void;
  handleLeadModal: (data?: Lead | null) => void;
  handleFilters: DebouncedFunc<
    (name: string, tags: string[], userId: string, date: Date) => void
  >;
  handleTagModal: (step: Steps, data: Tag | null, isOpen?: boolean) => void;
};

export type SalesFunnelProvider = {
  children: ReactNode;
};
