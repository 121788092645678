import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Container, Wrapper, PageHeader } from '../styles';
import {
  FormContainer,
  InputGroup,
  GroupedInputs,
  TableContainer,
} from '../../styles';
import Textarea from '../../../components/form-elements/textarea';
import { Button, Breadcrumb } from '../../../components';
import { ButtonContainer } from './style';
import Select from '../../../components/form-elements/select';
import projectsApi from '../../../services/projects';
import { ProjectDocumentationAnalysisType, ProjectStatus } from '../types';
import { useAuth } from '../../../contexts/auth';
import axios from 'axios';

type CallCenterDocumentationPageProps = {
  id: string;
};

type DocumentAnalisysType = {
  status: string;
  documentationObservations: string;
};

const CallCenterDocumentationPage: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [projectDocumentationAnalysis, setProjectDocumentationAnalysis] =
    useState<ProjectDocumentationAnalysisType[]>([]);
  const { id } = useParams<CallCenterDocumentationPageProps>();
  const { user } = useAuth();

  useEffect(() => {
    projectsApi
      .get<ProjectDocumentationAnalysisType[]>(
        `/api/projects/${id}/project-documentation-analysis`
      )
      .then((res) => {
        setProjectDocumentationAnalysis(res.data);
      });
  }, []);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 3,
      title: 'Análise de Documentação',
      link: '/projetos',
      active: true,
    },
  ];

  const schema = yup.object().shape({
    status: yup.string().required('Concessionária é um campo obrigatório'),
    description: yup.string().optional(),
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<DocumentAnalisysType>({ resolver: yupResolver(schema) });

  const onSubmit = async (data: DocumentAnalisysType) => {
    try {
      await projectsApi.post(`/api/projects/${id}/status-log`, {
        userId: user.id,
        observation: data.documentationObservations
          ? data.documentationObservations
          : '',
        status: data.status,
      });

      await projectsApi.post(
        `/api/projects/${id}/project-documentation-analysis`,
        {
          userId: user.id,
          observation: data.documentationObservations
            ? data.documentationObservations
            : '',
          status: data.status,
        }
      );

      await projectsApi.patch(`/api/projects/${id}/status`, {
        status: data.status,
      });

      toast.success('Status atualizado com sucesso');
      history.push('/projetos/central-atendimento');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data.message);
      } else {
        toast.error('Erro desconhecido, tente novamente mais tarde.');
      }
    }
  };

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Análise da Documentação</span>
        </PageHeader>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <GroupedInputs>
              <InputGroup size="mid" sideSpace="right">
                <span>Status</span>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        name="status"
                        onChange={onChange}
                        value={value}
                        control={control}
                        placeholder="Selecione uma opção"
                        options={[
                          {
                            label: ProjectStatus.DOCUMENTACAO_APROVADA,
                            value: ProjectStatus.DOCUMENTACAO_APROVADA,
                          },
                          {
                            label: ProjectStatus.DOCUMENTACAO_INCOMPLETA,
                            value: ProjectStatus.DOCUMENTACAO_INCOMPLETA,
                          },
                        ]}
                        error={errors.status?.message}
                      />
                    );
                  }}
                />
              </InputGroup>
            </GroupedInputs>
            <InputGroup>
              <Textarea
                name="documentationObservations"
                label="Observações"
                onChange={(e) => {
                  setValue('documentationObservations', e.target.value);
                }}
                error={errors.documentationObservations?.message}
              />
            </InputGroup>

            <ButtonContainer>
              <div>
                <Button
                  text="Salvar"
                  backgroundColor="#001FFF"
                  backgroundHoverColor="#2772f8"
                />
              </div>
            </ButtonContainer>
            <TableContainer>
              <div>
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Usuário</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {projectDocumentationAnalysis.map(
                      (projectDocumentationAnalysis) => (
                        <tr key={projectDocumentationAnalysis.id}>
                          <td>{projectDocumentationAnalysis.status}</td>
                          <td>{projectDocumentationAnalysis.user.name}</td>
                          <td>{projectDocumentationAnalysis.observation}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Form>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default CallCenterDocumentationPage;
