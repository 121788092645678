import styled from 'styled-components';

export const Content = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > h1 {
    font-weight: 700;
    font-size: 1.125rem;
    text-align: center;
  }

  & > section {
    max-height: 50vh;

    overflow-y: auto;

    background-color: #f0f0f0;

    padding: 1rem;

    & > h1,
    h2,
    p,
    ul {
      color: #27282b;
    }

    & > h2 {
      font-weight: 700;
      font-size: 1rem;
      margin: 1rem 0;
    }

    & > p,
    ul {
      font-weight: 400;
      font-size: 1rem;
    }

    & > ul > li {
      padding-left: 1rem;
      margin-bottom: 1rem;
    }

    & > p.spacing {
      margin-bottom: 1rem;
    }

    & > p.spacing-left {
      padding-left: 1rem;
    }
  }

  & > label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    color: #27282b;
  }
`;
