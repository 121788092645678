import React, { memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Container, Header } from './styles';
import { ItemList } from '../ItemList';
import { IColumn } from '..';

interface Props {
  column: IColumn;
  index: number;
}

export const Column = memo(function Column(props: Props) {
  const { column, index } = props;

  return (
    <Draggable draggableId={column.id} index={index} isDragDisabled>
      {(provided) => {
        return (
          <Container {...provided.draggableProps} ref={provided.innerRef}>
            <Header {...provided.dragHandleProps} color={column.color}>
              <div>
                <h2>{column.label}</h2>

                {/* {column.total && <span>{column.total}</span>} */}
              </div>
            </Header>
            <ItemList column={column} index={index} />
          </Container>
        );
      }}
    </Draggable>
  );
});
