import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../auth';
import * as Types from './types';

const DashboardContext = createContext({} as Types.DashboardContextData);

type Filter = {
  franchise?: string;
  admin?: boolean;
};

export const DashboardProvider: React.FC = (props) => {
  const { children } = props;

  const { user, franchises, selectedFranchise } = useAuth();

  const history = useHistory();

  const [loading, setLoading] = useState(true);

  const [mapData, setMapData] = useState<Types.MapData>({} as Types.MapData);
  const [franchiseData, setFranchiseData] =
    useState<Types.FranchiseData | null>(null);

  const fetchData = async (filter?: Filter) => {
    setLoading(true);

    try {
      const response = await axios.get('/dashboard-mock.json');

      const { data } = response;

      if (filter?.admin) {
        setFranchiseData(data['TOTAL']);
      } else if (filter?.franchise) {
        const [code] = filter.franchise.split('-');

        const formattedCode = code.startsWith('0') ? code.substring(1) : code;

        const franchiseName = Object.keys(data).find((key) =>
          key.includes(formattedCode.trim())
        );

        const testFranchises = ['Franquia Teste', 'Teste 2', 'Teste de CRM'];

        if (!franchiseName && !testFranchises.includes(filter.franchise)) {
          throw new Error('Franquia não encontrada');
        }

        setFranchiseData(data[franchiseName || '0007- Fernandopolis-SP']);
      } else {
        throw new Error('Nenhuma franquia selecionada');
      }

      setMapData(data.map);
    } catch (error) {
      toast.error('Não foi possível encontrar a franquia');

      return history.push('/franquia/selecionar');
    } finally {
      setLoading(false);
    }
  };

  const getFranchiseData = () => {
    setFranchiseData(null);

    if (user.role === 'Admin') {
      fetchData({ admin: true });
      return;
    }

    if (user.role === 'Franqueado') {
      const selectedFranchiseData = franchises.find(
        ({ franchise }) => franchise.id === selectedFranchise
      );

      fetchData({
        franchise: selectedFranchiseData?.franchise.name,
        admin: false,
      });
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        mapData,
        franchiseData,
        loading,
        getFranchiseData,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  return context;
};
