/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import cepApi from 'cep-promise';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { Props } from 'react-modal';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { ShapeModal } from '../..';
import { useAuth } from '../../../../contexts/auth';
import { useSalesFunnel } from '../../../../contexts/sales-funnel';
import crmApi from '../../../../services/crm';
import { Button } from '../../../button';
import { BRFlag } from '../../../icons';
import Input from '../../../input';
import InputPhone from '../../../input-phone';
import MaskInput from '../../../mask-input';
import RadioGroup from '../../../radio-group';
import SelectInput from '../../../select';
import {
  Buttons,
  Container,
  Content,
  DocumentWrapper,
  Header,
  Wrapper,
} from './styles';
import { Lead } from '../../../../contexts/sales-funnel/types';
import { UserRoles } from '../../../../types/users';

type NewLeadModalProps = {
  lead?: Lead | null;
  onCancel: () => void;
} & Props;

type Option = {
  label: string;
  value: string;
};

enum STATES_UF {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito Federal',
  ES = 'Espírito Santo',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato Grosso',
  MS = 'Mato Grosso do Sul',
  MG = 'Minas Gerais',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio de Janeiro',
  RN = 'Rio Grande do Norte',
  RS = 'Rio Grande do Sul',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa Catarina',
  SP = 'São Paulo',
  SE = 'Sergipe',
  TO = 'Tocantins',
}

type Form = {
  name: string;
  document: string;
  installationType?: Option;
  origin?: Option;
  email: string;
  phone: string;
  workPhone: string;
  zipcode: string;
  state: string;
  city: string;
  district: string;
  address: string;
  addressNumber: string;
  addressComplement: string;
  status: string;
  documentType?: string;
};

const schema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  document: yup.string().test('document', 'Documento inválido', (value) => {
    if (!value) {
      return true;
    }

    const isCPF = value.length <= 14;

    if (isCPF) {
      return cpf.isValid(String(value));
    } else {
      return cnpj.isValid(String(value));
    }
  }),
  installationType: yup.object().optional(),
  origin: yup.object().optional(),
  email: yup.string().email(),
  phone: yup
    .string()
    .test('phone', 'Número inválido', (value) => {
      if (!value) {
        return true;
      }

      if (value.length > 1) {
        const isNotValid = value.length < 15;

        return isNotValid ? false : true;
      }

      return true;
    })
    .optional(),
  workPhone: yup.string().optional(),
  zipcode: yup.string().test('zipcode', 'CEP inválido', (value) => {
    if (!value) {
      return true;
    }

    const isNotValid = value.length < 9;

    return isNotValid ? false : true;
  }),
  state: yup.string().optional(),
  city: yup.string().optional(),
  district: yup.string().optional(),
  address: yup.string().optional(),
  addressNumber: yup.string().optional(),
  addressComplement: yup.string().optional(),
});

const options = {
  sourceChannel: [
    {
      label: 'Indicação',
      value: 'indication',
    },
    {
      label: 'Landing page',
      value: 'landing-page',
    },
    {
      label: 'Captação direta',
      value: 'capitation',
    },
    {
      label: 'Anúncios',
      value: 'ads',
    },
    {
      label: 'Parcerias',
      value: 'partners',
    },
    {
      label: 'Redes Sociais',
      value: 'social-networks',
    },
    {
      label: 'Eventos',
      value: 'events',
    },
    {
      label: 'Outros',
      value: 'others',
    },
  ],
  installationType: [
    {
      label: 'Comercial',
      value: 'commercial',
    },
    {
      label: 'Industrial',
      value: 'industrial',
    },
    {
      label: 'Residencial',
      value: 'residential',
    },
  ],
  document: [
    {
      label: 'CPF',
      value: 'cpf',
    },
    {
      label: 'CNPJ',
      value: 'cnpj',
    },
  ],
};

export const LeadModal: React.FC<NewLeadModalProps> = (props) => {
  const { onCancel, lead, ...rest } = props;

  const { user, selectedFranchise } = useAuth();

  const { getLeads } = useSalesFunnel();

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      document: '',
      installationType: undefined,
      origin: undefined,
      email: '',
      phone: '',
      workPhone: '',
      zipcode: '',
      state: '',
      city: '',
      district: '',
      address: '',
      addressNumber: '',
      addressComplement: '',
      documentType: 'cpf',
    },
  });

  const isCPF = watch('documentType') === 'cpf';

  const isAdminOrConsultant = [
    UserRoles.CONSULTOR_SUCESSO,
    UserRoles.ADMIN,
  ].includes(user.role);

  const onSubmit = async (data: Form) => {
    if (!selectedFranchise) {
      toast.info('Selecione um integrador');

      return;
    }

    const { documentType, installationType, origin, ...formData } = data;

    const normalizeData = {
      franchiseId: selectedFranchise,
      franchiseUserId: isAdminOrConsultant ? undefined : user.id,
      customerData: {
        ...formData,
        origin: origin?.label || undefined,
        sector: installationType?.label || undefined,
        address: formData.address || undefined,
        addressNumber: formData.addressNumber || undefined,
        city: formData.city || undefined,
        district: formData.district || undefined,
        email: formData.email || undefined,
        phone: formData.phone || undefined,
        state: formData.state || undefined,
        zipcode: formData.zipcode || undefined,
        document: formData.document || undefined,
        addressComplement: formData.addressComplement || undefined,
        workPhone: formData.workPhone || undefined,
      },
    };

    const { franchiseId, franchiseUserId, ...updatedLead } = normalizeData;

    try {
      lead
        ? await crmApi.put(`/leads/${lead.id}`, {
            ...updatedLead,
            budgetId: lead.budgetId || undefined,
            orderId: lead.orderId || undefined,
          })
        : await crmApi.post('/leads', normalizeData);

      await getLeads();

      closeModal();

      toast.success(
        lead ? 'Lead atualizado com sucesso' : 'Lead criado com sucesso'
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível criar o lead.');
    }
  };

  const getAddress = async (cep: string) => {
    try {
      const {
        city,
        state,
        street: address,
        neighborhood: district,
      } = await cepApi(cep);

      reset({
        address,
        district,
        city,
        state: STATES_UF[state as keyof typeof STATES_UF],
        installationType: getValues('installationType'),
        origin: getValues('origin'),
      });

      clearErrors('zipcode');
    } catch (error) {
      setError('zipcode', {
        type: 'not-found',
        message: 'CEP não encontrado',
      });
    }
  };

  const closeModal = () => {
    onCancel();

    clearErrors();

    resetForm();
  };

  const resetForm = () => {
    reset({
      name: '',
      document: '',
      installationType: undefined,
      origin: undefined,
      email: '',
      phone: '',
      workPhone: '',
      zipcode: '',
      state: '',
      city: '',
      district: '',
      address: '',
      addressNumber: '',
      addressComplement: '',
    });
  };

  const resetDocument = () => {
    setValue('document', '');
  };

  const resetToLeadData = () => {
    if (lead) {
      const { customer } = lead;

      const isCPF = (customer.document?.length ?? 0) <= 14;

      const normalizedZipCode = customer.zipcode?.replace(
        /^(\d{5})[-]?(\d{3})$/,
        '$1-$2'
      );

      reset({
        name: customer.name,
        installationType: options.installationType.find(
          (option) => option.label === customer.sector
        ),
        origin: options.sourceChannel.find(
          (option) => option.label === customer.origin
        ),
        documentType: isCPF
          ? options.document[0].value
          : options.document[1].value,
        document: customer.document || '',
        email: customer.email || '',
        phone: customer.phone || '',
        workPhone: customer.workPhone || '',
        zipcode: normalizedZipCode || '',
        state: customer.state || '',
        city: customer.city || '',
        district: customer.district || '',
        address: customer.address || '',
        addressNumber: customer.addressNumber || '',
        addressComplement: customer.addressComplement || '',
      });
    }
  };

  useEffect(() => {
    resetToLeadData();
  }, [lead]);

  return (
    <ShapeModal
      padding="0"
      maxWidth="60rem"
      onRequestClose={closeModal}
      {...rest}
    >
      <Container onSubmit={handleSubmit(onSubmit)}>
        <Header>
          <h1>{lead ? 'Editar lead' : 'Novo lead'}</h1>

          <button onClick={closeModal} type="button">
            <FiX />
          </button>
        </Header>

        <Content>
          <Wrapper>
            <header>
              <h1>Dados do cliente</h1>
            </header>

            <section>
              <Input
                required
                label="Nome Completo"
                placeholder="Digite o nome completo"
                {...register('name')}
                error={errors.name?.message}
              />

              <DocumentWrapper>
                <Controller
                  control={control}
                  name="documentType"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <RadioGroup
                        options={options.document}
                        onChange={(event) => {
                          const value = event.target.value;

                          onChange(value);

                          resetDocument();
                        }}
                        value={value}
                        ref={ref}
                      />
                    );
                  }}
                />

                <MaskInput
                  label={isCPF ? 'CPF' : 'CNPJ'}
                  placeholder="Digite o número"
                  mask={isCPF ? '999.999.999-99' : '99.999.999/9999-99'}
                  {...register('document')}
                  error={errors.document?.message}
                />
              </DocumentWrapper>
            </section>

            <div>
              <Controller
                name="installationType"
                control={control}
                render={({
                  field: { name, value, ref, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      ref={ref}
                      label="Tipo de instalação"
                      placeholder="Selecionar opção"
                      options={options.installationType}
                      onChange={onChange}
                      value={value ?? ''}
                      error={error?.message}
                    />
                  );
                }}
              />

              <Controller
                name="origin"
                control={control}
                render={({
                  field: { name, value, ref, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectInput
                      name={name}
                      ref={ref}
                      label="Canal de Origem"
                      placeholder="Selecionar opção"
                      options={options.sourceChannel}
                      onChange={onChange}
                      value={value ?? ''}
                      error={error?.message}
                    />
                  );
                }}
              />
            </div>
          </Wrapper>

          <hr />

          <Wrapper>
            <header>
              <h1>Contato</h1>
            </header>

            <div>
              <Input
                label="Email"
                placeholder="email@email.com"
                {...register('email')}
                error={errors.email?.message}
              />

              <InputPhone
                label="Celular"
                mask="(99) 99999-9999"
                placeholder="+55 (00) 00000-0000"
                iconLeft={<BRFlag />}
                {...register('phone')}
                error={errors.phone?.message}
              />

              <InputPhone
                label="Telefone Comercial"
                mask="(99) 99999-9999"
                placeholder="+55 (00) 00000-0000"
                iconLeft={<BRFlag />}
                {...register('workPhone')}
                error={errors.workPhone?.message}
              />

              <MaskInput
                label="CEP"
                placeholder="00000-000"
                mask="99999-999"
                {...register('zipcode', {
                  onChange: async (event) => {
                    if (event.target.value.length === 9)
                      return await getAddress(event.target.value);

                    if (!event.target.value.length)
                      return reset({
                        district: '',
                        city: '',
                        state: '',
                        address: '',
                      });
                  },
                })}
                error={errors.zipcode?.message}
              />

              <Input
                label="Estado"
                placeholder="Digite o nome do estado"
                {...register('state')}
                error={errors.state?.message}
                disabled
              />

              <Input
                label="Cidade"
                placeholder="Digite o nome da cidade"
                {...register('city')}
                error={errors.city?.message}
                disabled
              />

              <Input
                label="Bairro"
                placeholder="Digite o nome do bairro"
                {...register('district')}
                error={errors.district?.message}
              />

              <Input
                className="complement"
                style={{ gridColumn: '2 span' }}
                label="Logradouro"
                placeholder="Digite seu endereço"
                {...register('address')}
                error={errors.address?.message}
              />

              <MaskInput
                label="N°"
                placeholder="Digite o número"
                mask="99999"
                {...register('addressNumber')}
                error={errors.addressNumber?.message}
              />

              <Input
                className="address"
                style={{ gridColumn: '2 span' }}
                label="Complemento"
                placeholder="Digite o texto"
                {...register('addressComplement')}
                error={errors.addressComplement?.message}
              />
            </div>
          </Wrapper>
        </Content>

        <Buttons>
          <Button
            width="9.5rem"
            type="button"
            text="Cancelar"
            typeStyle="default"
            onClick={closeModal}
          />
          <Button
            width="9.5rem"
            type="submit"
            text="Salvar"
            backgroundColor="#001FFF"
          />
        </Buttons>
      </Container>
    </ShapeModal>
  );
};
