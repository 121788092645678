import React, { useEffect, useMemo, useState } from 'react';
import {
  ActionButton,
  Breadcrumb,
  Button,
  SearchInput,
  StepProgress,
} from '../../../../../components';
import { Content, Header, TableHeader, Wrapper } from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonGroup, Container, PageHeader } from '../../../../styles';
import Table, {
  ColumnStructure,
  TableData,
} from '../../../../../components/table';
import Checkbox from '../../../../../components/checkbox';
import InnerActions from '../../../../../components/table/innerActions';
import { FiEye } from 'react-icons/fi';
import DynamicFilter, {
  FilterOption,
  Option,
} from '../../../../../components/dynamic-filter';
import { useAuth } from '../../../../../contexts/auth';
import productsApi from '../../../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../../../types/pagination';
import { Budget, BudgetItem, Kit } from '../../../../../contexts/budgets/types';
import { KitDescriptionModal } from '../../../../../components/modal/kit-description';
import { toMoneyFormat } from '../../../../../utils/toMoneyFormat';
import { debounce } from 'lodash';
import { useBudgets } from '../../../../../contexts/budgets';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getQueryParams } from '../../../../../utils/getQueryParams';
import Loading from '../../../../../components/loading';

type TableItems = {
  id: string;
  checkbox: JSX.Element;
  supplier: string;
  inverterType: string;
  power: string;
  category: string;
  fabricMaterial: string;
  price: string;
  actions: JSX.Element;
};

type CellErrorMessage = {
  id: string;
  label: string[];
};

type Filter = {
  selecteds: Filters;
  options: FilterOption[];
};

type Filters = {
  category: string[];
  inverter_lv: boolean[];
  inverter_type: string[];
  phase: string[];
  voltage: string[];
};

enum FiltersLabels {
  category = 'Categoria',
  inverter_lv = 'Inversor LV',
  inverter_type = 'Tipo de Inversor',
  phase = 'Fase',
  voltage = 'Tensão',
  supplier = 'Fornecedor',
}

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const CustomKitSelectKitsPage: React.FC = () => {
  const history = useHistory();

  const { pathname, search: searchParams } = history.location;

  const hasEditPath = pathname.includes('editar');

  const { id, customItemId } = useParams<{
    id: string;
    customItemId: string;
  }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`,
      active: true,
    },
  ];

  const { selectedFranchise: franchiseId } = useAuth();

  const { budget, getBudget } = useBudgets();

  const [loading, setLoading] = useState(true);

  const { fabricMaterial, supplier } = {
    fabricMaterial: getQueryParams('fabricMaterial')?.split(','),
    supplier: getQueryParams('supplier'),
  };

  const [kitDescription, setKitDescription] = useState('');

  const hasKitDescription = !!kitDescription;

  const handleKitDescriptionModal = (description: string) =>
    setKitDescription(description);

  const [customKits, setCustomKits] =
    useState<PaginatedResponse<Kit>>(EMPTY_PAGE);

  const customItem = budget?.items.find(
    (item) => item.budgetCustomItemId === customItemId
  )?.customItem;

  const { pagination } = customKits;

  const [search, setSearch] = useState('');

  const [filter, setFilter] = useState<Filter>({
    options: [],
    selecteds: {
      category: [],
      phase: [],
      voltage: [],
      inverter_lv: [],
      inverter_type: [],
    },
  });

  const handleSearchKits = (search: string) => {
    setSearch(search);

    getCustomKits(
      pagination.currentPage,
      pagination.limit,
      search,
      filter.selecteds
    );
  };

  const [errorMessage, setErrorMessage] = useState<CellErrorMessage[]>([]);

  const validateKits = (kits: Kit[], budget: Budget | null) => {
    const normalizeData = (warnings: CellErrorMessage[]) => {
      const result: { [key: string]: CellErrorMessage } = {};

      warnings.forEach((item) => {
        if (result[item.id]) {
          result[item.id].label = [...result[item.id].label, ...item.label];
        } else {
          result[item.id] = { id: item.id, label: item.label };
        }
      });

      const formattedError = Object.values(result);

      return setErrorMessage((state) => [...state, ...formattedError]);
    };

    switch (budget?.phase) {
      case 'Monofásico':
      case 'Bifásico': {
        const entryWarning = kits
          .filter((item) => item.phase === 'Trifásico')
          .map((item) => ({
            id: item.id,
            label: [
              `Troca do padrão de entrada ${
                budget?.phase === 'Monofásico' ? 'monofásico' : 'bifásico'
              } para trifásico.`,
            ],
          }));

        const transformWarning = kits
          .filter(
            (item) =>
              (budget?.voltage === 220 &&
                item.phase === 'Trifásico' &&
                !item.inverterLv) ||
              (budget?.voltage === 380 &&
                item.phase === 'Trifásico' &&
                item.inverterLv)
          )
          .map((item) => {
            return {
              id: item.id,
              label: ['Necessário o uso de transformador.'],
            };
          });

        const allWarnings = [...entryWarning, ...transformWarning];

        return normalizeData(allWarnings);
      }

      case 'Trifásico': {
        const transformWarning = kits
          .filter(
            (item) =>
              (budget?.voltage === 220 &&
                item.phase === 'Trifásico' &&
                !item.inverterLv) ||
              (budget?.voltage === 380 &&
                item.phase === 'Trifásico' &&
                item.inverterLv)
          )
          .map((item) => {
            return {
              id: item.id,
              label: ['Necessário o uso de transformador.'],
            };
          });

        return normalizeData(transformWarning);
      }
    }
  };

  const [orderBy, setOrderBy] = useState('');

  const handleOrderBy = (columnId: string) => {
    const currentColumn = tableColumns.find((column) => column.id === columnId);

    if (currentColumn) {
      setOrderBy(
        currentColumn.id +
          ',' +
          (currentColumn.orderBy === 'DESC' ? 'ASC' : 'DESC')
      );
    }
  };

  const [selectedKits, setSelectedKits] = useState<Kit[]>([]);

  const handleSelectAllKits = (kits: Kit[]) => {
    setSelectedKits((state) => {
      const hasValue = kits.every((_kit) =>
        state.some((kit) => kit.id === _kit.id)
      );

      if (hasValue) {
        return state.filter((_kit) => !kits.find((kit) => kit.id === _kit.id));
      }

      const normalizedKits = kits.map((kit) => ({
        ...kit,
        quantity: 1,
      }));

      return [...state, ...normalizedKits];
    });
  };

  const handleSelectKit = (kit: Kit) => {
    setSelectedKits((state) => {
      const normalizedKit = {
        ...kit,
        quantity: kit.quantity || 1,
      };

      const existingValue = state.find((k) => k.id === kit.id);

      if (existingValue) {
        return state.filter((k) => k.id !== kit.id);
      }

      return [...state, normalizedKit];
    });
  };

  const allCustomKitsSelected =
    !customKits.loading &&
    customKits.content.length > 0 &&
    customKits.content.every((kit) =>
      selectedKits.find((_kit) => _kit.id === kit.id)
    );

  const hasSelectedKits = selectedKits.length > 0;

  const tableColumns: ColumnStructure[] = useMemo(() => {
    const [column, order] = orderBy.split(',');

    return [
      {
        id: 'checkbox',
        label: 'Checkbox',
        type: 'actionCell',
        checkbox: (
          <Checkbox
            styles={{ padding: '1.2rem 2rem' }}
            name={'allCustomKitsChecked'}
            onChange={() => handleSelectAllKits(customKits.content)}
            isChecked={allCustomKitsSelected}
          />
        ),
      },
      {
        id: 'supplier',
        label: 'Fornecedor',
        orderBy: column === 'supplier' ? order : 'DESC',
        onClick: () => handleOrderBy('supplier'),
      },
      {
        id: 'inverterType',
        label: 'Inversor',
        orderBy: column === 'inverterType' ? order : 'DESC',
        onClick: () => handleOrderBy('inverterType'),
      },
      {
        id: 'power',
        label: 'Potência',
        orderBy: column === 'power' ? order : 'DESC',
        onClick: () => handleOrderBy('power'),
      },
      {
        id: 'category',
        label: 'Categoria',
        orderBy: column === 'category' ? order : 'DESC',
        onClick: () => handleOrderBy('category'),
      },
      {
        id: 'fabricMaterial',
        label: 'Estrutura',
        orderBy: column === 'fabricMaterial' ? order : 'DESC',
        onClick: () => handleOrderBy('fabricMaterial'),
      },
      {
        id: 'price',
        label: 'Valor',
        orderBy: column === 'price' ? order : 'DESC',
        onClick: () => handleOrderBy('price'),
      },
      {
        id: 'actions',
        label: 'Ações',
        type: 'actionCell',
      },
    ];
  }, [customKits, errorMessage, orderBy, selectedKits, budget]);

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    const kits: TableItems[] = customKits.content.map((kit) => {
      const isChecked = selectedKits.some(
        (selectedKit) => selectedKit.id === kit.id
      );

      const error = errorMessage.find((item) => item.id === kit.id);

      return {
        id: kit.id,
        error: error?.label.join(' ') ?? '',
        checkbox: (
          <Checkbox
            styles={{ padding: '1.2rem 2rem' }}
            name={kit.id}
            onChange={() => handleSelectKit(kit)}
            isChecked={isChecked}
          />
        ),
        supplier: kit.supplier,
        inverterType: kit.inverterType,
        power: kit.power + 'KWp',
        fabricMaterial: kit.fabricMaterial,
        price: toMoneyFormat(kit.price),
        category: kit.category + 'W',
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Visualizar"
              onClick={() => handleKitDescriptionModal(kit.description)}
            >
              <FiEye />
            </ActionButton>
          </InnerActions>
        ),
      };
    });

    const [column, order] = orderBy.split(',');

    const columnTable = column as keyof TableItems;

    const sortedItems = kits.sort((a, b) => {
      const columnA = String(a[columnTable]);
      const columnB = String(b[columnTable]);

      return (
        columnA.localeCompare(columnB, 'pt-BR', {
          numeric: true,
          sensitivity: 'base',
        }) * (order === 'ASC' ? -1 : 1)
      );
    });

    return sortedItems;
  }, [customKits, errorMessage, orderBy, selectedKits, budget]);

  const getCustomKits = async (
    page?: number,
    limit?: number,
    search?: string,
    filters?: Filters
  ) => {
    setCustomKits((state) => ({ ...state, loading: true }));

    try {
      const { data } = await productsApi.get<PaginatedResponse<Kit>>(
        `/budgets/${id}/custom-products`,
        {
          params: {
            franchiseId,
            promotional: false,
            fabricMaterial,
            supplier,
            limit,
            page,
            search: search || undefined,
            category: filters?.category,
            phase: filters?.phase,
            voltage: filters?.voltage,
            inverter_type: filters?.inverter_type,
            inverter_lv: filters?.inverter_lv,
          },
        }
      );

      setCustomKits(data);
    } catch (error) {
      toast.error('Não foi possível buscar os kits.');
    } finally {
      setCustomKits((state) => ({ ...state, loading: false }));
    }
  };

  const handleFilters = (options: Option[]) => {
    const normalizeOption = (
      option: Option
    ): [keyof Filters, string | boolean] => {
      switch (option.label) {
        case 'Categoria':
          return ['category', option.value];
        case 'Fase':
          return ['phase', option.value];
        case 'Tensão':
          return ['voltage', option.value];
        case 'Inversor LV':
          return ['inverter_lv', option.value];
        case 'Tipo de Inversor':
          return ['inverter_type', option.value];
        default:
          throw new Error(`Label não encontrada: ${option.label}`);
      }
    };

    const selectedFilters = options.reduce(
      (acc: Filters, option) => {
        const [filterLabel, filterValue] = normalizeOption(option);

        return {
          ...acc,
          [filterLabel]: [...acc[filterLabel], filterValue],
        };
      },
      {
        category: [],
        phase: [],
        voltage: [],
        inverter_lv: [],
        inverter_type: [],
      }
    );

    setFilter((state) => ({
      ...state,
      selecteds: selectedFilters,
    }));

    getCustomKits(
      pagination.currentPage,
      pagination.limit,
      search,
      selectedFilters
    );
  };

  const normalizeFilters = (options: Filters) => {
    const formatLabel: Record<
      keyof Filters,
      (value: string | boolean) => string
    > = {
      category: (value) => `${value}W`,
      inverter_lv: (value) => (value ? 'Sim' : 'Não'),
      inverter_type: (value) => `${value}`,
      phase: (value) => `${value}`,
      voltage: (value) => `${value}V`,
    };

    const normalizeOption = (
      field: keyof typeof FiltersLabels,
      value: string[] | boolean[]
    ) => {
      const label = FiltersLabels[field];

      const options = value
        .map((item) => {
          return {
            label: formatLabel[field as keyof typeof formatLabel](item),
            value: item,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));

      return { label, options };
    };

    const normalizedFilters = Object.entries(options)
      .map(([field, value]) =>
        normalizeOption(field as keyof typeof FiltersLabels, value)
      )
      .sort((a, b) => {
        if (a.label === FiltersLabels.inverter_lv) {
          return 1;
        }

        if (a.label === FiltersLabels.inverter_type) {
          return b.label === FiltersLabels.inverter_lv ? -1 : 1;
        }

        return a.label.localeCompare(b.label);
      });

    return normalizedFilters;
  };

  const getFilters = async () => {
    const { data } = await productsApi.get<Filters>('/products/filters/', {
      params: {
        fields: 'category,phase,voltage,inverter_type,inverter_lv',
        franchiseId,
      },
    });

    setFilter((state) => {
      return {
        ...state,
        options: normalizeFilters(data),
      };
    });
  };

  const createCustomKit = async () => {
    try {
      const products = selectedKits.map((kit) => ({
        id: kit.id,
        quantity: kit.quantity,
      }));

      const { data } = await productsApi.post<BudgetItem>(
        '/budgets/custom-kit',
        {
          products,
          budgetId: id,
        }
      );

      const customItemId = data.budgetCustomItemId;

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits/composicao/${customItemId}${searchParams}`
      );
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data.message;

      switch (errorMessage) {
        case 'budget not found':
          toast.error('Orçamento não encontrado');
          break;

        default:
          toast.error(errorMessage);
          break;
      }
    }
  };

  const deleteCustomKit = async (kitId: string) => {
    await productsApi.delete(`/budgets/${id}/delete-custom-kit/${kitId}`);
  };

  const updateCustomKit = async () => {
    try {
      const removedKits =
        customItem?.kits.filter(
          (kit) =>
            !selectedKits.find(
              (selectedKit) => kit.product.id === selectedKit.id
            )
        ) ?? [];

      const newKits = selectedKits
        .filter(
          (selectedKit) =>
            !customItem?.kits.find((kit) => kit.product.id === selectedKit.id)
        )
        .map((kit) => ({ id: kit.id, quantity: 1 }));

      if (newKits.length > 0) {
        await productsApi.put(`/budgets/custom-item/${customItem?.id}`, {
          budgetId: id,
          products: newKits,
        });
      }

      await Promise.all(removedKits.map((kit) => deleteCustomKit(kit.id)));

      history.push(
        `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits/composicao/${customItemId}${searchParams}`
      );
    } catch (error) {
      toast.error('Desculpe, náo foi possível atualizar a composição');
    }
  };

  const handleCustomKit = async () => {
    hasEditPath && customItem
      ? await updateCustomKit()
      : await createCustomKit();
  };

  const handleKitsAlreadySelected = () => {
    if (hasEditPath) {
      const kits =
        budget?.items.flatMap(
          (item) => item.customItem?.kits?.map((kit) => kit.product) ?? []
        ) ?? [];

      setSelectedKits(kits);
    }
  };

  const fetchData = async () => {
    try {
      await getBudget(id);

      await Promise.all([getFilters(), getCustomKits()]);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    validateKits(customKits.content, budget);

    handleKitsAlreadySelected();
  }, [customKits]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>{'Personalizar Kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <Header>
          <h1>Selecionar Kits</h1>
          <p>
            Você pode selecionar vários kits para formar uma composição, desde
            que sejam do mesmo fornecedor para garantir compatibilidade e
            funcionamento adequado.
          </p>
        </Header>

        <Wrapper>
          <Table
            items={tableItems}
            columns={tableColumns}
            isLoading={customKits.loading}
            header={
              <TableHeader>
                <SearchInput
                  name="search"
                  placeholder="Digite aqui para pesquisar"
                  onTextChange={debounce(handleSearchKits, 350)}
                />

                <DynamicFilter
                  filters={filter.options}
                  onChange={handleFilters}
                />
              </TableHeader>
            }
            placeholder="Nenhum kit encontrado"
            pagination={pagination}
            onLimitChange={(limit) =>
              getCustomKits(1, limit, search, filter.selecteds)
            }
            onPageChange={(page) =>
              getCustomKits(page, pagination.limit, search, filter.selecteds)
            }
          />
        </Wrapper>

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`
              );
            }}
          />

          <Button
            text="Avançar"
            onClick={handleCustomKit}
            disabled={!hasSelectedKits}
          />
        </ButtonGroup>
      </Content>

      <KitDescriptionModal
        isOpen={hasKitDescription}
        description={kitDescription}
        onCancel={() => handleKitDescriptionModal('')}
      />
    </Container>
  );
};

export default CustomKitSelectKitsPage;
