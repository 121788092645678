import React, { useEffect, useState } from 'react';
import {
  ActionButton,
  Breadcrumb,
  Button,
  MountKitIcon,
  SelectKitIcon,
  StepProgress,
} from '../../../../components';
import { ButtonGroup, Container, PageHeader } from '../../../styles';
import {
  Actions,
  Content,
  CustomKitsContainer,
  CustomKitsWrapper,
  EmptyMessage,
  Menu,
  MenuItem,
  TableFooter,
} from './styles';
import { useHistory, useParams } from 'react-router-dom';
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';
import { SelectKitsModal } from '../../../../components/modal/custom-kits';
import { useBudgets } from '../../../../contexts/budgets';
import { KitDescriptionModal } from '../../../../components/modal/kit-description';
import { formatQuantity } from '../../../../utils/formatQuantity';
import { toast } from 'react-toastify';
import productsApi from '../../../../services/products';
import { BudgetItem } from '../../../../contexts/budgets/types';

type Modals = {
  description: string;
  selectKit: boolean;
};

const steps = [
  { key: 1, title: 'Selecionar produto', active: false, complete: true },
  { key: 2, title: 'Cadastro do cliente', active: false, complete: true },
  { key: 3, title: 'Selecionar Kits', active: false, complete: true },
  { key: 4, title: 'Personalizar kit', active: true, complete: false },
  { key: 5, title: 'Serviços', active: false, complete: false },
  { key: 6, title: 'Proposta', active: false, complete: false },
];

const CustomKitPage: React.FC = () => {
  const { getBudget } = useBudgets();

  const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([]);

  const hasCompositions = budgetItems.length > 0;

  const history = useHistory();

  const { pathname } = history.location;

  const hasEditPath = pathname.includes('editar');

  const { id } = useParams<{ id: string }>();

  const links = [
    {
      id: 1,
      title: 'Orçamentos',
      link: '/orcamentos',
      active: false,
    },
    {
      id: 2,
      title: 'Selecionar produto',
      link: '/orcamentos/selecionar-produto',
      active: false,
    },
    {
      id: 3,
      title: 'Cadastro do cliente',
      link: hasEditPath
        ? `/orcamentos/selecionar-produto/cadastro-cliente/${id}/editar`
        : `/orcamentos/selecionar-produto/cadastro-cliente`,
      active: false,
    },
    {
      id: 5,
      title: 'Personalizar kit',
      link: `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/`,
      active: true,
    },
  ];

  const [modals, setModals] = useState<Modals>({
    description: '',
    selectKit: false,
  });

  const handleModals = (modal: keyof Modals, value: string | boolean) => {
    setModals((state) => ({ ...state, [modal]: value }));
  };

  const hasKitDescription = modals.description.length > 0;

  const handleCustomKits = async () => {
    history.push(
      `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/servicos/`
    );
  };

  const deleteBudgetItem = async (budgetItemId: string) => {
    try {
      await productsApi.delete(`/budgets/${id}/${budgetItemId}/remove-item`);

      setBudgetItems((state) =>
        state.filter((item) => item.id !== budgetItemId)
      );
    } catch (error) {
      toast.error('Desculpe, não foi possível remover o kit');
    }
  };

  const handleBudgetItems = async () => {
    const { items } = await getBudget(id);

    setBudgetItems(items);
  };

  useEffect(() => {
    handleBudgetItems();
  }, []);

  return (
    <Container>
      <Breadcrumb links={links} />
      <PageHeader>
        <span>{'Personalizar Kit'}</span>
        <div>
          <StepProgress steps={steps} />
        </div>
      </PageHeader>

      <Content>
        <CustomKitsContainer>
          <header>
            <h1>Kit Personalizado</h1>

            {/* <span>
              Geração calculada: <strong>99.9 KWp</strong>
            </span> */}
          </header>

          <CustomKitsWrapper>
            {!hasCompositions && (
              <EmptyMessage>
                Nenhum kit selecionado ou montado até o momento.
              </EmptyMessage>
            )}

            {hasCompositions && (
              <table>
                <thead>
                  <tr>
                    <th>Composição</th>
                    <th>Tipo</th>
                    <th>Marca</th>
                    <th>Kits</th>
                    <th>Módulos</th>
                    <th>Valores</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {budgetItems.map((item, i) => {
                    const { customItem } = item;

                    const kitsDescription =
                      customItem?.kits
                        .map((kit) => kit.product.description)
                        .join(', ') ?? '';

                    const quantity =
                      customItem?.kits.reduce(
                        (acc, kit) => acc + kit.quantity,
                        0
                      ) ?? 0;

                    const solarPlatesNumber =
                      customItem?.solarPlatesNumber ?? 0;

                    return (
                      <tr key={item.id}>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.type}</strong>
                        </td>
                        <td>
                          <strong>{customItem?.supplier}</strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(quantity, 'Kit', 'Kits')}
                          </strong>
                        </td>
                        <td>
                          <strong>
                            {formatQuantity(
                              solarPlatesNumber,
                              'módulo',
                              'módulos'
                            )}
                          </strong>
                        </td>
                        <td>
                          <strong>Kit: {item.formatted.price}</strong>
                        </td>
                        <td>
                          <Actions>
                            <ActionButton
                              tooltip="Editar"
                              onClick={() => {
                                const filters = {
                                  fabricMaterial:
                                    customItem?.kits[0].product
                                      .fabricMaterial ?? '',
                                  supplier: customItem?.supplier ?? '',
                                };

                                const queryParams = new URLSearchParams(
                                  filters
                                ).toString();

                                history.push(
                                  `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits/composicao/${customItem?.id}/editar?${queryParams}`
                                );
                              }}
                            >
                              <FiEdit />
                            </ActionButton>
                            <ActionButton
                              tooltip="Remover"
                              onClick={() => deleteBudgetItem(item.id)}
                            >
                              <FiTrash2 className="delete-icon" />
                            </ActionButton>
                            <ActionButton
                              tooltip="Visualizar"
                              onClick={() =>
                                handleModals('description', kitsDescription)
                              }
                            >
                              <FiEye />
                            </ActionButton>
                          </Actions>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TableFooter />
              </table>
            )}
          </CustomKitsWrapper>
        </CustomKitsContainer>

        <Menu>
          <MenuItem onClick={() => handleModals('selectKit', true)}>
            <SelectKitIcon />

            <div>
              <h1>Selecionar kits</h1>

              <p>Faça uma composição usando vários kits prontos.</p>
            </div>
          </MenuItem>
          <MenuItem
          // onClick={() =>
          //   history.push(
          //     `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/montar-kit`
          //   )
          // }
          >
            <MountKitIcon />

            <div>
              <h1>Montar kit</h1>

              <p>Faça sua própria composição.</p>
            </div>
          </MenuItem>
        </Menu>

        <ButtonGroup>
          <Button
            text="Voltar"
            typeStyle="default"
            backgroundHoverColor="#C9CBCF"
            onClick={() => {
              history.push(
                `/orcamentos/selecionar-produto/cadastro-cliente/${id}/selecao-de-kits/`
              );
            }}
          />

          <Button
            text="Avançar"
            disabled={!hasCompositions}
            onClick={handleCustomKits}
          />
        </ButtonGroup>
      </Content>

      <SelectKitsModal
        isOpen={modals.selectKit}
        onCancel={() => handleModals('selectKit', false)}
        onConfirm={(query) =>
          history.push(
            `/orcamentos/selecionar-produto/cadastro-cliente/${id}/personalizar-kit/selecionar-kits?${query}`
          )
        }
      />

      <KitDescriptionModal
        isOpen={hasKitDescription}
        description={modals.description}
        onCancel={() => handleModals('description', '')}
      />
    </Container>
  );
};

export default CustomKitPage;
