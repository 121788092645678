import React from 'react';
import { ShapeModal } from '..';
import { Buttons } from '../styles';

import { Button } from '../../button';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const CancelOrderModal: React.FC<Props> = (props) => {
  const { isOpen, onCancel, onConfirm } = props;

  return (
    <ShapeModal isOpen={isOpen}>
      <h1>Cancelar pedido</h1>
      <p>Deseja realmente cancelar esse pedido?</p>
      <p>
        Esta ação não poderá ser desfeita e o integrador terá que refazer o
        pedido do início se quiser retomá-lo.
      </p>

      <Buttons>
        <Button
          type="button"
          text="Fechar"
          typeStyle="default"
          backgroundHoverColor="#C9CBCF"
          onClick={onCancel}
        />
        <Button
          type="button"
          text="Quero cancelar"
          backgroundColor="#E01919"
          backgroundHoverColor="#e01919b7"
          onClick={onConfirm}
        />
      </Buttons>
    </ShapeModal>
  );
};
