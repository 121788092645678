import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ActionButton, Breadcrumb, SearchInput } from '../../../components';
import Table, { ColumnStructure, TableData } from '../../../components/table';
import InnerActions from '../../../components/table/innerActions';
import { useAuth } from '../../../contexts/auth';
import productsApi from '../../../services/products';
import { EMPTY_PAGE, PaginatedResponse } from '../../../types/pagination';
import { Container, PageHeader } from '../../styles';
import { Content, TableHeader } from './styles';

const links = [
  {
    id: 1,
    title: 'Clientes',
    link: '/clientes',
    active: true,
  },
];

type TableItems = {
  id: string;
  name: string;
  city: string;
  email: string;
  phone: string;
  actions: JSX.Element;
};

type Address = {
  id: string;
  zipCode: string;
  street: string;
  number: number;
  neighborhood: string;
  complement: string | null;
  city: string;
  state: string;
  customerId: string;
  createdAt: string;
  updatedAt: string;
};

type Customers = {
  id: string;
  name: string;
  document: string;
  normalizedDocument: string;
  documentType: string;
  email: string;
  phone: string;
  addresses: Address[];
};

const CustomersListPage: React.FC = () => {
  const { selectedFranchise: franchiseId } = useAuth();

  const [customers, setCustomers] =
    useState<PaginatedResponse<Customers>>(EMPTY_PAGE);

  const [search, setSearch] = useState('');

  const debounceSearch = debounce(setSearch, 300);

  const tableColumns: ColumnStructure[] = [
    {
      id: 'name',
      label: 'Nome',
      onClick: () => {},
    },
    {
      id: 'city',
      label: 'Cidade',
      onClick: () => {},
    },
    {
      id: 'email',
      label: 'Email',
      onClick: () => {},
    },
    {
      id: 'phone',
      label: 'Telefone',
      onClick: () => {},
    },
    {
      id: 'actions',
      label: 'Ações',
      type: 'actionCell',
      isCentered: true,
    },
  ];

  const history = useHistory();

  const tableItems: TableData<TableItems>[] = useMemo(() => {
    return customers.content.map((customer) => {
      return {
        id: customer.id,
        name: customer.name,
        city: customer.addresses[0].city,
        email: customer.email,
        phone: customer.phone,
        actions: (
          <InnerActions>
            <ActionButton
              tooltip="Editar"
              onClick={() => {
                history.push(`/clientes/${customer.id}/editar`);
              }}
            >
              <FiEdit />
            </ActionButton>
          </InnerActions>
        ),
      };
    });
  }, [franchiseId, search, customers]);

  const getCustomers = useCallback(
    async (page: number, limit: number) => {
      if (!franchiseId) {
        return toast.info('Selecione um integrador');
      }

      setCustomers((state) => ({
        ...state,
        loading: true,
      }));

      try {
        const { data } = await productsApi.get<PaginatedResponse<Customers>>(
          `/customers/franchise/${franchiseId}`,
          {
            params: {
              page,
              limit,
              search: search || undefined,
            },
          }
        );

        setCustomers(data);
      } catch (error) {
        toast.error('Desculpe, não foi possível buscar os clientes');

        throw error;
      } finally {
        setCustomers((state) => ({
          ...state,
          loading: false,
        }));
      }
    },
    [franchiseId, search, customers]
  );

  useEffect(() => {
    getCustomers(1, 10);
  }, [franchiseId, search]);

  return (
    <Container>
      <Breadcrumb links={links} />

      <PageHeader>
        <span>{'Lista de clientes'}</span>
      </PageHeader>

      <Content>
        <Table
          items={tableItems}
          columns={tableColumns}
          isLoading={customers.loading}
          header={
            <TableHeader>
              <SearchInput
                name="search"
                placeholder="Digite aqui para pesquisar"
                onTextChange={(value) => debounceSearch(value)}
              />
            </TableHeader>
          }
          placeholder="Nenhum cliente encontrado"
          pagination={customers.pagination}
          onPageChange={(page) =>
            getCustomers(page, customers.pagination.limit)
          }
          onLimitChange={(limit) => getCustomers(1, limit)}
        />
      </Content>
    </Container>
  );
};

export default CustomersListPage;
