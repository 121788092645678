import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from '../../../components';
import { Container, Wrapper, PageHeader } from '../styles';
import { useHistory, useParams } from 'react-router-dom';
import projectsApi from '../../../services/projects';
import { Divider, FormContainer, TableContainer } from '../../styles';
import { Group, ButtonContainer, File } from './style';
import { ProjectFilesType, ProjectStatus, UploadTypes } from '../types';
import { ProjectType } from '../../../types/project';
import { useAuth } from '../../../contexts/auth';
import { UserRoles } from '../../../types/users';
import { format } from 'date-fns';
import FileUploadService from '../../../services/FileUploadService';
import { Files } from '../../../types/files';

type ProjectCallCenterPageProps = {
  id: string;
};

const ProjectCallCenterExtrasPage: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams<ProjectCallCenterPageProps>();
  const [projectFiles, setProjectFiles] = useState<ProjectFilesType[]>([]);
  const [project, setProject] = useState<ProjectType>();
  const [files, setFiles] = useState<Files[]>([]);

  const links = [
    {
      id: 1,
      title: 'Projetos',
      link: '/projetos',
      active: false,
    },
    {
      id: 2,
      title: 'Lista de Projetos',
      link: '/projetos',
      active: true,
    },
  ];

  useEffect(() => {
    loadFiles();
  }, []);

  const loadFiles = () => {
    FileUploadService.getFiles(id, '').then((res) => {
      const normalizied = res.data.map((file: any) => ({
        id: file.id,
        name: file.originalName,
        url: file.path,
        type: file.type,
        file_url: file.file_url,
      }));
      setFiles(normalizied);
    });
  };

  const getFiles = (type: string) => {
    return files.filter((file) => file.type === type);
  };

  useEffect(() => {
    projectsApi
      .get<ProjectFilesType[]>(`/api/projects/${id}/file?type=`)
      .then((res) => {
        setProjectFiles(res.data);
      });
    projectsApi.get<ProjectType>(`/api/projects/${id}`).then((res) => {
      setProject(res.data);
    });
  }, [id]);

  return (
    <Container>
      <Breadcrumb links={links} />
      <Wrapper>
        <PageHeader>
          <span>Documentos Extras</span>
        </PageHeader>
        <FormContainer>
          <Group>
            <span>
              Contas de unidade beneficiárias, ART de execução,
              Contrato/Estatuto social e etc.
            </span>
            <div className="project-files">
              {projectFiles
                .filter((file) => file.type === UploadTypes.DOCUMENTOS_EXTRAS)
                .map((file) => (
                  <File key={file.id} href={file.file_url} target="_blank">
                    {file.originalName}
                  </File>
                ))}
            </div>
          </Group>
          <Divider />
          <Group>
            <span>
              Realizar compensação? {project?.compensation ? 'Sim' : 'Não'}
            </span>
          </Group>
          <Group>
            <span>Observações do Integrador: </span>
            <p>{project?.observations}</p>
          </Group>
          <Divider />
          <Group>
            <span>Observações da central: </span>
            <TableContainer>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Data</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.documentationAnalysis?.map(
                      (projectDocumentationAnalysis) => (
                        <tr key={projectDocumentationAnalysis.id}>
                          <td>{projectDocumentationAnalysis.status}</td>
                          <td>
                            {format(
                              new Date(projectDocumentationAnalysis.createdAt),
                              'dd/MM/yyyy HH:mm'
                            )}
                          </td>
                          <td>{projectDocumentationAnalysis.observation}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Group>
          <Divider />
          <Group>
            <span>Parecer da concessionária: </span>
            <div className="mb-16">
              {getFiles(UploadTypes.PARECER_CONCESSIONARIA).map((file) => (
                <File key={file.id}>
                  <a href={file.file_url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                </File>
              ))}
            </div>
            <TableContainer>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Data</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.powerCompanyFeedback?.map((feedback) => (
                      <tr key={feedback.id}>
                        <td>{feedback.status}</td>
                        <td>
                          {format(
                            new Date(feedback.createdAt),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </td>
                        <td>{feedback.observation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Group>
          <Divider />
          <Group>
            <span>Vistorias: </span>
            <TableContainer>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>Data</th>
                      <th>Observação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project?.projectInspections?.map((feedback) => (
                      <tr key={feedback.id}>
                        <td>{feedback.status}</td>
                        <td>
                          {format(
                            new Date(feedback.createdAt),
                            'dd/MM/yyyy HH:mm'
                          )}
                        </td>
                        <td>{feedback.observation}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TableContainer>
          </Group>
          <Divider />
          <ButtonContainer>
            <div>
              <Button
                text="Voltar"
                backgroundColor="#DFE0E2"
                onClick={() => history.push(`/projetos/${id}/arquivos`)}
              />
            </div>
            {user.role === UserRoles.CENTRAL_ATENDIMENTO &&
              project?.status === ProjectStatus.DOCUMENTACAO_EM_ANALISE && (
                <div>
                  <Button
                    text="Analisar documentação"
                    backgroundColor="#FF5E13"
                    onClick={() =>
                      history.push(`/projetos/${id}/analise-documentacao`)
                    }
                  />
                </div>
              )}
          </ButtonContainer>
        </FormContainer>
      </Wrapper>
    </Container>
  );
};

export default ProjectCallCenterExtrasPage;
