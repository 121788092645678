import styled from 'styled-components';

export const Content = styled.div`
  border: 1px solid red;

  padding: 3rem;

  border-radius: 0.5rem;
  border: 1px solid #dfe0e2;
  background: #fff;
`;

export const Wrapper = styled.fieldset`
  display: flex;
  flex-direction: column;

  max-width: 63rem;

  & > legend {
    color: #27282b;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.25rem;
    font-weight: 600;

    margin-bottom: 3rem;
  }

  & .text-area {
    margin: 2rem 0 2.5rem;
  }
`;

type GridProps = {
  columns?: number;
};

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.columns ?? 3}, minmax(0, 1fr))`};
  column-gap: 1.5rem;
  row-gap: 2.5rem;

  @media (max-width: 992px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const Divisor = styled.hr`
  margin: 4rem 0;

  border: 1px solid #dfe0e2;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & > label {
    font-size: 1rem;
    font-weight: 500;
    color: #27282b;
  }

  & > div {
    display: flex;
    gap: 11.5rem;

    @media (max-width: 1295px) {
      flex-direction: column;
      gap: 4.5rem;
    }
  }

  & > span {
    color: #e01919;

    font-weight: 500;

    font-size: 0.875rem;
  }
`;

type InputFileProps = {
  hasValue: boolean;
};

export const InputFile = styled.div<InputFileProps>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  grid-column: ${(props) => (props.hasValue ? 'span 2' : 'auto')};

  @media (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    grid-column: auto;
  }

  margin-top: 1.25rem;

  & > div {
    max-width: 20rem;
    width: 100%;
    height: 3rem;
    border-radius: 0.5rem;
    background: #dfe0e2;
    cursor: pointer;

    & > p {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
      color: #27282b;
      text-align: center;
      font-family: 'Montserrat', sans-serif;
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

export const FileItem = styled.a`
  display: flex;
  align-items: center;

  max-width: 20rem;
  width: 100%;
  height: 3rem;

  border-radius: 8px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  position: relative;

  & > div {
    display: flex;
    align-items: center;

    border-radius: 8px;

    font-weight: 600;
    font-size: 1rem;

    color: #27282b;

    padding: 0.75rem 1rem;

    width: 100%;
    height: 100%;

    background: #dfe0e2;

    position: relative;

    z-index: 4;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      max-width: 12.5rem;
    }
  }

  & > div::before {
    position: absolute;

    content: '';
    top: 0;
    left: 0;

    height: 100%;

    z-index: -1;

    background-color: #7ed56b;

    animation: uploadProgress 2s ease-in-out both;

    border-radius: 4px;

    @keyframes uploadProgress {
      0% {
        width: 0%;
      }

      100% {
        width: 100%;
      }
    }
  }

  & > button {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background: #131415;

    position: absolute;
    right: 1rem;

    z-index: 5;

    width: 1.25rem;
    height: 1.25rem;

    & > svg {
      color: black;

      & > path {
        stroke: #ffffff;
      }
    }
  }
`;

