import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ShapeModal } from '..';
import { Button } from '../../button';
import Checkbox from '../../checkbox';
import { Buttons } from '../styles';
import { Content } from './styles';

type Props = {
  onConfirm: () => void;
  isOpen: boolean;
};

type Form = {
  acceptTerms: boolean;
};

const schema = yup.object().shape({
  acceptTerms: yup
    .boolean()
    .required()
    .oneOf([true], 'Você precisa concordar com os termos de uso.'),
});

export const AcceptTermsModal: React.FC<Props> = (props) => {
  const { onConfirm, isOpen } = props;

  const { control, handleSubmit, setValue, watch } = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      acceptTerms: false,
    },
  });

  const isValid = watch('acceptTerms');

  const onSubmit = (data: Form) => {
    console.log('data => ', data);
  };

  const checkIfHasScrolledToBottom = (
    e: React.UIEvent<HTMLElement, globalThis.UIEvent>
  ) => {
    if (e.target instanceof HTMLElement) {
      const hasScrolledToBottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

      if (hasScrolledToBottom) {
        setValue('acceptTerms', true);
      }
    }
  };

  return (
    <ShapeModal
      isOpen={isOpen}
      onRequestClose={onConfirm}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Content onSubmit={handleSubmit(onSubmit)}>
        <h1>TERMOS E CONDIÇÕES GERAIS DE USO</h1>
        <section onScroll={checkIfHasScrolledToBottom}>
          <p>
            Este Termo e Condições Gerais de Uso (“Termo”), é um acordo legal
            entre pessoa física ou jurídica, doravante denominado INTEGRADOR, e
            F5C NEGOCIOS E MEIOS DE PAGAMENTOS LTDA. (“ITISUN”), pessoa jurídica
            de direito privado, com sede na Avenida Queiroz Filho, nº 1560,
            conjunto 12C, Vila Hamburguesa, São Paulo/SP, CEP: 05319-000,
            inscrita no CNPJ/MF sob o nº 43.683.286/0001-60; doravante
            denominada ITISUN, para uso da Plataforma ITISUN (“PLATAFORMA”). Ao
            utilizar a PLATAFORMA, mesmo que parcialmente ou em caráter de
            teste, o INTEGRADOR estará vinculado aos termos deste Contrato,
            concordando com eles, principalmente no que diz respeito ao
            CONSENTIMENTO COM O ACESSO, COLETA, USO, ARMAZENAMENTO, TRATAMENTO E
            TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do INTEGRADOR para a integral
            execução das funcionalidades ofertadas pela PLATAFORMA. No caso de
            discordância dos termos aqui apresentados, a utilização da
            PLATAFORMA deve ser imediatamente interrompida.
          </p>
          <h2>1. DAS DEFINIÇÕES</h2>
          <p className="spacing">
            1.1. Os termos utilizados neste Termo de Uso deverão ser
            interpretados e usados conforme definições abaixo:
          </p>
          <ul>
            <li>
              1.1.1. PORTAL: site na internet em que a PLATAFORMA se encontra
              disponibilizado (www.itisun.com.br).
            </li>
            <li>
              1.1.2. INFORMAÇÕES DE CONTA: informações e dados relativos ao
              cadastramento e completa identificação do INTEGRADOR, podendo
              serem compostas, mas sem limite, de NOME ou RAZÃO SOCIAL, NÚMERO
              DE TELEFONE, E-MAIL, CPF, CNPJ, DADOS DO REPRESENTANTE LEGAL,
              entre outros, bem como os dados de LOGIN e SENHA DE ACESSO do
              INTEGRADOR à PLATAFORMA.
            </li>
            <li>
              1.1.3. INFORMAÇÕES FINANCEIRAS: informações fornecidas pelo
              INTEGRADOR e que possuam algum caráter financeiro, tais como
              BANCO, AGÊNCIA e NÚMERO DE CONTA CORRENTE.
            </li>
            <li>
              1.1.4. CLIENTES: Adquirentes de produtos que tenham se utilizado
              dos serviços do ITISUN por intermédio de um INTEGRADOR para
              realização da aquisição.
            </li>
            <li>
              1.1.5. INFORMAÇÕES DO CLIENTE: qualquer informação disponibilizada
              pelo CLIENTE ao INTEGRADOR, tal como, mas sem limite, NOME,
              ENDEREÇO, CONTA DE LUZ, DATA DE NASCIMENTO, NÚMERO DE TELEFONE,
              E-MAIL, SERVIÇOS CONTRATADOS, PRODUTOS ADQUIRIDOS, INTERESSES,
              OPINIÕES, PROFISSIONAIS SELECIONADOS, VALOR DAS INTEGRAÇÕES,
              DATAS, entre outros.
            </li>
            <li>
              1.1.6. INFORMAÇÕES DO PROFISSIONAL: informações do INTEGRADOR.
            </li>

            <li>
              1.1.7. INFORMAÇÕES: todos os tipos de informação que possuam
              definição e constem nesta cláusula.
            </li>

            <li>
              1.1.8. INTEGRADOR: pessoa física ou jurídica, com plena capacidade
              de contratar, que acessa a PLATAFORMA da ITISUN por meio do
              Portal, realizando seu cadastro, aceitando os termos do presente
              Contrato e usufruindo das funcionalidades oferecidas pela
              PLATAFORMA.
            </li>

            <li>
              1.1.9. PLATAFORMA: software de propriedade exclusiva da ITISUN,
              cujas funcionalidades e serviços estão disponibilizados pelo
              Portal, por meio do qual as informações do INTEGRADOR serão
              fornecidas diretamente por ele.
            </li>
          </ul>
          <h2>2. DO OBJETO</h2>

          <p className="spacing">
            2.1. O objeto da presente contratação é a concessão, ao INTEGRADOR,
            de uma licença revogável, não exclusiva e intransferível para usar a
            PLATAFORMA, completamente sujeita aos termos e condições do presente
            Termo de Uso.
          </p>

          <p className="spacing">
            2.2. O INTEGRADOR não poderá utilizar e nem permitir o uso da
            PLATAFORMA para uma outra finalidade que não seja o processamento de
            suas informações, bem como a de seus CLIENTES, observados os limites
            estabelecidos neste Termo de Uso.
          </p>

          <p className="spacing">
            3.3. Esta licença não implica na capacidade de acessar outros
            softwares além daqueles originalmente localizados na PLATAFORMA. Em
            nenhuma hipótese o INTEGRADOR terá acesso ao código fonte da
            PLATAFORMA, por este se tratar de propriedade intelectual da ITISUN.
          </p>

          <p className="spacing">
            3.4. A ITISUN poderá ofertar ao INTEGRADOR novos serviços ou novas
            modalidades dos serviços existentes, inclusive de forma remota ou
            eletrônica, sendo que sua contratação será concretizada a partir do
            uso do serviço e/ou aceitação dos termos e condições aplicáveis.
          </p>

          <h2> 4. DA PROPRIEDADE INTELECTUAL</h2>

          <p className="spacing">
            4.1. O INTEGRADOR não adquire nenhum direito de propriedade
            intelectual ou outros direitos exclusivos, incluindo patentes,
            desenhos, marcas, direitos autorais, direitos sobre informações
            confidenciais ou segredos de negócio, sobre ou relacionado à
            PLATAFORMA ou qualquer parte dela.
          </p>

          <p className="spacing">
            4.2. O INTEGRADOR também não adquire nenhum direito sobre ou
            relacionado à PLATAFORMA ou qualquer parte dela, além dos direitos a
            ele expressamente licenciados sob o presente Termo de Uso ou em
            qualquer outro Contrato mutuamente acordado por escrito que o
            INTEGRADOR possa ter celebrado com a ITISUN.
          </p>

          <p className="spacing">
            4.3. Quaisquer direitos não expressamente concedidos no presente
            Termo de Uso são reservados, bem como será de propriedade exclusiva
            da ITISUN todo o conteúdo disponibilizado no Portal, incluindo, mas
            não se limitando, textos, gráficos, imagens, logos, ícones,
            fotografias, conteúdo editorial, notificações, softwares e qualquer
            outro tipo de material.
          </p>

          <h2>6. DAS RESTRIÇÕES</h2>

          <p className="spacing">
            6.1. Em hipótese alguma é permitido ao INTEGRADOR ou a terceiros, de
            forma geral: a{')'} copiar, ceder, sublicenciar, vender, dar em
            locação ou em garantia, reproduzir, doar, alienar de qualquer forma,
            transferir total ou parcialmente, sob quaisquer modalidades,
            gratuita ou onerosamente, provisória ou permanentemente, o software
            objeto deste contrato, assim como seus módulos, partes, manuais ou
            quaisquer informações relativas a ele; b{')'} retirar ou alterar,
            total ou parcialmente, os avisos de reserva de direito existente na
            PLATAFORMA e na documentação; c{')'} praticar de engenharia reversa,
            descompilação ou desmontagem da PLATAFORMA.
          </p>

          <h2>
            7. DA PROPRIEDADE, USO E RESTITUIÇÃO DAS INFORMAÇÕES DO CLIENTE
          </h2>

          <p className="spacing">
            7.1. As INFORMAÇÕES DO CLIENTE serão de propriedade conjunta da
            ITISUN e do INTEGRADOR, que poderão delas dispor da forma que lhes
            convier.
          </p>

          <p className="spacing spacing-left">
            7.1.1. Em nenhuma hipótese a ITISUN repassará ao INTEGRADOR
            INFORMAÇÕES DO CLIENTE de outros INTEGRADORES.
          </p>

          <p className="spacing">
            7.2. Suspenso o acesso à PLATAFORMA ou rescindido o Contrato, a
            ITISUN manterá as INFORMAÇÕES DO CLIENTE lançadas na PLATAFORMA pelo
            período de 180 (cento e oitenta) dias, contados da suspensão de
            acesso ou data de rescisão. Neste período, a ITISUN poderá extrair,
            se assim o desejar, as INFORMAÇÕES DO CLIENTE.
          </p>

          <p className="spacing">
            7.3. Decorridos 180 (cento e oitenta) dias citados na cláusula 7.2,
            as INFORMAÇÕES DO CLIENTE ficarão permanentemente indisponíveis, não
            sendo mais passível de consulta, extração ou cópia, em nenhuma
            hipótese.
          </p>

          <h2>8. DAS OBRIGAÇÕES DO INTEGRADOR</h2>

          <p className="spacing">8.1. Obriga-se o INTEGRADOR a:</p>

          <ul>
            <li>
              8.1.1. Responder pelas informações inseridas na PLATAFORMA, em
              hipótese alguma a ITISUN será responsável pelo conteúdo
              (informações, senhas, cópias de informações etc.), não sendo,
              portanto, essas informações revisadas em momento algum. A
              responsabilidade pelas informações inseridas na PLATAFORMA será
              sempre do INTEGRADOR;
            </li>

            <li>
              8.1.2. Certificar-se de que não está proibido por determinação
              legal e/ou contratual de prestar informações, sejam elas pessoais
              ou de qualquer outra espécie, bem como quaisquer outros dados à
              ITISUN;
            </li>

            <li>
              8.1.3. Não utilizar a PLATAFORMA de qualquer forma que possa
              implicar em ilícito, infração, violação de direitos ou danos à
              ITISUN, bem como a terceiros;
            </li>

            <li>
              8.1.4. Não publicar, enviar ou transmitir, por qualquer meio
              existente ou que venha a ser disponibilizado, qualquer arquivo que
              contenha vírus, worms, cavalos de troia ou qualquer outro programa
              que possa contaminar, destruir ou interferir no om funcionamento
              da PLATAFORMA;
            </li>

            <li>
              8.1.5. Informar a ITISUN sempre que houver qualquer alteração das
              INFORMAÇÕES que possam impedir, limitar ou prejudicar a execução
              das funcionalidades ofertadas pela PLATAFORMA;
            </li>

            <li>
              8.1.6. Caso o INTEGRADOR acredite que seu login e senha de acesso
              ao Portal tenham sido roubados ou sejam de conhecimento de outras
              pessoas, por qualquer razão, deverá imediatamente comunicar a
              ITISUN, sem prejuízo da alteração de senha imediata por meio do
              Portal;
            </li>

            <li>
              8.1.7. Responsabilizar-se integral e exclusivamente pelas relações
              jurídicas existentes com seus profissionais, incluindo, mas sem
              limite, pagamento de eventuais comissões acordadas, direito de
              imagem, responsabilidade civil e penal quanto às atividades
              exercidas etc.;
            </li>

            <li>
              8.1.8. Manter todas as informações a que tiver acesso em completo
              sigilo;
            </li>

            <li>
              8.1.9. Utilizar-se das funcionalidades oferecidas pela PLATAFORMA
              em estrita conformidade com a legislação vigente e aplicável à
              atividade exercida, sendo, pois, o único e exclusivo responsável
              pela licitude e adequação de suas atividades;
            </li>

            <li>
              8.1.10. Observar e fazer com que os seus profissionais cumpram as
              políticas, normas e padrões estabelecidos, obrigando-se, por si e
              pelos seus profissionais, a atender as normas pertinentes à
              segurança, meio ambiente, higiene e medicina do trabalho e do
              trabalho do menor;
            </li>

            <li>
              8.1.11. Reconhecer que todos os acessos realizados por meio de sua
              Conta de Acesso na PLATAFORMA são interpretados como tendo sido
              feitos pelo próprio INTEGRADOR e/ou seus responsáveis legais de
              forma incontestável e de sua integral responsabilidade;
            </li>

            <li>
              8.1.12. Dispor de dispositivos e equipamentos tecnológicos,
              serviços de conexão à Internet, com antivírus e firewall
              habilitados, softwares devidamente atualizados, além da adoção de
              medidas de segurança mínimas para acesso à PLATAFORMA;
            </li>

            <li>
              8.1.13. Deixar seus sistemas de antispam, filtros similares ou
              configurações de redirecionamento de mensagens ajustados de modo
              que não interfiram no recebimento dos comunicados e materiais da
              Plataforma, não sendo aceitável nenhuma escusa caso não tenha tido
              acesso a algum e-mail ou mensagem eletrônica em virtude dos
              recursos mencionados;
            </li>

            <li>
              8.1.14. Na incidência de danos à ITISUN ou a terceiros, arcar com
              todas as obrigações de indenizar o sujeito lesado, inclusive danos
              decorrentes de atos praticados por meio da Conta de Acesso,
              assumindo o polo passivo de ação judicial ou procedimento
              administrativo e requerendo a exclusão da ITISUN, devendo arcar
              totalmente com as despesas e custas processuais atinentes,
              deixando-a livre de prejuízos e ônus, indenizando a ITISUN pelos
              prejuízos por ela suportados.
            </li>

            <li>
              8.1.15. As funcionalidades da PLATAFORMA são oferecidas na forma
              de licença de uso, não conferindo ao INTEGRADOR nenhum direito
              sobre o software utilizado pela ITISUN ou sobre suas estruturas de
              informática que o sustentam. Os INTEGRADORES reconhecem que a
              PLATAFORMA é uma ferramenta auxiliar para sua prestação dos
              serviços e de seus profissionais, não sendo essencial para o
              desenvolvimento de suas atividades econômicas.
            </li>
          </ul>

          <h2>9. DAS OBRIGAÇÕES DA ITISUN </h2>

          <p className="spacing"> 9.1. Obriga-se a ITISUN a: </p>

          <ul>
            <li>
              9.1.1. A ITISUN garante ao INTEGRADOR que a PLATAFORMA deverá
              funcionar regularmente, se respeitadas as condições de uso
              definidas na documentação. Na ocorrência de falhas de programação
              (“bugs”), a ITISUN fará a correção, podendo, a seu critério,
              atualizar a versão da PLATAFORMA;
            </li>

            <li>9.1.2. Fornecer acesso à PLATAFORMA; </li>

            <li>
              9.1.3. Suspender o acesso do INTEGRADOR à PLATAFORMA que esteja
              desrespeitando as regras de conteúdo aqui estabelecidas ou as
              normas legais em vigor;
            </li>

            <li>
              9.1.4. Alterar as especificações e/ou características da
              PLATAFORMA para a melhoria e/ou correções de erros;
            </li>

            <li>
              9.1.5. Disponibilizar acesso aos serviços de suporte compreendido
              das 9h às 12h e das 14h às 17h (pelo horário de Brasília) via
              chat, telefone ou através de correio eletrônico, nos dias úteis,
              de segunda a sexta-feira, excetuando-se, desse modo, sábados,
              domingos e feriados, para esclarecimento de dúvidas de ordem não
              funcional e diretamente relacionadas a problemas na PLATAFORMA;
            </li>

            <li>
              9.1.6. Manter todas as informações a que tiver acesso em completo
              sigilo.
            </li>
          </ul>

          <h2> 10. ISENÇÃO DE RESPONSABILIDADE DA ITISUN </h2>

          <p className="spacing">
            10.1. A ITISUN emprega seus melhores esforços para informar, atender
            e proteger o INTEGRADOR. O INTEGRADOR é o único responsável pela
            utilização da PLATAFORMA, de suas ferramentas e funcionalidades. Em
            nenhuma hipótese, a ITISUN será responsabilizada por qualquer dano
            emergente, indireto, punitivo ou expiatório, lucros cessantes ou
            outros prejuízos monetários relacionados a qualquer reclamação, ação
            judicial ou outro procedimento tomado em relação à utilização da
            PLATAFORMA, seu conteúdo, funcionalidades e/ou ferramentas. Fica
            sumariamente excluída a responsabilidade da ITISUN sobre as
            seguintes circunstâncias, entre outras:
          </p>

          <ul>
            <li>
              10.1.1. Danos e prejuízos que o INTEGRADOR possa experimentar pela
              indisponibilidade ou funcionamento parcial da PLATAFORMA e/ou de
              todos ou alguns de seus serviços, informações, conteúdos,
              funcionalidade e/ou ferramentas, bem como pela incorreção ou
              inexatidão de qualquer destes elementos;
            </li>

            <li>10.1.2. indisponibilidade do INTEGRADOR;</li>

            <li>
              10.1.3. Danos e prejuízos que o INTEGRADOR possa experimentar em
              outros sites de internet acessíveis por links incluídos na
              PLATAFORMA;
            </li>

            <li>
              10.1.4. Danos e prejuízos que o INTEGRADOR possa experimentar em
              decorrência de falhas na PLATAFORMA, inclusive decorrentes de
              falhas no sistema, no servidor ou na conexão de rede, ou ainda de
              interações maliciosas como vírus, softwares que possam danificar o
              equipamento ou acessar informações do equipamento do INTEGRADOR;
            </li>

            <li>
              10.1.5. Danos e prejuízos que o USUÁRIO possa experimentar em
              decorrência do uso da PLATAFORMA em desconformidade com este Termo
              de Uso, Políticas, Códigos, Condições e Contratos;
            </li>

            <li>
              10.1.6. Danos e prejuízos que o INTEGRADOR possa experimentar em
              decorrência do uso da PLATAFORMA em desconformidade com a lei, com
              os costumes ou com a ordem pública.
            </li>

            <li>
              10.1.7. Danos ou prejuízos decorrentes de decisões
              administrativas, gerenciais ou comerciais tomadas com base nas
              informações fornecidas pela PLATAFORMA;
            </li>

            <li>
              10.1.8. Por problemas definidos como “caso fortuito” ou “força
              maior” contemplados pelo Art. 393, do Código Civil Brasileiro;
            </li>

            <li>
              10.1.9. Problemas oriundos de ações de terceiros que possam
              interferir na qualidade do serviço.
            </li>
          </ul>

          <p className="spacing">
            10.2. Na extensão máxima permitida pela lei em vigor, a PLATAFORMA é
            fornecida “no estado em que se encontra” e “conforme a
            disponibilidade”, com todas as falhas e sem garantia de qualquer
            espécie.
          </p>

          <p className="spacing">
            10.3. A ITISUN não garante que as funções contidas na PLATAFORMA
            atendam às suas necessidades, que a operação da PLATAFORMA será
            ininterrupta ou livre de erros, que qualquer serviço continuará
            disponível, que os defeitos na PLATAFORMA serão corrigidos ou que o
            PLATAFORMA será compatível ou funcione com qualquer software,
            aplicações ou serviços de terceiros.
          </p>

          <h2>11. DA VENDA DE EQUIPAMENTOS E GARANTIA </h2>

          <p className="spacing">
            11.1. A estrutura do negócio é sustentada por uma base comercial
            triangular, que envolve a ITISUN o INTEGRADOR e o CLIENTE.
          </p>

          <p className="spacing">
            11.2. A ITISUN figura como fornecedora do produto, respondendo
            apenas e tão somente por ele e nos limites legais daí decorrentes,
            ressalvada a responsabilidade do fabricante.
          </p>

          <p className="spacing">
            11.3. A ITISUN não se responsabiliza por quaisquer informações
            contidas nas propostas geradas e sob gestão do INTEGRADOR.
          </p>

          <p className="spacing">
            11.4. O INTEGRADOR figura como prestador de serviços, respondendo
            pela instalação do produto, sendo total, integral e isoladamente sua
            a responsabilidade pela aferição de viabilidade do projeto,
            solicitação de todas as autorizações necessárias, atendimento a
            regulamentação, normas e leis para manuseio e instalação do produto,
            manutenção do sistema ou qualquer outra necessidade vinculada ao
            fiel desempenho dos produtos.
          </p>

          <p className="spacing">
            11.5. O CLIENTE figura como consumidor e reportará suas necessidades
            diretamente com o INTEGRADOR, responsável direto e imediato para com
            a relação comercial, ficando o INTEGRADOR responsável por firmar o
            competente contrato de prestação de serviço de forma individual com
            o CLIENTE, isoladamente da ITISUN, que nenhuma relação guardará com
            este contrato.
          </p>

          <p className="spacing">
            11.6. Todos os produtos comercializados através da PLATAFORMA ITISUN
            têm sua garantia explicitada pelo Fabricante, o qual garante o
            CLIENTE contra defeitos de fabricação, ocorridos durante seu uso
            CORRETO, pelos prazos estipulados nos certificados. Na inexistência
            de garantia do fabricante a ITISUN garante o produto pelo prazo
            MÁXIMO de três meses, a partir da data da emissão da Nota Fiscal da
            ITISUN.
          </p>

          <p className="spacing">
            11.7. Ocorrerá PERDA AUTOMÁTICA DE GARANTIA quando forem executados
            reparos por postos não autorizados ou credenciados; em caso de
            falhas por mau uso, imperícia, negligência, acidentes, quedas,
            incêndios, surtos de energia, descargas elétricas e similares, pela
            utilização de acessórios inadequados ou outros.
          </p>

          <p className="spacing">
            11.8. O INTEGRADOR e/ou CLIENTE poderão verificar, nos manuais ou
            nos SITES próprios, onde constam os locais de assistência técnica ou
            postos autorizados. Estes poderão sanar os problemas com maior
            rapidez e menor custo. O próprio CLIENTE poderá encaminhar o produto
            para conserto em postos autorizados.
          </p>

          <h2>12. DAS ATUALIZAÇÕES DA LEGISLAÇÃO VIGENTE </h2>

          <p className="spacing">
            12.1. A ITISUN não se responsabiliza por mudança legislativa e/ou
            quaisquer mudança regulatória que vier a contribuir para novos
            cenários do Mercado de energia fotovoltaica;
          </p>

          <p className="spacing">
            12.2. A ITISUN não se responsabiliza por quaisquer mudanças
            regulatórias que podem vir a existir perante os equipamentos
            fotovoltaicos;
          </p>

          <p className="spacing">
            12.3. É de responsabilidade dos INTEGRADORES todo e qualquer
            informação à respeito da legitimidade e regulamentação dos
            equipamentos vendidos e/ou instalados;
          </p>

          <h2>13. DO PAGAMENTO E RECEBIMENTO DOS EQUIPAMENTOS</h2>

          <p className="spacing">
            13.1. Os pagamentos realizados com cartão de crédito estão sujeitos
            à aprovação da administradora do cartão, a entrega dos equipamentos
            será liberada pelo distribuidor/fabricante a partir da checagem dos
            dados cadastrais e da confirmação do pagamento, de acordo com o
            prazo da administradora do cartão.
          </p>

          <p className="spacing">
            13.2. Os pagamentos realizados através de boleto bancário ou PIX
            serão considerados válidos a partir da confirmação do pagamento pelo
            fabricante/distribuidor.
          </p>

          <p className="spacing">
            13.3. Os pedidos somente são liberados para separação e posterior
            envio dos produtos após a confirmação do pagamento.
          </p>

          <p className="spacing">
            13.4. A ITISUN recomenda que os INTEGRADORES tomem todas as
            precauções necessárias para garantirem que a informação acerca do
            local de entrega esteja correta.
          </p>

          <p className="spacing">
            13.5. Em caso de entrega em local errado em razão de informações
            erradas repassadas pelo INTEGRADOR, o valor do frete, para entrega
            no local correto, será de responsabilidade exclusiva do INTEGRADOR
            responsável pela inserção dos dados na Plataforma.
          </p>

          <p className="spacing">
            13.6. A ITISUN fará a intermediação, entre distribuidor/fabricante e
            INTEGRADOR, de modo que os equipamentos possam ser entregues no
            tempo constante na oferta ou informados no ato da contratação.
          </p>

          <p className="spacing">
            13.7. A ITISUN, como intermediadora, não se responsabiliza pelo
            prazo e entrega dos equipamentos. No entanto, empenhará esforços
            para que a entrega e os prazos sejam respeitados.
          </p>

          <h2> 14. POLÍTICA DE TROCA E DEVOLUÇÃO.</h2>

          <p className="spacing">
            14.1. O CLIENTE poderão devolver o produto adquirido em até 7 (sete)
            dias corridos contados do seu recebimento, desde que este mantenha
            as condições da entrega, isto é, esteja na embalagem original, sem
            indícios de uso, sem violação do lacre original do fabricante,
            acompanhados de nota fiscal, manual e todos os demais acessórios que
            o acompanham.
          </p>

          <p className="spacing">
            14.2. O CLIENTE que se arrepender deverá entrar em contato com a
            ITISUN, para que por intermédio desta, o distribuidor/fabricante
            realize o estorno dos valores despendidos, ou o cancelamento de
            qualquer ordem de pagamento em até 30 dias após a confirmação da
            devolução dos produtos ao distribuidor/fabricante.
          </p>

          <p className="spacing">
            14.3. Decorrido o prazo de 07 dias o CLIENTE não poderá mais
            devolver o produto, os valores pagos não serão restituídos e os
            pendentes, serão devidos.
          </p>

          <p className="spacing">
            14.4. Caso o produto apresente problemas ou defeitos efetivamente
            comprovados dentro do prazo de 30 (trinta) dias contados do
            recebimento, o CLIENTE poderá solicitar a troca do equipamento
            adquirido ou a devolução do produto e dos valores pagos. Para tanto,
            o CLIENTE deverá informar o distribuidor/fabricante, dentro do
            referido prazo e portando a nota fiscal de compra que acompanha o
            produto para que seja dado início às providências necessárias.
          </p>

          <p className="spacing">
            14.5. Caberá ao distribuidor/fabricante determinar a melhor forma
            para averiguar se houve de fato defeito na fabricação do produto
            adquirido pelo CLIENTE. Constatado o defeito, o CLIENTE poderá optar
            entre o reparo/troca do equipamento (o que for necessário para o seu
            funcionamento adequado) ou a sua devolução e o estorno do valor
            despendido.
          </p>

          <p className="spacing">
            14.6. Caso comprovado o defeito, O distribuidor/fabricante efetuará
            os procedimentos necessários para troca ou reparo do equipamento.
            Ainda, caso o CLIENTE opte por devolver o produto, terá os valores
            restituídos em até 30 dias, contados da notificação ao
            distribuidor/fabricante.
          </p>

          <p className="spacing">
            14.7. Caso o produto apresente defeito que se comprove ser
            decorrente do mal-uso, o CLIENTE não terá direito à reparo/troca ou
            devolução.
          </p>

          <h2> 15. DA INSTALAÇÃO DOS EQUIPAMENTO E DO CRONOGRAMA DE OBRA </h2>

          <p className="spacing">
            15.1. O INTEGRADOR é o único e exclusivo responsável pelo cronograma
            de obra ajustado com o CLIENTE.
          </p>

          <p className="spacing">
            15.2. INTEGRADOR se responsabiliza, total e integralmente, pela
            instalação dos produtos adquiridos via plataforma ITISUN.
          </p>

          <h2> 16. DA CONFIDENCIALIDADE </h2>

          <p className="spacing">
            16.1. Ambas as Partes, por si, seus funcionários, contratados,
            subcontratados ou prepostos, deverão manter o mais completo e
            absoluto sigilo sobre todas as INFORMAÇÕES e dados que tiverem
            acesso no decorrer do presente Contrato, obrigando-se a adotar todas
            as medidas de segurança e praticar todos os atos necessários para
            salvaguardar e proteger as INFORMAÇÕES contra o uso ou divulgação
            não autorizada, durante todo o prazo de vigência contratual e por
            mais 05 (cinco) anos após o término do Contrato.
          </p>

          <p className="spacing">
            16.2. São consideradas confidenciais todas e quaisquer informações
            que qualquer uma das partes fornecer, revelar ou comunicar,
            independente do meio utilizado para a transmissão da informação, ou,
            ainda, aquelas a que a parte, por qualquer meio, tenha tido acesso e
            que, em relação aos negócios da outra parte, sejam de importância
            tática ou estratégica.
          </p>

          <p className="spacing">
            16.3. A ITISUN respeitará a privacidade e confidencialidade dos
            dados do INTEGRADOR armazenados em seus servidores, comprometendo-se
            a não editar, acessar ou divulgar dados, salvo nas seguintes
            hipóteses: (i) em cumprimento a ordem judicial emanada dos órgãos
            públicos competentes ou exigências da lei. Neste caso a ITISUN
            compromete-se a informar de imediato ao INTEGRADOR, para que a esta
            seja dada a oportunidade de defesa e mitigação dos efeitos da
            divulgação; e, (ii) para identificar e/ou resolver problemas
            técnicos que possam comprometer o serviço prestado.
          </p>

          <p className="spacing">
            16.4. As Partes obrigam-se a manter absoluto sigilo e
            confidencialidade sobre todas as informações relativas à outra,
            incluindo, mas não se limitando a, informações contratuais,
            transações comerciais, dados de CLIENTES, entre outras, sejam elas
            classificadas como confidenciais ou não, respondendo inclusive por
            atos de seus funcionários ou terceiros, sob pena de suportar todos
            os prejuízos ocasionados à Parte inocente e a terceiros
            prejudicados.
          </p>

          <p className="spacing">
            16.5. A ITISUN se responsabiliza por empregar sistemas de segurança
            usualmente adotados pelo mercado para proteger as Informações contra
            acessos desautorizados, mas não garante a ausência de erros, fraudes
            ou ataques, ficando em tais casos responsável exclusivamente por
            restabelecer a PLATAFORMA da melhor forma e prazo possível.
          </p>

          <p className="spacing">
            16.6. O INTEGRADOR autoriza expressamente que suas informações de
            cadastramento sejam mantidas pela ITISUN, bem como autoriza a ITISUN
            a fornecer as informações do cadastro e das operações realizadas
            pelo INTEGRADOR: (i) às autoridades públicas competentes que as
            solicitarem formalmente, nos termos da Constituição Federal
            brasileira e demais legislações aplicáveis, e (ii) aos seus
            parceiros estratégicos, comerciais ou técnicos, necessários para o
            perfeito funcionamento da PLATAFORMA, desde que resguardado o dever
            de sigilo dos dados.
          </p>

          <p className="spacing">
            16.7. O INTEGRADOR deverá adotar as melhores práticas para quaisquer
            tratamentos de dados pessoais que realize em relação aos seus
            CLIENTES, incluindo, mas sem limite, a adoção de ferramentas de
            criptografia, dupla autenticação para acesso ao seu banco de dados,
            possibilidade de identificação dos seus contratados que tenham
            acesso à sua base de dados, etc.
          </p>

          <h2>
            17. CONSENTIMENTO LIVRE, EXPRESSO E INFORMADO PARA ACESSO À
            INFORMAÇÕES CONFIDENCIAIS E DADOS PESSOAIS
          </h2>

          <p className="spacing">
            17.1. O INTEGRADOR, na qualidade de Controlador de dados de seus
            CLIENTES, declara e garante que tratará os dados de CLIENTES
            cumprindo todos os requisitos da Legislação de Proteção de Dados;
          </p>

          <p className="spacing">
            17.2. O INTEGRADOR é responsável por garantir que os Dados Pessoais
            de Terceiros submetidos a Tratamento pela ITISUN são legítimos,
            atualizados e precisos, e que o tratamento foi autorizado pelo
            respectivo Titular dos Dados Pessoais ou atende a uma das bases
            legais para Tratamento previstas na LGPD.
          </p>

          <p className="spacing">
            17.3. O INTEGRADOR consente, livre e expressamente, em fornecer os
            dados que permitam o acesso as suas INFORMAÇÕES para que a
            PLATAFORMA execute todas as funções para as quais foi projetada.
          </p>

          <p className="spacing">
            17.4. O INTEGRADOR declara e reconhece que para a prestação dos
            serviços e funcionalidades ofertadas pela PLATAFORMA, a ITISUN
            acessa essas INFORMAÇÕES diretamente na base de dados, sem fazer
            qualquer emulação de medidas de segurança, utilizando-se apenas das
            INFORMAÇÕES, bem como outras eventualmente necessárias, fornecidas
            pelo INTEGRADOR, conforme autorizado.
          </p>

          <p className="spacing">
            17.5. O INTEGRADOR, por meio deste Termo de Uso e fornecendo as
            INFORMAÇÕES DE CONTA, autoriza e consente expressamente que a ITISUN
            acesse suas INFORMAÇÕES na qualidade de mandatária.
          </p>

          <p className="spacing">
            17.6. A ITISUN declara que todas as INFORMAÇÕES serão utilizadas
            única e exclusivamente para o adequado funcionamento da PLATAFORMA,
            sendo absolutamente vedada à ITISUN a realização de quaisquer
            transações, com exceção das INFORMAÇÕES DO CLIENTE, que poderão ser
            utilizadas pela ITISUN da forma como lhe convier, incluindo, mas sem
            limite, transacionar os dados com empresas parceiras, anonimizá-los,
            gerar dados estatísticos etc.
          </p>

          <p className="spacing">
            17.7. O INTEGRADOR consente que quando acessar o Portal da ITISUN,
            este poderá coletar informações técnicas de navegação, tais como
            tipo de navegador do computador utilizado para acesso ao Portal,
            endereço de protocolo de Internet, páginas visitadas e tempo médio
            gasto no Portal. Tais informações poderão ser usadas para orientar o
            próprio INTEGRADOR e melhorar os serviços e produtos ofertados.
          </p>

          <p className="spacing">
            17.8. O INTEGRADOR consente livre e expressamente que suas
            INFORMAÇÕES poderão ser transferidas a terceiros em decorrência da
            venda, aquisição, fusão, reorganização societária ou qualquer outra
            mudança no controle da ITISUN. A ITISUN, contudo, compromete-se,
            nestes casos, a informar o INTEGRADOR.
          </p>

          <p className="spacing">
            17.9. O INTEGRADOR consente livre e expressamente que a ITISUN
            utilize “cookies” apenas para controlar a audiência e a navegação em
            seu Portal e possibilitar a identificação de serviços segmentados e
            personalizados ao perfil do INTEGRADOR. A ITISUN garante que essas
            informações coletadas por meio de “cookies” são estatísticas e não
            pessoais, bem como que não serão utilizadas para propósitos diversos
            dos expressamente previstos neste Contrato, comprometendo-se a
            adotar todas as medidas necessárias, a fim de evitar o acesso e o
            uso de tais informações por quaisquer terceiros, sem a devida
            autorização.
          </p>

          <h2>18. DA RESCISÃO E DA SUSPENSÃO</h2>

          <p className="spacing">
            14.1. As Partes poderão rescindir o presente Contrato a qualquer
            tempo.
          </p>

          <p className="spacing">
            14.2. No caso de rescisão do presente Contrato, os dados pessoais,
            bancários, financeiros e demais informações do INTEGRADOR ficarão
            disponíveis conforme cláusula 7 deste Termo.
          </p>

          <h2>17. DAS DEMAIS DISPOSIÇÕES </h2>

          <p className="spacing">
            17.1. A autorização de uso da PLATAFORMA é fornecida por CNPJ/CPF.
          </p>

          <p className="spacing">
            17.2. Este Termo de Uso obriga as partes e seus sucessores, sendo
            vedado transferir os direitos e obrigações impostos por este
            Contrato. Tal limitação, no entanto, não atinge a ITISUN, que
            poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e
            obrigações inerentes ao presente Contrato.
          </p>

          <p className="spacing">
            17.3. A tolerância de uma parte para com a outra quanto ao
            descumprimento de qualquer uma das obrigações assumidas neste
            contrato não implicará em novação ou renúncia de direito. A parte
            tolerante poderá, a qualquer tempo, exigir da outra parte o fiel e
            cabal cumprimento deste contrato.
          </p>

          <p className="spacing">
            17.3. Não constituem causa de rescisão contratual, o não cumprimento
            das obrigações aqui assumidas em decorrência de fatos que independam
            da vontade das partes, tais como os que configuram o caso fortuito e
            a força maior previstos no artigo 393 do Código Civil Brasileiro.
          </p>

          <p className="spacing">
            17.4. Se qualquer disposição deste Termo de Uso for considerada
            nula, anulável, inválida ou inoperante, nenhuma outra disposição
            será afetada como consequência disso e, portanto, as disposições
            restantes deste Termo permanecerão em pleno vigor e efeito como se
            tal disposição nula, anulável, inválida ou inoperante não estivesse
            contida neste Termo de Uso.
          </p>

          <p className="spacing">
            17.5. O INTEGRADOR aceita receber notificações via correio
            eletrônico sobre treinamentos, parcerias e campanhas relacionadas à
            PLATAFORMA.
          </p>

          <p className="spacing">
            17.6. A ITISUN poderá, ao seu exclusivo critério, a qualquer tempo,
            e sem a necessidade de comunicação prévia ao INTEGRADOR: a)
            Encerrar, modificar ou suspender, total ou parcialmente, o acesso do
            INTEGRADOR à PLATAFORMA, quando referido acesso ou cadastro estiver
            em violação das condições estabelecidas neste Termo; b) Excluir,
            total ou parcialmente, as informações cadastradas pelo INTEGRADOR
            que não estejam em consonância com as disposições deste Termo de
            Uso; e, c) Acrescentar, excluir ou modificar o conteúdo oferecido no
            Portal.
          </p>

          <p className="spacing">
            17.7. A ITISUN ainda poderá, ao seu exclusivo critério, suspender,
            modificar ou encerrar as atividades da PLATAFORMA, mediante
            comunicação prévia ao INTEGRADOR, disponibilizando formas e
            alternativas de extrair as informações, salvo nas hipóteses de caso
            fortuito ou força maior.
          </p>

          <p className="spacing">
            17.8. A ITISUN poderá, por meio de comunicação no e-mail indicado
            pelo INTEGRADOR em seu cadastro, ou por meio de aviso no Portal
            definir preços para oferecimento de determinados conteúdos e/ou
            serviços, ainda que inicialmente tenham sido ofertados de forma
            gratuita, sendo a sua utilização, após o referido aviso, considerada
            como concordância do INTEGRADOR com tais preços.
          </p>

          <h2> 18. DA LEI APLICÁVEL </h2>

          <p className="spacing">
            18.1. Este Contrato será regido, interpretado e se sujeitará às leis
            brasileiras e, em caso de inadimplência das obrigações ora
            contratadas, as partes, desde logo elegem, de forma irrevogável e
            irretratável, o foro da Comarca da Cidade de São José do Rio Preto,
            Estado de São Paulo, para dirimir quaisquer dúvidas ou controvérsias
            oriundas deste Contrato, com a exclusão de qualquer outro, por mais
            privilegiado que seja.
          </p>

          <p>
            Última atualização
            <time dateTime="2023-10-25">25 de outubro de 2023.</time>
          </p>
        </section>

        <Controller
          control={control}
          name="acceptTerms"
          render={({ field: { name, onChange, value, ...rest } }) => {
            return (
              <label htmlFor={name}>
                <Checkbox
                  id={name}
                  name={name}
                  onChange={() => onChange(!value)}
                  isChecked={JSON.parse(String(value))}
                  {...rest}
                />
                Eu li e concordo com os termos de uso.
              </label>
            );
          }}
        />

        <Buttons>
          <Button
            disabled={!isValid}
            type="submit"
            typeStyle="confirm"
            backgroundColor="#001FFF"
            text="Avançar"
            onClick={onConfirm}
          />
        </Buttons>
      </Content>
    </ShapeModal>
  );
};
