import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  padding: 1.5rem 0;
`;

export const Wrapper = styled.div`
  min-height: calc(100vh - 14rem);
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin-bottom: 2rem;

  & > span {
    font-weight: 700;
    font-size: 1.5rem;

    color: #292929;
  }
`;

type InputGroup = {
  sideSpace?: 'left' | 'right';
  size?: 'small' | 'mid';
};

export const InputGroup = styled.div<InputGroup>`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > span.note {
    display: inline-block;
    margin-top: 0.125rem;
    font-weight: 500;
    color: #ccc;
  }

  & > span {
    display: inline-block;

    margin-bottom: 0.5rem;

    font-size: 1rem;
    font-weight: 500;
    color: #27282b;
  }

  ${(props) =>
    props.sideSpace === 'right' &&
    css`
      margin-right: 1rem;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      width: 25%;
    `}
  ${(props) =>
    props.size === 'mid' &&
    css`
      width: 50%;
    `}
`;

type groupType = {
  size?: 'small' | 'medium';
};

export const Group = styled.div<groupType>`
  ${(props) =>
    props.size === 'small' &&
    css`
      max-width: 21.875rem;
    `}
  ${(props) =>
    props.size === 'medium' &&
    css`
      max-width: 61.25rem;
    `}
`;

export const GroupHeader = styled.div`
  width: 100%;

  h2 {
    font-weight: 600;
    font-size: 1.25rem;

    color: #27282b;
  }

  span {
    display: inline-block;
    margin-top: 1rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;

    color: #666666;
  }
`;

export const FormContainer = styled.div`
  border: 1px solid #dfe0e2;
  background-color: #fff;
  border-radius: 12px;
  padding: 5%;
  margin: 1.5rem 0;
`;

export const FileList = styled.div`
  :not(:first-child) {
    margin-top: 1rem;
  }

  span {
    display: inline-block;
    margin: 1rem 0;
    font-weight: bold;
  }
`;

export const GroupedInputs = styled.div`
  display: flex;

  textarea {
    width: 100%;
    height: 150px;
    outline: none !important;
    border: 1px solid #dfe0e2;
  }

  @media (max-width: 1295px) {
    flex-direction: column;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem !important;

  flex-wrap: wrap;

  margin: 3.5rem 0 0;

  @media (max-width: 992px) {
    flex-direction: column-reverse;

    & > button {
      width: 100% !important;
    }
  }
`;

export const Divider = styled.hr`
  border: 1px solid #e0e0e0;
  margin: 3rem 0;
`;

export const TableContainer = styled.div`
  border: #e0e0e0 solid 1px;

  border-radius: 10px;

  background-color: #fafafa;

  margin: 2rem 0;

  color: #292929;

  @media (max-width: 520px) {
    margin: 2rem -5%;

    border-left: 0;
    border-right: 0;

    border-radius: 0;
  }

  & > div.table-wrapper {
    overflow: auto;

    & > table {
      width: 100%;
      border-spacing: 0;
      border-collapse: collapse;

      tr {
        height: 3.5rem;
      }

      td,
      th {
        text-align: left;
        padding: 0 2rem;
        border-top: #e0e0e0 solid 1px;
        border-bottom: #e0e0e0 solid 1px;
        background-color: #fafafa;

        font-size: 1rem;

        @media (max-width: 992px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      td {
        background-color: #fff;

        span {
          border-radius: 1rem;

          padding: 0.5rem 0;

          &.completed {
            background: #f5f8dd;
          }

          &.project {
            background: #e0e0e0;
          }
        }
      }

      tbody tr:hover {
        background-color: #f3f3f3;
      }
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  div.filter-container {
    display: flex;
    align-items: center;
    gap: 1.125rem;

    @media (max-width: 992px) {
      align-items: flex-start;
      flex-direction: column;

      width: 100%;

      & > div {
        max-width: unset;

        order: 2;
      }
    }

    div.status-select {
      width: 17.5rem;

      margin-right: 1.125rem;

      @media (max-width: 992px) {
        width: 100%;

        order: 1;
      }
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    & > div {
      max-width: unset;

      order: 2;
    }

    & > button {
      width: 100%;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
`;

export const LinkBoxGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22rem, 22rem));
  grid-template-rows: max-content;
  gap: 1.5rem;

  margin: 3rem 0;

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

export const LinkBox = styled.a`
  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 15rem;
  padding: 1.75rem 2.5rem;
  background: #ffffff;
  border: 1px solid #dfe0e2;
  border-radius: 1rem;

  svg {
    margin-bottom: 1.375rem;

    width: 3rem;
    height: 3rem;
  }

  span {
    font-weight: 600;
    font-size: 1.25rem;
    color: #292929;
  }

  p {
    color: #292929;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 150%;
  }

  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const ImportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  height: 100%;

  h2 {
    font-weight: 700;
    font-size: 1.25rem;
  }

  h3 {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
`;

export const ImportInputs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  flex: 1;

  @media (max-width: 992px) {
    flex-direction: column;

    & > div > label > input {
      width: 100%;
    }
  }
`;

export const ImportInputGroup = styled.div`
  display: flex;
  flex-direction: column;

  font-weight: 500;

  & > label {
    display: flex;
    flex-direction: column;

    & > input {
      margin-top: 0.5rem;
      width: 22.5rem;
      height: 3rem;
    }
  }

  div {
    display: flex;
    padding: 0.5rem 0.25rem;
    font-size: 0.875rem;
    svg {
      margin-top: 0.125rem;
    }
    p {
      margin-left: 0.25rem;
    }
  }
`;

export const NotFoundMessage = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;

  margin: 0 auto;

  padding: 3rem 0;

  font-weight: 400;
  font-size: 1.125rem;

  color: #27282b;
`;
