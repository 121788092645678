import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  justify-content: center;
  background-color: #001fff;

  height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6rem;

  padding: 0 5%;

  max-width: 90rem;
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1.5rem;

    width: 100%;
  }

  & > strong {
    font: 700 2.5rem 'DunbarTallBold';

    color: #fff;

    letter-spacing: 0.03em;

    text-align: center;
  }

  & > small {
    display: inline-block;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    font-family: 'DunbarTextRegular';
    letter-spacing: 0.02em;
    margin-bottom: 1.5rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const HiddenLogo = styled.div`
  margin: 2rem 0;

  & > img {
    width: 100%;

    object-fit: cover;
  }
`;
